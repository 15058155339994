<ng-template #monthDetails let-modal>
	<div class="modal-header">
		<h4> Item Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!-- <div class="col-md-auto text-right mt-2">
			<mat-checkbox [formControl]="hideshowunits">Units&nbsp;</mat-checkbox>
			<mat-checkbox [formControl]="hideshowsales">Sales&nbsp;</mat-checkbox>
			<mat-checkbox [formControl]="hideshowcost" *ngIf="user.user.viewcost">Cost&nbsp;</mat-checkbox>
		</div> -->
		<!-- <mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator> -->
		<div class="row">
		<div class="col-sm-3">
		</div>
		<div class="col-3-sm">
		<mat-form-field appearance="outline">
		<mat-label >Items Per Page</mat-label>
		<mat-select id="pagePicker" [value]="pageSize" (selectionChange)="updatePageSize($event);">
		<mat-option *ngFor="let i of items_per_page" [value]="i">{{ i }}</mat-option>
		</mat-select>
		</mat-form-field>
		</div>
		<div class="col-6-sm" style="margin-top:20px">
		 <pagination-controls (pageChange)="p = $event"></pagination-controls>
		</div>
		</div>
		<h3> Most Purchased {{ brand_details.brand }} Items </h3>
		<div class="table mt-3" *ngIf="brand_details">

			<!-- <div class="table mt-3" *ngIf="brand_details" #print_history>
				<table class="table table-bordered" *ngFor="let year of brand_details.years">
					<tr>
						<th></th>
						<th *ngFor="let month of year.sales" class="text-center">
							{{ month.label }} {{ month.year }}
						</th>
						<th class="text-center">Total</th>
					</tr>

					<tr *ngIf="hideshowunits.value">
						<td class="text-center">Qty</td>
						<td class="text-center" *ngFor="let month of year.qty">
							<button mat-flat-button (click)="viewHistory( month.monthnumber, month.year)">{{ month.value | number:'1.0-0' }}</button>
						</td>
						<td class="text-center">{{ getQtyTotalYear( year ) }}</td>
					</tr>
					<tr *ngIf="hideshowsales.value">
						<td class="text-center">Sales </td>
						<td class="text-center" *ngFor="let month of year.sales">
							{{ month.value | currency }}
						</td>
						<td class="text-center">{{ getSaleTotalYear( year ) | currency }}</td>
					</tr>
					<tr *ngIf="hideshowcost.value">
						<td class="text-center">Cost </td>
						<td class="text-center" *ngFor="let month of year.cost">
							{{ month.value | currency }}
						</td>
						<td class="text-center">{{ getCostTotalYear( year ) | currency }}</td>
					</tr>
				</table>
			</div> -->
			<!--  -->
			<table class="table table-striped table-fixed table-hover" *ngIf="brand_details">
				<thead class="thead-dark">
					<tr>
						<th>DOCUMENT#</th>
						<th>TYPE</th>
						<th>LOCATION#</th>
						<th>DATE</th>
						<th>ITEM#</th>
						<th>DESC</th>
						<th class="text-center">DISCOUNT</th>
						<th class="text-center">QTY</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
				</thead>
				<tbody>
					<!-- *ngFor="let item of brand_details.items" -->
					<tr *ngFor="let item of dataObs | async | paginate: { itemsPerPage: pageSize, currentPage: p }">
						<td>
							{{ item.transno }}
						</td>
						<td>{{ item.typename }}</td>
						<td>{{ item.loccode }}</td>
						<td>{{ item.trandate }}</td>
						<td>{{ item.stockid }}</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.discountpercent | percent }}</td>
						<td class="text-center">{{ item.qty }}</td>
						<td class="text-right">{{item.price | currency }}</td>
						<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
					</tr>
					<tr>
						<td>TOTAL</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-center">{{ getItemTotals() }}</td>
						<td></td>
						<td class="text-right">{{getDollarTotals() | currency}}</td>
					</tr>
				</tbody>
			</table>
			<h4 class="text-center" *ngIf="!brand_details.items">
				No Results
			</h4>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #shiptodetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Edit Ship To</h4>
		<button class="ml-auto mr-2" mat-button mat-raised-button (click)="newShipTo()">{{ text_add_button }}</button>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<app-customer-shiptos [edittoggle]="edittoggle" [newcustomer]="newcustomertoggle" [customerdata]="customerdata" (editing)="updateEditing($value)" (newcustomerdata)="updateCustomer($event)" (updatebranch)="updateBranch($event)"></app-customer-shiptos>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #inactiveModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Re-Activate Customer</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<h3 class="bg-danger text-center"> CONFIRM CUSTOMER ACCOUNT </h3>
			<app-customer-shiptos [edittoggle]="edittoggle" [newcustomer]="newcustomertoggle" [customerdata]="customerdata" (editing)="updateEditing($value)" (newcustomerdata)="updateCustomer($event)" (updatebranch)="updateBranch($event)"></app-customer-shiptos>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<ng-template #documentModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Document {{ viewingdoc.name }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3 ml-auto mr-auto">
			<app-iframe-view [src]="viewingdoc.link+'?'"></app-iframe-view>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="main-content p-0">
	<div class="container-fluid">
		<div class="row ">
			<div class="col-6 text-left mt-3">
				<a [routerLink]="'/customers/view/'+customerdata.prev" *ngIf="customerdata.prev">Previous Customer</a>
			</div>
			<div class="col-6 text-right mt-3">
				<a [routerLink]="'/customers/view/'+customerdata.next" *ngIf="customerdata.next">Next Customer</a>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 " *ngIf="customerdata.customer">
				<div class="card ">
					<div class="card-header card-header-{{ color }}-6">
						<h4 class="card-title">
							<i class="material-icons" (click)="back()">arrow_left</i>
							&nbsp;<button mat-icon-button [cdkCopyToClipboard]="customerdata.customer.debtorno+'.'+customerdata.customer.branchcode"><small><mat-icon>content_copy</mat-icon></small> </button><span class="bold">{{customerdata.customer.debtorno}}.{{customerdata.customer.branchcode}}: {{customerdata.customer.name}}</span>
						</h4>
					</div>
					<div class="card-body">
						<app-customer-lookup (customer_selected)="selectCustomer($event)" *ngIf="!isItemLookup" @grow></app-customer-lookup>
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" [selectedIndex]="activeIndex" (selectedIndexChange)="updateActiveIndex($event)">
							<mat-tab label="Customer Info">
								<ng-container *ngIf="!ctl">
									<div class="container-fluid tp-box" [@flipState]="flip">
										<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? 'd-none' : ''">
											<ng-container *ngIf="viewingtrans == 'branches' ">
												<app-customer-shiptos [customerdata]="customerdata"></app-customer-shiptos>
											</ng-container>
										</div>
										<div id="frontside" class="row m-0 p-0 tp-box__side tp-box__front ml-2 mr-auto" [ngClass]="flip == 'active' ? 'd-none' : ''">
											<ng-container>
												<div @filterAnimation class="card col-md-4  ml-0 p-0 card-editor" [ngClass]="editbilling ? 'col-md-4':'col-md-4'">
													<div class="card-header card-header-icon card-header-rose pointer">
														<div class="card-icon" (click)="edit('billto')" matTooltip="Edit Billing Information">
															<i class="material-icons">credit_card</i>
														</div>
														<h4 class="card-title">Bill To: Customer# {{customerdata.customer.debtorno}}</h4>
													</div>
													<div class="card-body mt-3">
														<h4 class="bolder-to" [innerHTML]="customerdata.billing_address" *ngIf="!editbilling"></h4>
														<ng-container *ngIf="!editbilling && config && config.env.package == 'beauty'">
															<mat-divider></mat-divider>
															<ul class="entry-list p-0 m-0">
																<li>
																	<div class="spec-label bg-white">
																		Signed Contract:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.signed_mor }}
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		License:
																	</div>
																	<div class="spec-value" [ngClass]="customerdata.customer.cosm_license == '' ? ' bg-danger bg-danger text-danger' : '' ">
																		{{ customerdata.customer.cosm_license }}
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		License Exp:
																	</div>
																	<div class="spec-value" [ngClass]="customerdata.customer.licExpDate == '0000-00-00' || customerdata.customer.licExpDate == 'N/A' ? ' bg-danger  bg-danger text-danger' : '' ">
																		{{ customerdata.customer.licExpDate }}
																	</div>
																</li>
															</ul>
														</ng-container>
														<ng-container *ngIf="editbilling">
															<app-customer-edit *ngIf="customerdata" [customerdata]="customerdata" (editing)="edit($value)" (newcustomerdata)=updateCustomer($event)></app-customer-edit>
														</ng-container>
														<ng-container *ngIf="viewingcards">
															<app-card-manager [customer]="customerdata"></app-card-manager>
														</ng-container>
														<mat-divider></mat-divider>
														<div class="row m-0 p-0 mt-4">
															<div class="col-md-6">
																<div class="checkbox-wrapper-44 max-50" *ngIf="!viewingcards && customerdata">
																	<label class="toggleButton"><small>{{ isActiveText() }}</small>
																		<input type="checkbox" [checked]="customerdata.customer.isactive" (click)="updateActive($event)">
																		<div>
																			<svg viewBox="0 0 44 44">
																				<path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)" stroke-width="5"></path>
																			</svg>
																		</div>
																	</label>
																</div>
															</div>
															<div class="col-md-6 text-right pointer">
																<i class="material-icons mt-3 addcard" (click)="toggleCreditCards()" matTooltip="Add/Edit Credit Cards">credit_card</i>
															</div>
														</div>
													</div>
												</div>
											</ng-container>
											<div class="card  ml-md-1 mr-md-1 p-0 " [ngClass]="editing_shipping ? 'col-md-4 card-editor':'col-md-4 card-editor'">
												<div class="card-header card-header-success card-header-icon pointer">
													<div class="card-icon" (click)="openShippingModal(shiptodetails)" matTooltip="Edit Shipping Information">
														<i class="material-icons">local_shipping</i>
													</div>
													<h4 class="card-title">Ship To: {{customerdata.customer.branchcode}}
														{{ getRouteName(customerdata.customer.route) }}
														<ng-container *ngIf="customerdata.shiptocount > 1">
															<small>
																<button mat-icon-button [matMenuTriggerFor]="branchchange" color="accent" aria-label="Change Branch">
																	<mat-icon>menu</mat-icon>
																</button>
															</small>
															<mat-menu #branchchange="matMenu">
																<ng-container *ngFor="let branch of customerdata.branches">
																	<button mat-menu-item (click)="changeBranch(branch.branchcode)">
																		<mat-icon>sync_alt</mat-icon>
																		<span>{{ branch.branchcode }}: {{branch.brname}} {{ branch.braddress1 }}</span>
																	</button>
																</ng-container>
															</mat-menu>
															<small>({{customerdata.shiptocount}})</small>
														</ng-container>
													</h4>
												</div>
												<div class="card-body  mt-3">
													<h4 class="bolder-to" [innerHTML]="customerdata.shipping_address" *ngIf="!editing_shipping"></h4>
													<mat-divider></mat-divider>
													<ul class="entry-list p-0 m-0" *ngIf="!editing_shipping">
														<li *ngIf="customerdata.customer.contactname != ''">
															<div class="spec-label bg-white">
																Attention:
															</div>
															<div class="spec-value">
																{{ customerdata.customer.contactname }}
															</div>
														</li>
														<li>
															<div class="spec-label bg-white">
																Phone:
															</div>
															<div class="spec-value">
																<ng-container *ngIf="customerdata.customer.phoneno && customerdata.customer.phoneno!='' ">
																	{{ customerdata.customer.phoneno | phoneFormat }}
																</ng-container>
															</div>
														</li>
														<li>
															<div class="spec-label bg-white">
																Tax ID:
															</div>
															<div class="spec-value">
																{{ customerdata.customer.tax_ref }}
															</div>
														</li>
														<li *ngIf="customerdata.customer.specialinstructions != ''">
															<div class="spec-label bg-white">
																Special Instructions:
															</div>
															<div class="spec-value">
																{{ customerdata.customer.specialinstructions }}
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="card col-md-4  mr-md-0 card-editor" [ngClass]="(customerdata.customer.dissallowinvoices) ? 'border border-danger': 'border border-success'">
												<span>
													<div class="card-header card-header-danger card-header-icon pointer" [ngClass]="(customerdata.customer.disabletrans) ? 'bg-secondary text-white': ''">
														<div class="card-icon" (click)="updateArEdit()" matTooltip="Edit Account">
															<i class="material-icons">account_circle</i>
														</div>
													</div>
													<h4 class="card-title mt-3">Accounts Receivable :</h4>
													<div class="card-body m-0 p-0">
														<app-customer-ar [customer]="customerdata" *ngIf="editing_ar" (newcustomerdata)=updateCustomer($event) (editing)="updateArEdit($value)"></app-customer-ar>
														<ng-container *ngIf="!editing_ar && !attach_file">
															<div class="row">
																<div class="col-md-6 text-left mt-2" [ngClass]="(customerdata.customer.disabletrans) ? 'bg-danger text-white bold': ' '">
																	{{ customerdata.customer.reasondescription }}
																</div>
																<div class="col-md-6 text-right">
																	<b>Terms: {{ customerdata.customer.termsindicator }} - {{ customerdata.customer.terms  }}</b>
																</div>
																<div class="col-md-6 text-left">
																	Credit: {{ customerdata.customer.creditlimit | currency }}
																</div>
																<div class="col-md-6 text-right">
																	Currency: {{ customerdata.customer.currency  }}
																</div>
																<div class="col-md-6 text-left">
																	Print Statement: {{ customerdata.customer.edireference }}
																</div>
																<div class="col-md-6 text-right">
																	Email Statement: {{ getYesNo(customerdata.customer.emailstatement) }}<br>
																	Email Invoice: {{ getYesNo(customerdata.customer.ediinvoices) }}
																</div>
																<div class="col-md-12 text-left">
																	<b>Tax Auth: {{ getTaxAuth(customerdata.customer.taxgroupid) }}</b>
																</div>
															</div>
														</ng-container>
													</div>
													<div class="card-footer m-0 pt-4" (click)="selectTransactions()">
														<app-customer-aging [aged]="customerdata.aged" class="w-100 m-0 p-0"></app-customer-aging>
													</div>
												</span>
											</div>
											<div class="card col-md-4" [ngClass]="editing_contacts ? 'col-md-6':'col-md-4 card-editor'">
												<div class="card-header card-header-warning card-header-icon pointer">
													<div class="card-icon" (click)="edit('editing_contacts')">
														<i class="material-icons" matTooltip="Edit Contacts">analytics</i>
													</div>
													<h4 class="card-title">Contacts & Info:</h4>
												</div>
												<div class="card-body">
													<app-customer-contacts [customer]="customerdata" *ngIf="editing_contacts" (newcustomerdata)="updateCustomer($event)" (editing)="updateContactEdit($value)"></app-customer-contacts>

													<ng-container *ngIf="!editing_contacts">
														<ul class="entry-list p-0 m-0">
															<li>
																<div class="text-center ml-auto mr-auto">

																	Most Purchased Brands: (L365)<br />

																	<span *ngIf="customerdata.customer.topthree">

																		<span *ngIf="customerdata.customer.topthree[0]" (click)="getBrandSales(customerdata.customer.debtorno, customerdata.customer.topthree[0].manufacturer_id)" matRipple>{{customerdata.customer.topthree[0].name}} ({{customerdata.customer.topthree[0].brandcount}})&nbsp;</span>

																		<span *ngIf="customerdata.customer.topthree[1]" (click)="getBrandSales(customerdata.customer.debtorno, customerdata.customer.topthree[1].manufacturer_id)" matRipple>{{customerdata.customer.topthree[1].name}} ({{customerdata.customer.topthree[1].brandcount}})&nbsp;</span>

																		<span *ngIf="customerdata.customer.topthree[2]" (click)="getBrandSales(customerdata.customer.debtorno, customerdata.customer.topthree[2].manufacturer_id)" matRipple>{{customerdata.customer.topthree[2].name}} ({{customerdata.customer.topthree[2].brandcount}})&nbsp;</span>
																	</span>
																	<span *ngIf="!customerdata.customer.topthree">
																		N/A
																	</span>
																</div>
															</li>
															<li>
																<div class="spec-label bg-white">
																	REFUSED EMAIL:
																</div>
																<div class="spec-value">
																	<div class="refused-checkmark-words mt-2">
																		<input class="tgl tgl-flip" id="rfb" type="checkbox" [checked]="refusedemail" (click)="updateRefused($event)" />
																		<label class="tgl-btn" data-tg-off="NO" data-tg-on="YES" for="rfb"></label>
																	</div>
																</div>
															</li>
															<li>
																<div class="spec-label bg-white">
																	Client Since:
																</div>
																<div class="spec-value">
																	{{ customerdata.customer.clientsince | dateAgo  }}
																</div>
															</li>
															<li>
																<div class="spec-label bg-white">
																	Total Spend:
																</div>
																<div class="spec-value">
																	{{ customerdata.customer.totalspend | currency }}
																</div>
															</li>
															<li>
																<div class="spec-label bg-white">
																	Last Paid:
																</div>
																<div class="spec-value" *ngIf="customerdata.customer.lastpaid">
																	{{ customerdata.customer.lastpaid | currency }} | {{ customerdata.customer.lastpaiddate | dateAgo }}
																</div>
																<div class="spec-value" *ngIf="!customerdata.customer.lastpaid">
																	N/A
																</div>
															</li>
															<li>
																<div class="spec-label bg-white">
																	Last Purchase:
																</div>
																<div class="spec-value">
																	<span *ngIf="customerdata.customer.last_purchase">
																		{{ customerdata.customer.last_purchase  | dateAgo }}
																	</span>
																	<span *ngIf="!customerdata.customer.last_purchase">
																		N/A
																	</span>
																</div>
															</li>
															<li>
																<div class="spec-label bg-white">
																	Avg Order: (L365)
																</div>
																<div class="spec-value">
																	<span *ngIf="customerdata.customer.avgspend">
																		{{ customerdata.customer.avgspend | currency }}
																	</span>
																	<span *ngIf="!customerdata.customer.avgspend">
																		N/A
																	</span>
																</div>
															</li>
															<li *ngIf="!customerdata.contacts.length">
																<div class="spec-label bg-white">
																	Contacts
																</div>
																<div class="spec-value">
																	N/A
																</div>
															</li>
															<li *ngFor="let contact of customerdata.contacts ">
																<div class="spec-value spec-label bg-white">
																	<span *ngIf="contact.name != ''">{{ contact.name }},</span>{{ contact.role }}
																</div>
																<div class="spec-value">
																	{{contact.email}}, <ng-container *ngIf="contact.phone  &&  contact.phone !='' "> {{ contact.phone  | phoneFormat }}</ng-container>
																</div>
															</li>

														</ul>
													</ng-container>
												</div>
											</div>
											<div class="card  ml-1 mr-1" [ngClass]="editing_settings ? 'col-md-4 card-editor':'col-md-4 card-editor '">
												<div class="card-header card-header-info card-header-icon pointer">
													<div class="card-icon" (click)="edit('settings')" matTooltip="Edit Profile">
														<i class="material-icons">phonelink_setup</i>
													</div>
													<h4 class="card-title">Profile:</h4>
													<div class="card-body text-dark">
														<app-customer-settings [customer]="customerdata" *ngIf="editing_settings" (newcustomerdata)=updateCustomer($event) (editing)="updateSettingEdit($value)"></app-customer-settings>
														<ng-container *ngIf="!editing_settings">
															<ul class="entry-list p-0 m-0">
																<li *ngIf="config.env.package == 'beauty'">
																	<div class="spec-label bg-white">
																		Last login:
																	</div>
																	<div class="spec-value">

																		<span *ngIf="customerdata.lastlogin">
																			{{ customerdata.lastlogin }}
																		</span>
																		<span *ngIf="!customerdata.lastlogin">
																			N/A
																		</span>

																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Username:
																	</div>
																	<div class="spec-value">

																		<span *ngIf="customerdata.username">
																			{{ customerdata.username }}
																		</span>
																		<span *ngIf="!customerdata.username">
																			N/A
																		</span>

																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Password:
																	</div>
																	<div class="spec-value">
																		<span *ngIf="customerdata.password">{{ customerdata.password }}</span><span *ngIf="!customerdata.password">N/A</span>
																		<span *ngIf="!customerdata.password">
																			N/A
																		</span>
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Salesman:
																	</div>
																	<div class="spec-value">
																		{{ salemanName(customerdata.customer.salesman) }}
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Type:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.typename }}
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Price List:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.salestype }} <ng-container *ngIf="pricelists">{{getPriceListName( customerdata.customer.salestype) }}</ng-container>
																	</div>
																</li>
																<li *ngIf="customerdata.customer.salestype == 'O'">
																	<div class="spec-label bg-white">
																		Price List 2 :
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.salestype2 }} <ng-container *ngIf="pricelists">{{getPriceListName( customerdata.customer.salestype2) }}</ng-container>
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Discount:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.discountdsp }}
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Discount Level:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.price_type }}
																	</div>
																</li>
																<li>
																	<div class="spec-label bg-white">
																		Commission Rate:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.customer.commpct }}%
																	</div>
																</li>
																<li *ngIf="customerdata.memberid != ''">
																	<div class="spec-label bg-white">
																		Clubs Joined:
																	</div>
																	<div class="spec-value">
																		{{ customerdata.memberid }}
																	</div> 
																</li>
															</ul>
														</ng-container>
													</div>
												</div>
											</div>
											<div class="card col-md-4  mr-md-0 card-editor" [ngClass]="editing_notes ? 'col-md-4 card-editor':'col-md-4 card-editor '">
												<div class="card-header card-header-secondary card-header-icon pointer">
													<div class="card-icon" matTooltip="Edit Notes" (click)="updateNoteEdit()">
														<i class="material-icons">notes</i>
													</div>
													<h4 class="card-title">Notes:</h4>
												</div>
												<div class="card-body">
													<app-customer-notes [customer]="customerdata" *ngIf="editing_notes" (newcustomerdata)="updateCustomer($event)" (editing)="updateNoteEdit($value)"></app-customer-notes>
													<ul class="entry-list p-0 m-0" *ngIf="!editing_notes">
														<li *ngFor="let note of customerdata.notes">
															<div class="spec-label bg-white">{{ note.note_code }} {{ note.userid }} {{ note.notedate }}:</div>
															<div class="spec-value">
																<span>{{ note.note }}</span>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</mat-tab>
							<mat-tab label="Transactions">
								<ng-template matTabContent>
									<mat-tab-group #tabPanel mat-align-tabs="start" class="no-overflow" color="accent" (selectedTabChange)="resetTrans=true">
										<mat-tab label="Customer Inquiry">
											<ng-template matTabContent>
												<app-customer-transactions [customerdata]="customerdata">
												</app-customer-transactions>
											</ng-template>
										</mat-tab>
										<mat-tab label="Payment" *ngIf="editsallowed.takepayment">
											<ng-template matTabContent>
												<app-payment [customer]="customerdata" class="m-0 p-0"></app-payment>
											</ng-template>
										</mat-tab>
										<mat-tab label="Statements">
											<ng-template matTabContent>
												<app-customer-statements [customerdata]="customerdata">
												</app-customer-statements>
											</ng-template>
										</mat-tab>
									</mat-tab-group>
								</ng-template>
							</mat-tab>
							<mat-tab [label]="config.env.package === 'tires' ? 'Item Lookup' : 'Order Entry'" preserveContent="true" *ngIf="!isPosUser">
								<ng-template matTabContent>
									<app-shared-order-entry [cusomerdata]="customerdata" [customer]="customerdata.customer" [flip]="'inactive'" [invoicing]="invoicing" (reloadCustomer)="loadCustomer($event)" class="w-100 mt-3"></app-shared-order-entry>
								</ng-template>
							</mat-tab>
							<mat-tab label="Reports " preserveContent="true">
								<ng-template matTabContent>
									<app-customer-item-history (addCreditItems)="addCreditItems($event)" [customerdata]="customerdata" [menuapp]="false">
									</app-customer-item-history>
								</ng-template>
							</mat-tab>
							<mat-tab label="Open Orders ({{ customerdata.opens.orders }})">
								<ng-template matTabContent>
									<app-open-orders [customerdata]="customerdata" (orderloaded)="selectItemLookup($event)" (reloadCustomer)="loadCustomerBranch($event)" (orderinvoice)="invoiceOrder($event)"></app-open-orders>
								</ng-template>
							</mat-tab>
							<mat-tab label="Open RMA's ({{ customerdata.openrmas.length }})" *ngIf="editsallowed.editar && !isPosUser">
								<app-open-credits [customerdata]="customerdata" (credit_data)="updateCreditData($event)"></app-open-credits>
							</mat-tab>
							<mat-tab label="Quotes ({{ customerdata.opens.quotes }})">
								<ng-template matTabContent>
									<app-customer-quotes [customerdata]="customerdata" (quote_loaded)="loadQuote($event)" (quotesUpdated)="updateCustomer($event)"></app-customer-quotes>
								</ng-template>
							</mat-tab>

							<mat-tab [label]="'Documents (' + (customerdata?.customer_files?.length || 0) + ')'">
							  <ng-container>
								<div class="row m-0 p-0">
								  <div class="col-md-4">
									<h5 class="mt-3 ml-3">Documents</h5>
									<ngx-dropzone [multiple]="false" (change)="onSelect($event)" class="dropzone-container">
									  <ngx-dropzone-label class="dropzone-label">
										DROP FILE HERE OR CLICK TO UPLOAD
									  </ngx-dropzone-label>
									  <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
										(removed)="onRemove(f)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
										<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
									  </ngx-dropzone-preview>
									</ngx-dropzone>
								  </div>
								  <div class="col-md-8 mt-2">
									<mat-grid-list cols="3" rowHeight="1:1" gutterSize="16px">
									  <ng-container *ngIf="!customerdata?.customer_files || customerdata.customer_files.length === 0">
										<div mat-subheader>No Files</div>
									  </ng-container>
									  <ng-container *ngIf="customerdata?.customer_files && customerdata.customer_files.length > 0">
										<div mat-subheader>Files</div>
										<ng-container *ngFor="let f of customerdata.customer_files">
										  <mat-grid-tile>
											<mat-card class="file-card" *ngIf="isImage(f)">
											  <img mat-card-image [src]="f.link" (click)="openDocument(f)" alt="{{ f.name }}">
											  <mat-card-actions>
												<button mat-icon-button (click)="removeFile(f.name)">
												  <mat-icon>delete</mat-icon>
												</button>
											  </mat-card-actions>
											</mat-card>
											<mat-card class="file-card" *ngIf="!isImage(f)">
											  <mat-card-content (click)="openDocument(f)">{{ f.name }}</mat-card-content>
											  <mat-card-actions>
												<button mat-icon-button (click)="removeFile(f.name)">
												  <mat-icon>delete</mat-icon>
												</button>
											  </mat-card-actions>
											</mat-card>
										  </mat-grid-tile>
										</ng-container>
									  </ng-container>
									</mat-grid-list>
								  </div>
								</div>
							  </ng-container>
							</mat-tab>

							<mat-tab label="Consignment ({{ (customerdata.consignitems) ? customerdata.consignitems.length : 0  }})" *ngIf="config.consignment">
								<ng-template matTabContent>
									<app-customer-consignment [customerdata]="customerdata" (updateCustomerEmit)="loadCustomer($event)"></app-customer-consignment>
								</ng-template>
							</mat-tab>
							<mat-tab label="Dealer Codes" *ngIf="config.env.package == 'tires'">
								<ng-template matTabContent>
									<app-dealer-codes [customer]="customerdata"></app-dealer-codes>
								</ng-template>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
