<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a *ngIf="config.env.package == 'tires' || (config.env.package == 'beauty'  && !issalesman)" [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="cleanAndFormatHTML(item_details.descriptions.name)"></h3>
						<section class="section-small" [innerHTML]="cleanAndFormatHTML(item_details.descriptions.description)"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.features)"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.benefits)"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="standard" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #mmmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ promo.itemdesc.stockid }} <span>{{promo.itemdesc.description }}</span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-9">
				<div class="promo-item-description">
					<p class="stock-id">{{ promo.itemdesc.stockid }}</p>
					<p class="description">{{ promo.itemdesc.description }}</p>
				</div>
				<ul class="list-group">
					<ng-container *ngFor="let intro of promo.intros">
						<ng-container *ngIf="intro.type === '1' || intro.type === 1">
							<li class="list-group-item item-details-above">
								<div class="item-description">
									<p>{{ intro.stockid }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})</p>
								</div>
							</li>
						</ng-container>
					</ng-container>
				</ul>
			</div>
			<div class="col-md-3 text-right">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput type="number" [(ngModel)]="promo.qty" (input)="setParent($event, promo)" class="text-right">
				</mat-form-field>
				<button class="btn btn-primary mt-2" (click)="addMM(promo)" [disabled]="promo.error">Add To Order</button>
			</div>
		</div>

		<!-- {{ promo.descriptions | json }} <h4>PROMO {{ promo.description }} {{ ispromo.promo.price | currency }} </h4> -->
		<div class="promo-container" *ngIf="promo">
			<h5>Promo Items</h5>
			<div class="row m-0 p-0">
				<ng-container *ngFor="let intro of promo.intros">
					<div class="col-md-{{ 12  / getNumberOfNonType1Intros(promo.intros) }}" *ngIf="intro.type != '1'">
						<div class="promo-intro">
							<h4>{{ intro.name }} {{ intro.description }}</h4>
							<ng-container *ngIf="intro.type === '1'; else typeDefault">
								<div class="intro-details">
									<div class="quantity-info">
										{{ intro.item }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})
									</div>
								</div>
							</ng-container>
							<ng-template #typeDefault>
								<div class="intro-details" [ngClass]="{'error': intro.error}">
									<div class="quantity-info">
										{{ intro.price }} <span [ngClass]="{'zero-quantity': (updateMMQty(intro) == 0) }">{{ intro.dsptype }} {{ updateMMQty(intro) }} / {{ intro.floor }}</span> {{ intro.price }}
									</div>
								</div>
							</ng-template>
						</div>
						<div>
							<mat-form-field appearance="fill">
								<mat-label>Search Mix & Match...</mat-label>
								<input tabindex="1" matInput value="" [(ngModel)]="intro.searchQuery" (input)="filterItems(intro)" appCollapseKeyboard>
								<span matSuffix>
									<button tabindex="-1" mat-icon-button color="white">
										<mat-icon (click)="filterItems(intro)">search</mat-icon>
									</button>
								</span>
							</mat-form-field>
						</div>
						<ng-container *ngFor="let item of (intro.filteredItems ? intro.filteredItems : intro.items)">
							<li class="list-group-item item-details">
								<div class="item-details-row">
									<div class="item-description">
										<p>{{ item.stockid }}</p>
										<p>{{ item.description }}</p>
									</div>
									<div class="item-quantity">
										<button mat-icon-button class=" " (click)="decrementmm(item, intro)">
											<mat-icon>remove</mat-icon>
										</button>
										<input matInput type="text" class="quantity-input " [value]="item.quantity | number" (input)="qtyMM($event, item, intro)">
										<button mat-icon-button class="pl-4 " (click)="incrementmm(item, intro)">
											<mat-icon>add</mat-icon>
										</button>
									</div>
								</div>
							</li>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #edumodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ event.name }} Registration - {{ event.location_name }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
			<div class="row">
			<div class="col-12">
			{{ event.short_description }}
			</div>
			</div>
			<br>
			<div class="row">
			<div class="col-3">
			<b>Host</b>
			</div>
			<div class="col-3">
			{{ event.host }}
			</div>
			<div class="col-3">
			<b>Presenter</b>
			</div>
			<div class="col-3">
			{{ event.artist_name }}
			</div>
			</div>
			<hr>
			<div class="row">
			<div class="col-3">
			<b>Cost Per Seat</b>
			</div>
			<div class="col-3">
			{{ event.discount | currency }}
			</div>
			<div class="col-3">
			<b>Capacity Remaining</b>
			</div>
			<div class="col-3">
			{{ event.remaining }} / {{ event.uses_total }}
			</div>
			</div>
			<hr>
	<form [formGroup]="regForm">
		<div class="row">

			<div class="col-2">
			<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput type="text" formControlName="eduname">
			</mat-form-field>
			</div>
			<div class="col-2">
			<mat-form-field>
			<mat-label>Cell Phone</mat-label>
			<input matInput type="text" formControlName="educell">
			</mat-form-field>
			</div>
			<div class="col-2">
			<mat-form-field>
			<mat-label>Email</mat-label>
			<input matInput type="text" formControlName="eduemail">
			</mat-form-field>
			</div>
			<div class="col-2">
			<mat-form-field>
			<mat-label>Instagram</mat-label>
			<input matInput type="text" formControlName="eduinsta">
			</mat-form-field>
			</div>
			<div class="col-2">
			<button type="button" mat-stroked-button aria-label="Add" (click)="addSeat(event)"><mat-icon>add</mat-icon> Add Seat</button>
			</div>

		</div>
	</form>
		<hr>
		<div class="row" *ngFor="let seat of event.seats">
			<div class="col-2">
			{{ seat.name }}
			</div>
			<div class="col-2">
			{{ seat.cell }}
			</div>
			<div class="col-2">
			{{ seat.email }}
			</div>
			<div class="col-2">
			{{ seat.insta }}
			</div>
			<div class="col-2">
			<button type="button" mat-stroked-button aria-label="Add" (click)="remSeat(seat)"><mat-icon>remove</mat-icon></button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<h4 class="modal-title">Total: {{ event.total | currency}}</h4>
		<button type="button" mat-stroked-button aria-label="Add" (click)="addedureg(event)"><mat-icon>add</mat-icon> Add To Order</button>
	</div>
</ng-template>
<div [ngClass]="isGridView ? '' : 'list-layout'">
	<ng-container *ngIf="isGridView">
		<div class="row">
			<ng-container *ngFor="let item of filtered_items; let i = index;">
				<div class="col-12 col-md-6 col-lg-4">
					<div class="card mb-3">
						<img [src]="item.image_large" class="card-img-top" lazy>
						<div class="card-body" (click)="openModal(itemdetails, item)">
							<h5 class="card-title">{{ item.brand }}</h5>
							<p class="card-text">{{ item.description }}</p>
						</div>
						<div class="card-footer text-muted">
							<div class="row p-0 m-0 text-center">
								<div class="col-auto mt-1 ml-auto mr-0 p-0 shrink-2">
									<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm " (click)="decrement(item)">
										<small><i class="material-icons">remove</i></small>
									</button>
								</div>
								<div class="m-0 p-0 the-dense-zone text-center" [ngClass]="showfilters ? 'col-4' : 'col-6 mw100'">
									<input type="text" tabindex="{{ i + 2 }}" pattern="[0-9]*" value="{{ item.quantity | number }}" autofocus (input)="updateSearchQuantity($event, item )" class="quantity-input col-auto">
								</div>
								<div class="col-auto mt-1 m-0 mr-0 p-0 shrink-2">
									<ng-container *ngIf="!item.ispromo">
										<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="increment(item)">
											<small>
												<i class="material-icons">add</i></small>
										</button>
									</ng-container>

									<ng-container *ngIf="item.ispromo">
										<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
											<small><i class="material-icons">settings</i></small>
										</button>

										<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
											<small><i class="material-icons">add_box</i></small>
										</button>
									</ng-container>

								</div>

								<div class="col-12 col-md-4 col-lg-4 ml-auto mb-0 mt-0 ml-auto mr-auto">
									<div [ngClass]="(item.quantity > 0) ? 'linetotal_row_picked' : 'linetotal_row'" class="row">
										<span class="linetotal_price">
											&nbsp;{{ getLineTotal(item) | currency }} &nbsp;
										</span>

										<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="addToOrder(item)" *ngIf="!item.ispromo && !item.isevent">
											<small><i class="material-icons">add_box</i></small>
										</button>
										<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="triggerEdu(item)" *ngIf="item.isevent">
											<small><i class="material-icons">settings</i></small>
										</button>
										<ng-container *ngIf="item.ispromo">
											<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
												<small><i class="material-icons">settings</i></small>
											</button>
											<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
												<small><i class="material-icons">add_box</i></small>
											</button>
										</ng-container>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<ng-container *ngIf="!isGridView">
		<ng-container *ngFor="let item of filtered_items; let i = index;">
		  <div class="row mt-0 mb-2 mb-lg-0 pt-0 pb-0 border-top text-center text-md-left compact-row">
			<div class="col-md-5 col-lg-6 ml-auto mr-auto ml-md-0 mr-md-0 p-0 mt-0 mb-0">
			  <span class="bold pointer" (click)="openModal(itemdetails, item)">
				<span class="description">{{item.brand}} |&nbsp;</span> <span class="stock-id">{{ item.stockid }} |&nbsp;</span> <span class="brand">{{ item.description }}</span>
			  </span>
			</div>
			<div class="col-md-4 col-lg-2 mt-0 p-0 text-center compact-info" [ngClass]="showfilters ? 'col-xl-1' : ''" *ngIf="!salesman && !isdriver">
			  <div class="d-flex justify-content-between align-items-center flex-fill">
				<div class="compact-item pointer" matTooltip="All location QOH: {{ item.locqoh }}" matTooltipPosition="above">
				  <strong>QOH</strong><br>{{ item.locqoh }}
				</div>
				<div class="compact-item">
				  <strong>COMM</strong><br>{{ item.qos }}
				</div>
				<div class="compact-item">
				  <strong>AVAIL</strong><br>{{ item.locqoh - item.qos }}
				</div>
			  </div>
			</div>
			<div [ngClass]="showfilters ? 'col-12 col-sm-1 col-md-1 col-lg-1 ml-auto mr-auto mr-md-0 showqtys' : 'col-12 col-sm-1 col-md-3 col-lg-1 ml-auto mr-auto mr-md-0 showqtys'">
			  <div class="row mt-0 mb-0 border-r j-center qty-box ml-auto mr-auto" [ngClass]="item.specialflag ? 'specialbox' : ''" *ngIf="issalesman && item.specialflag">
				<ng-container>
				  <span *ngIf="item.specialflag" class="m-0 pb-0 text-center ml-auto mr-auto icon"><span>
					  <mat-icon>star</mat-icon>
					</span></span>
				</ng-container>
			  </div>
			</div>
			<div class="col-12 col-md-3 mt-0 ml-auto mr-auto">
			  <div class="row p-0 m-0 text-center">
				<div class="col-auto mt-1 ml-auto mr-0 p-0 shrink-2">
				  <button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm " (click)="decrement(item)">
					<small><i class="material-icons">remove</i></small>
				  </button>
				</div>
				<div class="m-0 p-0 the-dense-zone text-center" [ngClass]="showfilters ? 'col-4' : 'col-6 mw100'">
				  <input type="text" tabindex="{{ i + 2 }}" pattern="[0-9]*" value="{{ item.quantity | number }}" autofocus (input)="updateSearchQuantity($event, item )" class="quantity-input col-auto">
				</div>
				<div class="col-auto mt-1 m-0 mr-0 p-0 shrink-2">
				  <ng-container *ngIf="!item.ispromo">
					<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="increment(item)">
					  <small><i class="material-icons">add</i></small>
					</button>
				  </ng-container>
				  <ng-container *ngIf="item.ispromo">
					<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
					  <small><i class="material-icons">settings</i></small>
					</button>
					<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
					  <small><i class="material-icons">add_box</i></small>
					</button>
				  </ng-container>
				</div>
			  </div>
			</div>
			<div class="col-6 col-md-2 col-lg-1 ml-auto mb-0 mt-0 ml-auto mr-auto">
			  <div [ngClass]="(item.quantity > 0) ? 'linetotal_row_picked' : 'linetotal_row'" class="row">
				<span class="linetotal_price">
				  &nbsp;{{ getLineTotal(item) | currency }} &nbsp;
				</span>
				<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="addToOrder(item)" *ngIf="!item.ispromo && !item.isevent">
				  <small><i class="material-icons">add_box</i></small>
				</button>
				<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="triggerEdu(item)" *ngIf="item.isevent">
				  <small><i class="material-icons">settings</i></small>
				</button>
				<ng-container *ngIf="item.ispromo">
				  <button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
					<small><i class="material-icons">settings</i></small>
				  </button>
				  <button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
					<small><i class="material-icons">add_box</i></small>
				  </button>
				</ng-container>
			  </div>
			</div>
		  </div>
		</ng-container>




	</ng-container>
</div>
