<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Select Bins</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-times"></i>
		</button>
	</div>
	<div class="modal-body text-dark">
		<table class="table table-hover">
			<thead>
				<tr>
					<th>Item Code</th>
					<th>Bin</th>
					<th>Total Needed</th>
					<th>Qty In Bin</th>
					<th>Add</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let bin of needbin" [ngClass]="{'d-none': bin.binmax === '0'}">
					<td>{{ bin.stockid }}</td>
					<td>{{ bin.bin }}</td>
					<td>{{ bin.needquantity }}</td>
					<td>{{ bin.binmax }}</td>
					<td>
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>Qty</mat-label>
							<input matInput type="number" [(ngModel)]="bin.quantity" (input)="updateBinQuantity($event, bin)" class="text-right" min="0">
						</mat-form-field>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="modal-footer">
		<button class="btn btn-primary" (click)="addBins(needbin)">Add Items To Transfer</button>
		<button class="btn btn-secondary" (click)="modal.dismiss()">Cancel</button>
	</div>
</ng-template>




<div class="main-content">
	<div class="row mt--75">
		<div class="col-md-9">
			<h3>Create Transfer </h3>
		</div>
		<div class="col-md-3">
			<button mat-raised-button color="accent" (click)="flip()" class="resetbtn">
				Reset
			</button>
		</div>
	</div>
	<div class="container-fluid">
		<form [formGroup]="transferForm" *ngIf="transferForm">
			<div class="row">
				<div class="col-md-6">
					<mat-card>
						<mat-card-header>
							<mat-card-title class="text-dark">From Location</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<mat-form-field appearance="fill" class="full-width">
								<mat-label>From Location</mat-label>
								<mat-select formControlName="fromstkloc" (selectionChange)="updateHeader()">
									<mat-option *ngFor="let loc of tranlocations" [value]="loc.loccode">
										{{loc.locationname}}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<div class="address-display" *ngIf="fromaddress">{{ fromaddress }}</div>
						</mat-card-content>
					</mat-card>
				</div>

				<div class="col-md-6">
					<mat-card>
						<mat-card-header>
							<mat-card-title class="text-dark">To Location</mat-card-title>
						</mat-card-header>
						<mat-card-content>
							<mat-form-field appearance="fill" class="full-width">
								<mat-label>To Location</mat-label>
								<mat-select formControlName="tostkloc" (selectionChange)="updateHeader();focusSearch()">
									<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
										{{loc.locationname}}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<div *ngIf="transferData.header.fromstkloc === transferData.header.tostkloc" class="error-message">
								<span>Duplicate From and To locations</span>
								<mat-icon color="warn">warning</mat-icon>
							</div>
							<div class="address-display" *ngIf="transferData.header.tostkloc != ''">{{ toaddress }}</div>
						</mat-card-content>
					</mat-card>
				</div>
			</div>


			<div class="row" *ngIf="tabreset == 1">
				<div class="col-md-12 pr-3 ">
					<mat-form-field class="ml-1 w-100 full-width" appearance="fill">
						<mat-label>Transfer Note</mat-label>
						<textarea matInput formControlName="notes" placeholder="Transfer notes"></textarea>
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<mat-tab-group class="w-100 " [(selectedIndex)]="tabreset">
					<mat-tab label="Item Lookup" preserveContent="true" class="item-lookup-tab">

						<div class="item-lookup-container">
							<h4 *ngIf="!transferData.header.tostkloc" class="warning-message">Please select a "to" location.</h4>

							<div class="search-bar-container" *ngIf="transferData.header.tostkloc">
							  <mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="50" id="paginator"></mat-paginator>
							  <!-- [ngClass]="!items.length ? 'd-none' : ''" -->
							  <div *ngIf="searching" class="spinner-container mt-0 mr-1">
								<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							  </div>

							  <mat-form-field appearance="outline" class="search-bar">
								<input
								  matInput
								  #itemrec
								  formControlName="searchVal"
								  id="itemrec"
								  autocomplete="off"
								  placeholder="Item Search"
								  class="text-right"
								  (keyup.enter)="itemSearch()"
								>
								<button mat-icon-button matSuffix (click)="itemSearch()">
								  <mat-icon>search</mat-icon>
								</button>
							  </mat-form-field>

							  <div class="toggle-container">
								<mat-slide-toggle [formControl]="fillOpenOrdersOnly" >Fill Open Orders Only</mat-slide-toggle>
							  </div>
							</div>

							<div class="table-container" *ngIf="items.length > 0">
								<table class="styled-table">
									<thead>
										<tr class="sticky-header" #tableHeader>
											<th>Item #</th>
											<th>Description</th>
											<th>Price</th>
											<th>Bins</th>
											<th>QOH at {{ transferData.header.fromstkloc }}</th>
											<th>QOH at {{ transferData.header.tostkloc }}</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let part of dataObs | async; let i = index; trackBy: trackByStockId" [ngClass]="{'item-to-add': part.quantity > 0}">
											<td>{{ part.stockid }}</td>
											<td>{{ part.description }}</td>
											<td>{{ part.price | currency }}</td>
											<td [innerHTML]="displayBins(part)"></td>
											<td>{{ part.fromqoh }}</td>
											<td>
												<div class="inventory-info">
													<span class="current-quantity">{{ part.toqoh }}</span>
													<ng-container *ngFor="let bin of getTotalTransferQtyByPart(part)">
														<span class="plus-icon">+</span>
														<span class="transfer-quantity">{{ bin.bin }}: {{ bin.quantity }}</span>
														<button mat-icon-button color="warn" (click)="removeTransferLine(part.stockid, bin.bin)" aria-label="Remove transfer line">
															<mat-icon>remove_circle</mat-icon>
														</button>
													</ng-container>
												</div>
											</td>
											<td>
												<div class="action-buttons" #inputElement>
													<ng-container *ngIf="part.bins.length > 1">
														<div class="inventory-info">
															<div *ngFor="let bin of part.bins; let j = index">
																<div class="bin-container">
																	<mat-form-field class="quantity-input" appearance="outline">
																		<mat-label>{{ bin.bin }} - Max {{ bin.maxqty }}</mat-label>
																		<ng-container *ngIf="i === 0">
																			<input #firstItem matInput type="text" min="0" value="{{bin.quantity}}" tabindex="{{ (i * 10) + j + 1 }}" (input)="updateBinQuantity($event, part, bin)" (keydown.enter)="handleEnterKeyMulti($event, part, bin)" (keydown.tab)="handleTabKey($event, j)">
																		</ng-container>
																		<ng-container *ngIf="i !== 0">
																			<input matInput type="text" min="0" value="{{bin.quantity}}" tabindex="{{ (i * 10) + j + 1 }}" (input)="updateBinQuantity($event, part, bin)" (keydown.enter)="handleEnterKeyMulti($event, part, bin)" (keydown.tab)="handleTabKey($event, j)">
																		</ng-container>
																	</mat-form-field>
																</div>
															</div>
														</div>
														<button tabindex="-1" mat-stroked-button color="accent" (click)="addBinQtys(part)">
															<mat-icon>add_box</mat-icon>
														</button>
													</ng-container>

													<ng-container *ngIf="part.bins.length === 1">
														<button tabindex="-1" mat-stroked-button color="primary" class="mt-0 btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(part)">
															<mat-icon>remove</mat-icon>
														</button>

														<mat-form-field class="quantity-input" appearance="outline">
															<mat-label>Qty</mat-label>
															<ng-container *ngIf="i === 0">
																<input #firstItem matInput type="text" [value]="part.quantity" tabindex="{{ (i * 10) + 2 }}" class="text-center" (input)="updateSearchQuantity($event, part)" (keydown.enter)="handleEnterKey($event, items)" (keydown.tab)="handleTabKey($event, i)">
															</ng-container>
															<ng-container *ngIf="i !== 0">
																<input matInput type="text" [value]="part.quantity" tabindex="{{ (i * 10) + 2 }}" class="text-center" (input)="updateSearchQuantity($event, part)" (keydown.enter)="handleEnterKey($event, items)" (keydown.tab)="handleTabKey($event, i)">
															</ng-container>
														</mat-form-field>

														<button tabindex="-1" mat-stroked-button color="primary" class="mt-0 btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(part)">
															<mat-icon>add</mat-icon>
														</button>

														<button tabindex="-1" mat-stroked-button color="accent" (click)="addItems(items)">
															<mat-icon>add_box</mat-icon>
														</button>
													</ng-container>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Transfer Review ({{transfer_qty }} &nbsp;items @ {{transfer_total | currency}})">
						<div class="row m-0 p-0">
							<ng-container *ngIf="transferData.lines.length > 0">
								<table class="m-auto w-90">
									<thead>
										<tr>
											<th>Item Number</th>
											<th>Description</th>
											<th>Bin Location</th>
											<th>Qty to Transfer</th>
											<th>Price</th>
											<th>Ext.</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let line of transferData.lines" class="reviewRow">
											<td>{{line.stockid}}</td>
											<td>
												<ng-container *ngIf="line.narrative != ''">{{line.narrative}}</ng-container>
												<ng-container *ngIf="line.narrative == ''">{{line.description}}</ng-container>
											</td>
											<td>{{line.bincode}}
											<td>
												<mat-form-field appearance="outline" class="w-50">
													<mat-label>Qty</mat-label><input matInput [disabled]="!(line.releasedqty == 0)" value="{{line.transferqty}}" (input)="updateLineQty($event, line )">
												</mat-form-field>
											</td>
											<td>{{line.price | currency}}</td>
											<td>{{line.price * line.transferqty | currency}}</td>
											<td><button style="display: none;">dummy button</button><button tabindex="-1" class="btn-danger btn-just-icon btn-sm btn btn-fab btn-round" (click)="$event.stopPropagation();$event.preventDefault();removeItem(line)" (keyup.enter)="$event.stopPropagation();$event.preventDefault();"><small><i class="material-icons">remove_box</i></small></button></td>
										</tr>
									</tbody>
								</table>
								<hr class="w-100 ">
								<div class="row m-auto w-90 justify-content-end ">
									<h4 class="line-h45">Transfer Total: {{transfer_total | currency}} &nbsp;&nbsp;</h4>
									<button class="btn btn-success" (click)="submitTransfer()" [disabled]="transferData.header.fromstkloc === transferData.header.tostkloc">
										Submit Transfer
									</button>
								<!--	<button class="btn btn-danger" (click)="submitAndMoveTransfer()" [disabled]="transferData.header.fromstkloc === transferData.header.tostkloc">
										Instant Transfer
									</button> -->
								</div>
							</ng-container>
							<ng-container *ngIf="transferData.lines.length == 0">
								<h3 class="m-auto w-90" style="overflow: hidden; text-align: center;">No items on transfer.</h3>
								{{tabreset}}
							</ng-container>

						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</form>
	</div>
</div>
