<div class="main-content">
    <div class="card card-spacing">
        <div class="card-header card-header-{{color}}-6">
            TRUCK UNLOADING
        </div>
        <div class="card-body">

            <div class="col-12" [formGroup]="truckForm" *ngIf="formReady">
                <mat-form-field appearance="outline">
                    <mat-label>Truck to unload</mat-label>
                    <mat-select formControlName="truckref">
                        <mat-option [value]="">-</mat-option>
                        <ng-container *ngFor="let truck of returnedTrucks">
                            <mat-option [value]="truck.details">
                                {{truck.name}} &nbsp; {{truck.driver}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 p-0 m-0">
                <ul *ngIf="truckForm.get('truckref').value" class="w-100 m-1 p-1">
                    <li *ngFor="let da of truckForm.get('truckref').value" class="list-unstyled p-0 m-0">
                        <div class="card" *ngIf="da.status_id == 7 || da.type == 11">
                            <div class="card-header text-white" [ngClass]="da.status_id == 7 ? 'bg-danger' : 'credit-bg'">
                                <div class="float-left">#{{da.orderno}}</div>
                                <div class="float-right">{{da.debtorno}}.{{da.branchcode}}&nbsp; &nbsp; &nbsp;{{da.brname}}</div>
                            </div>
                            <div class="card-body">
                                <div class="w-100">
                                    <div class="col-12 row">
                                    <div class="lrg-font">{{da.stkcode}}</div>
                                    <div class="med-font align-middle">
                                        <ng-container *ngIf="da.type != 11">
                                            &nbsp;&nbsp;&nbsp;{{da.loaded}}&nbsp;/&nbsp;{{da.ordered}}&nbsp;<mat-icon class="align-middle">local_shipping</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="da.type == 11">
                                            &nbsp;&nbsp;&nbsp;{{da.quantity}}&nbsp;/&nbsp;{{da.quantity}}&nbsp;<mat-icon class="align-middle">local_shipping</mat-icon>
                                        </ng-container>
                                    </div>
                                    </div>
                                    <br>
                                    <div *ngIf="!da.unloading" class="med-font">{{da.itemdesc}}</div>
                                    <mat-form-field *ngIf="da.unloading && !da.error" [formGroup]="barcodeForm">
                                        <mat-label>Scan to Unload</mat-label>
                                        <input matInput placeholder="UPC#" value="" formControlName="barcoderef" (keyup.enter)="removePick(da, $event)">
                                    </mat-form-field>
                                    <div *ngIf="da.error" class="text-center error-msg">{{da.error}}</div>
                                </div>
                                <hr>
                                <div class="w-100">
                                    <div class="float-left align-middle med-font" *ngIf="da.type != 11">{{da.name}}</div>
                                    <div class="float-left align-middle med-font" *ngIf="da.type == 11">CREDIT</div>
                                    <button *ngIf="!da.unloading" class="float-right mt-1" mat-stroked-button (click)="da.unloading = true">Unload</button>
                                    <button *ngIf="da.unloading" class="float-right mt-1" mat-stroked-button (click)="da.unloading = false">Back</button>
                                </div>

                            </div>

                            <!-- <div class="card-body" *ngIf="da.unloading">
                                <div class="w-100" [formGroup]="barcodeForm">
                                    <div class="col-12 row">
                                        <div class="lrg-font">{{da.stkcode}}</div>
                                        <div class="med-font align-middle">
                                            &nbsp;&nbsp;&nbsp;{{da.loaded}}&nbsp;/&nbsp;{{da.ordered}}&nbsp;<mat-icon class="align-middle">local_shipping</mat-icon>
                                        </div>
                                    </div>
                                    <br>

                                </div>
                                <hr>
                                <div class="w-100">
                                    <div class="float-left align-middle med-font">{{da.name}}</div>
                                    <button class="float-right mt-1" mat-stroked-button (click)="da.unloading = false">Back</button>
                                </div>
                            </div> -->
                        </div>
                    </li>
                </ul>
            </div>


        </div>
    </div>
</div>