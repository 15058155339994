import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import * as FileSaver from 'file-saver';
import { GlobalsService } from '../../services/globals.service';
import { SystemService } from '../../services/system.service';
import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-company-setup',
	templateUrl: './company-setup.component.html',
	styleUrls: ['./company-setup.component.scss']
})
export class CompanySetupComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	company: any = [];
	companyForm: UntypedFormGroup = this.fb.group({});
	configForm: UntypedFormGroup = this.fb.group({});
	controls: any = [];
	color = 'blue';
	configs: any = [];
	allconfigs: any = [];

	confname = new FormControl('');
	confvalue = new FormControl('');
	conftype = new FormControl('text');
	sectionVisible = false;
	logo: any = '';
	logoFileName: any = '';

	@ViewChild('details') detailsRef: ElementRef;
	termsModal = new FormControl('');
	termscontrol: any;


	constructor(private globalSearchService: GlobalSearchService, private systemService: SystemService, private modalService: NgbModal, private location: Location, public cdr: ChangeDetectorRef, private globalsService: GlobalsService, private fb: UntypedFormBuilder) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.loadData();
	}

	toggleSection() {
		this.sectionVisible = !this.sectionVisible;
	}

	loadData() {
		this.globalsService.getCompany().subscribe(details => {
			this.company = details;
			if (this.company.logo) {
				this.logo = this.company.logo;
			}

			const controls = [];
			const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			Object.keys(details).forEach(key => {
				const value = (details[key]) ? details[key] : '';
				this.controls.push(key)
				controls.push({ name: key, control: new UntypedFormControl(value, []) })
			});
			controls.forEach(f => {
				formGroup.addControl(f.name, f.control, {})
			});

			this.companyForm = formGroup;
		});

		this.globalsService.getConfigKeyPairsWithType().subscribe(details => {

			const controls = [];
			this.allconfigs = details;
			const formGroup: UntypedFormGroup = new UntypedFormGroup({});
			Object.keys(details).forEach(key => {
				const value = (details[key]) ? details[key].value : '';
				this.configs.push(key)
				controls.push({ name: key, control: new UntypedFormControl(value, []) })
			});
			controls.forEach(f => {
				formGroup.addControl(f.name, f.control, {})
			});

			this.configForm = formGroup;
		});
	}


	properCase(str) {
		return str.split(' ').map(function(val) {
			return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
		}).join(' ');
	}

	addConfig() {
		const configData = {
			name: this.confname.value,
			value: this.confvalue.value,
			type: this.conftype.value
		};
		this.globalsService.addConfig(configData).subscribe((result) => {
			if (result.success) {
				this.globalSearchService.showNotification('Configuration Added', 'success', 'bottom', 'right');
				this.confname.setValue('');
				this.confvalue.setValue('');
				this.conftype.setValue('text');
				this.sectionVisible = false;
				this.loadData();
			}
		});
	}


	onSelect(event: any): void {
	  const file = event.addedFiles[0];
	  this.logoFileName = file.name; // Store the original file name
	  const reader = new FileReader();

	  reader.onload = (e: any) => {
		this.logo = e.target.result;
	  };

	  reader.readAsDataURL(file);
	}


	getConfig(config: any) {
		return this.allconfigs[config];
	}


	back(): void {
		this.location.back()
	}

	update() {
	  const formData = { ...this.companyForm.value };

	  // Include the base64 encoded image and file name if they exist
	  if (this.logo && this.logoFileName) {
		formData.logo = this.logo;
		formData.logoFileName = this.logoFileName;
	  }

	  this.globalsService.updateCompany(formData).subscribe((result) => {
		this.globalSearchService.showNotification('Updated Company Settings', 'success', 'bottom', 'right');
	  });
	}

	updateConfig() {
		this.globalsService.updateConfig(this.configForm.value).subscribe((result) => {
			this.globalSearchService.showNotification('Updated Configuration', 'success', 'bottom', 'right');
		});
	}


	openTermsModal(control: any){

		this.termsModal.setValue(this.companyForm.get(control).value);
		this.termscontrol = control;
		this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => {
			this.termscontrol = false;
		});
	}

	dismissTerms(action){
		if(action){
			this.companyForm.get(this.termscontrol).setValue(this.termsModal.getRawValue());
		}
		this.modalService.dismissAll()
	}



}
