<ng-template #popTemplate let-modal>
	<div class="modal-body">
		<div class="form-group">
			<div class="row">
				<div class="col-12 text-center" *ngIf="snoozeForm">
					<form [formGroup]="snoozeForm">
						<label for="numberofdays">Snoozing (Days): {{ snoozeorder }}</label>
						<div class="row">
							<div class="col-4">
								<mat-form-field class="example-full-width" appearance="fill">
									<mat-label>Initials</mat-label>
									<input matInput type="text" placeholder="Initials" [value]="" name="initials" id="initials" formControlName="initials">
									<mat-error>
										<span *ngIf="!snoozeForm.get('initials').valid && snoozeForm.get('initials').touched">
											Enter your Initials!</span>
									</mat-error>
								</mat-form-field>
							</div>
							<div class="col-8">
								<mat-form-field class="full-width" appearance="fill">
									<mat-label>Reason For Snooze</mat-label>
									<input matInput #message maxlength="128" placeholder="Reason for snooze ..." name="message" id="message" formControlName="message" [value]="">
									<mat-hint align="end">{{message.value.length}} / 128 </mat-hint>
									<mat-error>
										<span *ngIf="!snoozeForm.get('message').valid && snoozeForm.get('message').touched">
											Enter a reason!
										</span>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</form>

					<div class="row">
						<mat-form-field color="accent" appearance="fill" class="col-5">
							<mat-label>Pick a Day</mat-label>
							<input matInput [matDatepicker]="picker1" (dateInput)="dateChanged($event)" (dateChange)="dateChanged($event)" [(ngModel)]="initaldate">
							<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</mat-form-field>

						<h6 class="col-2 ml-0 mr-0"><b>OR</b></h6>

						<mat-form-field class="example-full-width" appearance="fill" class="col-5">
							<mat-label>Number Of Days</mat-label>
							<input matInput type="number" placeholder="Number Of Days" [value]="numberofdays" name="numberofdays" id="numberofdays" (input)="setSnooze($event)" restrict-to="[0-9]">
						</mat-form-field>
					</div>

					<button class="btn btn-sm btn-rose" (click)="snooze(snoozeorder)">
						<i class="material-icons">snooze</i>
					</button>

				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div *ngIf="orderdetails.dispatch_details && orderdetails.dispatch_details.status_id == '29'">
				<div #mapDiv class="w-100" [ngClass]="{'d-none': !orderdetails.dispatch_details.nextlast}">
					EST: {{orderdetails.dispatch_details.nextlast}}
					<div class="w-100" id="map"></div>
				</div>
			</div>
			<div class="row border-bottom pb-2">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>

			<div class="row mt-2 mb-2 border-bottom pb-2">
				<div class="col-md-3">
					<h5 class="mb-0">Ship To:</h5>
					<mat-divider></mat-divider>
					<span class="mt-2 lesser" [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0">Phone:</h5>
					<mat-divider></mat-divider>
					<span class="mt-2 lesser">{{orderdetails.header.contactphone}}</span>

					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
						<h5 class="mb-0 mt-2">Email:</h5>
						<mat-divider></mat-divider>
						<span class="mt-2 lesser">
							<a [href]="'mailto:' + orderdetails.header.contactemail">{{orderdetails.header.contactemail}}</a>
						</span>
					</ng-container>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0">Notes:</h5>
					<mat-divider></mat-divider>
					<span class="mt-2 lesser">{{orderdetails.header.comments}}</span>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0 lesser">History:</h5>
					<mat-divider></mat-divider>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul class="mt-2">
							<li *ngIf="orderdetails.dispatch_details.truck_name">
								ShipVia: {{ orderdetails.dispatch_details.truck_name }}<br>
								{{ orderdetails.dispatch_details.driveruserid }}
							</li>
							<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">
								Dispatched: {{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
								{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}
							</li>
							<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">
								Delivered: {{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
								{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table-responsive mt-3">
				<table class="table table-bordered table-striped table-hover normal">
					<thead class="thead-dark">
						<tr>
							<th>ITEM</th>
							<th>DESC</th>
							<th class="text-center">QOH</th>
							<th class="text-center">ORDERED</th>
							<th class="text-center">INVOICED</th>
							<th class="text-center">OUTSTANDING</th>
							<th class="text-right">PRICE</th>
							<th class="text-right" *ngIf="config.discounts">DISCOUNT</th>
							<th class="text-right">TOTAL</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of orderdetails.details">
							<td>
								<button mat-icon-button (click)="copyToClipboard(item.stkcode)" class="ml-2" title="Copy to clipboard">
									<mat-icon>content_copy</mat-icon>
								</button>
								<a [routerLink]="'/inventory/view/'+item.stkcode" (click)="modal.dismiss('route change')">{{item.stkcode}}</a>
							</td>
							<td>{{item.description}}</td>
							<td class="text-center">{{item.qoh}}</td>
							<td class="text-center">{{item.ordered}}</td>
							<td class="text-center">{{item.qtyinvoiced}}</td>
							<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
							<td class="text-right">{{item.unitprice | currency}}</td>
							<td class="text-right" *ngIf="config.discounts">{{item.discountpercent | percent }}</td>
							<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr *ngFor="let t of orderdetails.saved_totals">
							<ng-container *ngIf="config.discounts">
								<td colspan="7"></td>
							</ng-container>
							<ng-container *ngIf="!config.discounts">
								<td colspan="6"></td>
							</ng-container>
							<td class="text-right">{{ t.title }}:</td>
							<td class="text-right">{{t.text | currency}}</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer"></div>
</ng-template>
<ng-template #purchaseOrderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="podetails">
			<a [routerLink]="'/vendors/'+podetails.poheader.supplierno">{{ podetails.poheader.supplierno }}</a> Purchase Order
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<!-- Purchase Order Tab -->
			<mat-tab label="Purchase Order">
				<div class="mb-3" *ngIf="podetails">
					<h4 class="mt-3">PO #{{ podetails.poheader.orderno }}</h4>
					<div class="card-deck mt-3">
						<div class="card col-6">
							<div class="card-header">
								<strong>Contact Information</strong>
							</div>
							<div class="card-body">

								<p class="card-text">
									<strong>Contact:</strong> {{podetails.poheader.contact}}<br>
									<strong>Address:</strong>
									<ng-container *ngIf="podetails.address">
										<div [innerHTML]="podetails.address"></div>
									</ng-container>
								</p>
								<!-- <p><strong>Supplier Delivery Address:</strong>
									<ng-container *ngIf="podetails.poheader.suppdeladdress1">{{podetails.poheader.suppdeladdress1}}<br></ng-container>
									<ng-container *ngIf="podetails.poheader.suppdeladdress2">{{podetails.poheader.suppdeladdress2}}<br></ng-container>
									<ng-container *ngIf="podetails.poheader.suppdeladdress3">{{podetails.poheader.suppdeladdress3}},</ng-container>
									<ng-container *ngIf="podetails.poheader.suppdeladdress4">{{podetails.poheader.suppdeladdress4}}</ng-container>
									<ng-container *ngIf="podetails.poheader.suppdeladdress5">{{podetails.poheader.suppdeladdress5}}</ng-container>
								</p> -->
							</div>
						</div>
						<div class="card col-6">
							<div class="card-header">
								<strong>Delivery Information</strong>
							</div>
							<div class="card-body">
								<p class="card-text">
									<strong>Delivery Address:</strong><br>
									<ng-container *ngIf="podetails.poheader.deladd1">{{podetails.poheader.deladd1}}<br></ng-container>
									<ng-container *ngIf="podetails.poheader.deladd2">{{podetails.poheader.deladd2}}<br></ng-container>
									<ng-container *ngIf="podetails.poheader.deladd3">{{podetails.poheader.deladd3}},</ng-container>
									<ng-container *ngIf="podetails.poheader.deladd4">{{podetails.poheader.deladd4}}</ng-container>
									<ng-container *ngIf="podetails.poheader.deladd5">{{podetails.poheader.deladd5}}</ng-container>
								</p>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="card col-6">
							<div class="card-header">
								<strong>Notes</strong>
							</div>
							<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
						</div>
						<div class="card col-6">
							<div class="card-header">
								<strong>Totals</strong>
							</div>
							<div class="card-body">
								<p class="card-text">
									<strong>Total Quantity:</strong> {{ getTotalQuantity() }}<br>
									<strong>Total Value:</strong> {{ getTotalValue() | currency }}<br>
								</p>
							</div>
						</div>
					</div>
					<div class="table-responsive mt-3">
						<table class="table table-bordered table-striped table-hover normal" #pdata>
							<thead class="thead-dark">
								<tr>
									<th>ITEM</th>
									<th>DESC</th>
									<th class="text-center">QTY</th>
									<th class="text-center">RCVD</th>
									<th class="text-center">PEND</th>
									<th class="text-right">PRICE</th>
									<th class="text-right">TOTAL</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of podetails.polines">
									<td>
										<button mat-icon-button (click)="copyToClipboard(item.itemcode)" class="ml-2" title="Copy to clipboard">
											<mat-icon>content_copy</mat-icon>
										</button>

										<a [routerLink]="'/inventory/view/'+item.itemcode" (click)="modal.dismiss('route change')">
											{{item.itemcode}}
										</a>

									</td>
									<td>{{item.itemdescription}}</td>
									<td class="text-center">{{item.quantityord}}</td>
									<td class="text-center">{{item.quantityrecd}}</td>
									<td class="text-center">{{item.quantityord - item.quantityrecd}}</td>
									<td class="text-right">{{item.unitprice | currency}}</td>
									<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2"><strong>Total</strong></td>
									<td class="text-center"><strong>{{ getTotalQuantity() }}</strong></td>
									<td colspan="3"></td>
									<td class="text-right"><strong>{{ getTotalValue() | currency }}</strong></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</mat-tab>




			<!-- Receiving Details Tab -->
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>GRN</th>
								<th>Order#</th>
								<th>Item#</th>
								<th>Description</th>
								<th>Received</th>
								<th>Invoiced</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.receiving">
								<td>{{ r.grnno }}</td>
								<td>{{ r.poorderno }}</td>
								<td>{{ r.itemcode }}</td>
								<td>{{ r.itemdescription }}</td>
								<td>{{ r.qtyrecd }}</td>
								<td>{{ r.quantityinv }}</td>
								<td>{{ r.update_date }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					<p>No Receiving Details</p>
				</ng-container>
			</mat-tab>
			<!-- Receiving Movements Tab -->
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>Item#</th>
								<th>Location</th>
								<th>User</th>
								<th>Quantity</th>
								<th>QOH</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.movements">
								<td>{{ r.stockid }}</td>
								<td>{{ r.loccode }}</td>
								<td>{{ r.userid }}</td>
								<td>{{ r.qty }}</td>
								<td>{{ r.newqoh }}</td>
								<td>{{ r.trandate }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					<p>No Movements</p>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Close</button>
	</div>
</ng-template>

<div class="row m-0 p-0 mt-3">
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>Warehouse</mat-label>
			<mat-select [formControl]="defaultlocation" (selectionChange)="filterWarehouse($event.value)">
				<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
					{{ loc.locationname }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="col-md-2">
		<mat-form-field appearance="outline" *ngIf="config.env.package === 'beauty'">
			<mat-label>Order Source</mat-label>
			<mat-select [(ngModel)]="selectedOrderSource" (selectionChange)="filterItemSource()">
				<mat-option [value]="''">All</mat-option>
				<mat-option *ngFor="let source of orderSources" [value]="source.value">
					{{ source.viewValue }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>ShipVia</mat-label>
			<mat-select [formControl]="shipvia" (selectionChange)="filterShipVia()">
				<mat-option [value]="''">All</mat-option>
				<mat-option *ngFor="let loc of shipvias" [value]="loc.shipper_id">
					{{ loc.shippername }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>Status</mat-label>
			<mat-select [formControl]="statusSearch" (selectionChange)="filterItems()">
				<mat-option [value]="''">All</mat-option>
				<mat-option *ngFor="let stat of order_statuses" [value]="stat.order_status_id">
					{{ stat.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<!-- <mat-form-field appearance="outline">
				<mat-label>Status</mat-label>
				<input matInput [formControl]="searchInputItems" (input)="searchItems()" placeholder="Search..." class=:text-right>
				<span matSuffix>
					<button mat-icon-button (click)="searchItems()">
						<mat-icon>search</mat-icon>
					</button>
				</span>
			</mat-form-field> -->
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>Item Search</mat-label>
			<input matInput [formControl]="searchInputItems" (input)="searchItems()" placeholder="Search..." class="text-right" appCollapseKeyboard>
			<span matSuffix>
				<button mat-icon-button (click)="searchItems()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
	<div class="" [ngClass]="(config.env.package === 'beauty') ? 'col-md-2': 'col-md-3'">
		<mat-form-field appearance="outline">
			<mat-label>Order Search</mat-label>
			<input matInput [formControl]="searchInput" (input)="onInput(searchInput.value)" placeholder="Search..." class="text-right" appCollapseKeyboard>
			<span matSuffix>
				<button mat-icon-button (click)="search()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
			<span matSuffix>
				<button mat-icon-button (click)="loadData()">
					<mat-icon>refresh</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
</div>

<ng-container *ngIf="config.env.package == 'beauty'">
		<mat-paginator [ngClass]="openOrderData ? '': 'd-none'" [pageSizeOptions]="[100, 500, 1000]" showFirstLastButtons aria-label="select page">
		</mat-paginator>
	</ng-container>

<div class="table table-responsive text-center" *ngIf="!openorders">
	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
		</circle>
	</svg>
</div>
<div class="row" *ngIf="!openOrderData">
	<div class="col-md-12">
		<h4 class="mt-3 text-center">No Open Orders</h4>
	</div>
</div>
<!-- <div class="legend">
	<div class="legend-item placedfrom-1">Backoffice</div>
	<div class="legend-item placedfrom-2">POS</div>
	<div class="legend-item placedfrom-3">Salesman</div>
	<div class="legend-item placedfrom-4">Online</div>
</div> -->


<div class="table-container w-100">
	<table mat-table [dataSource]="openorders" matSort (matSortChange)="announceSortChange($event)" [ngClass]="openOrderData ? 'w-100 smallertable': 'd-none'">

		<ng-container matColumnDef="orderno">

			<th mat-header-cell *matHeaderCellDef mat-sort-header>

				<button mat-icon-button color="primary" (click)="toggleMerging()" *ngIf="config.env.package==='beauty'">
					<mat-icon>{{ isMerging ? 'cancel' : 'merge_type' }}</mat-icon>
				</button>


				<button mat-icon-button color="accent" (click)="mergeOrders()" *ngIf="isMerging">
					<mat-icon>merge_type</mat-icon>
				</button>
				Order#
			</th>
			<td mat-cell *matCellDef="let tran">

				<mat-checkbox (change)="toggleOrderSelection($event, tran)" [checked]="isSelected(tran)" *ngIf="isMerging"></mat-checkbox>

				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">

					<div class="bg-danger text-white w-100" *ngIf="tran.invoiced">
						ORDER HAS BEEN PAID
					</div>

					<button mat-menu-item (click)="viewOrder(tran.orderno, orderDetails)" *ngIf="!tran.invoiced">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>

					<button mat-menu-item (click)="viewInvoicedOrder(tran.orderno, orderDetails)" *ngIf="tran.invoiced && !issalesman && !isPosUser">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>
					<a href="{{invoicelink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>Customer Copy</span>
					</a>
					<mat-divider></mat-divider>
					<!-- pre order for salesman -->
					<ng-container *ngIf="config.env.package == 'beauty'">
						<button mat-menu-item (click)="loadOrder(tran)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && tran.orderstatusid ==='3' && issalesman">
							<mat-icon>edit</mat-icon>
							<span>Edit Order</span>
						</button>
						<button mat-menu-item (click)="loadOrder(tran)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && !issalesman">
							<mat-icon>edit</mat-icon>
							<span>Edit Order</span>
						</button>
					</ng-container>
					<ng-container *ngIf="config.env.package == 'tires'">
						<button mat-menu-item (click)="loadOrder(tran)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && tran.orderstatusid ==='3' && issalesman  && tran.details.cleared != 1 && tran.details.passed != 1 && tran.details.invoiced == 0">
							<mat-icon>edit</mat-icon>
							<span>Edit Order</span>
						</button>
						<button mat-menu-item (click)="loadOrder(tran)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && !issalesman && tran.details.cleared != 1 && tran.details.passed != 1 && tran.details.invoiced == 0">
							<mat-icon>edit</mat-icon>
							<span>Edit Order</span>
						</button>
					</ng-container>
					<mat-divider></mat-divider>
					<a href="{{pickinglink}}{{tran.orderno}}" mat-menu-item *ngIf="!issalesman && !isPosUser">
						<mat-icon>print</mat-icon>
						<span>Picking PDF</span>
					</a>
					<button mat-menu-item [routerLink]="'/orders/pick/'+tran.orderno" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && !issalesman && !isPosUser">
						<mat-icon>get_app</mat-icon>
						<span>Pick</span>
					</button>

					<button mat-menu-item (click)="invoiceOrder(tran.orderno)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && !issalesman && !isPosUser">
						<mat-icon>description</mat-icon>
						<span> Invoice </span>
					</button>
					<mat-divider></mat-divider>
					<button mat-menu-item (click)="updatePick(tran.orderno)" *ngIf="tran.orderstatusid !='50' && !tran.invoiced && !issalesman && !isPosUser">
						<mat-icon>done</mat-icon>
						<span>Set Picked</span>
					</button>
					<button mat-menu-item (click)="setSnoozeOrder(tran.orderno)" *ngIf="tran.snoozed != '1' && tran.orderstatusid !='50' && !tran.invoiced && !issalesman && !isPosUser">
						<mat-icon>snoozed</mat-icon>
						<span>Snooze</span>
					</button>
					<button mat-menu-item (click)="removeSnooze(tran.orderno)" *ngIf="tran.snoozed == '1' && tran.orderstatusid !='50' && !tran.invoiced && !issalesman && !isPosUser">
						<mat-icon>notifications_active</mat-icon>
						<span>Remove Snooze</span>
					</button>
					<!-- <button mat-menu-item (click)="setDelivered(tran.orderno)">
							<mat-icon>local_shipping</mat-icon>
							<span>Set Delivered</span>
						</button> -->
					<button mat-menu-item (click)="cancelOrder(tran.orderno) " *ngIf="tran.orderstatusid !='50' && !tran.invoiced && !issalesman">
						<mat-icon>remove</mat-icon>
						<span>Cancel</span>
					</button>
				</mat-menu>
			</td>
		</ng-container>
		<ng-container matColumnDef="statusname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Order#/Status</th>
			<td mat-cell *matCellDef="let tran">
				<b>{{ tran.orderno }}</b>
				<br>
				<span class="status-{{ tran.orderstatusid }}">
					{{ tran.statusname }}
				</span>
				&nbsp;&nbsp;<i class="fa fa-edit" (click)="toggleEdit(tran)" *ngIf="tran.orderstatusid !='50' && !issalesman"></i>

				<ng-container *ngIf="tran.edit">
					<mat-form-field appearance="outline">
						<mat-label>Status</mat-label>
						<mat-select [formControl]="order_status_edit">
							<mat-option *ngFor="let s of order_statuses" [value]="s.order_status_id">
								{{ s.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn btn-sm btn-danger" (click)="saveStatusUpdate(tran)">
						<i class="fa fa-save"></i>
					</button>
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="pickprinted">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Printed</th>

			<td mat-cell *matCellDef="let tran" class="text-center smallest">
				<ng-container *ngIf="tran.snoozed == '1' ">
					<mat-icon>notifications_active</mat-icon>
				</ng-container>
				<ng-container *ngIf="tran.snoozed != '1' ">
					<ng-container *ngIf="tran.printedpackingslip == 0">
						<span class="text-danger">N</span>
					</ng-container>
					<ng-container *ngIf="tran.printedpackingslip == 1">
						Y
					</ng-container>
					<ng-container *ngIf="tran.printedpackingslip == ''">

					</ng-container>
				</ng-container>
			</td>

		</ng-container>
		<ng-container matColumnDef="deliverto">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Ship to</th>
			<td mat-cell *matCellDef="let tran">
				<div [innerHTML]="tran.shipto + '<pre>\n</pre>' + tran.details.deliverto" (click)="viewCustomer(tran.debtorno)"></div>

			</td>
		</ng-container>
		<ng-container matColumnDef="datecreated">
		  <th mat-header-cell *matHeaderCellDef mat-sort-header>Wanted/Created</th>
		  <td mat-cell *matCellDef="let tran" class="wanted-cell">
			<ng-container *ngIf="tran.orderstatusid != '3'">
			  <div class="info-row">
				<span class="info-label">Wanted:</span>
				<span class="info-value">{{ tran.deliverydate }}</span>
			  </div>
			</ng-container>
			<ng-container *ngIf="tran.orderstatusid == '3'">
			  <div class="info-row">
				<span class="info-label">Wanted:</span>
				<span class="info-value">{{ tran.details.quotedate | date: 'M/d/YYYY' }}</span>
			  </div>
			</ng-container>
			<div class="info-row">
			  <span class="info-label">Created:</span>
			  <span class="info-value">{{ tran.date_created | date:'shortDate' }} {{ tran.date_created | date:'shortTime' }}</span>
			</div>
			<div class="info-row">
			  <a href="tel://{{ tran.phoneno }}" class="info-link">{{ tran.phoneno }}</a>
			</div>
		  </td>
		</ng-container>

		<ng-container matColumnDef="ponumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Customer PO#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ponumber }}</td>
		</ng-container>
		<ng-container matColumnDef="shipvia">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> ShipVia</th>
			<td mat-cell *matCellDef="let tran">{{ getShipVia(tran.shipvia) }}</td>
		</ng-container>
		<ng-container matColumnDef="haspo">
			<th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="config.env.package === 'beauty' ? 'd-none':''">Purchase#</th>
			<td mat-cell *matCellDef="let tran" [ngClass]="config.env.package === 'beauty' ? 'd-none':''">
				<span [ngClass]="{'po': tran.haspo.length }" *ngFor="let p of tran.haspo">
					<span (click)="viewPurchaseOrder(p.purchase_orderno)" class="pointer">{{ p.purchase_orderno }}</span>
				</span>
				<span *ngIf="tran.suppliers" [ngClass]="{'po': tran.haspo.length }"><br>{{ tran.suppliers }}</span>

			</td>
		</ng-container>
		<ng-container matColumnDef="notes">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Notes</th>
			<td mat-cell *matCellDef="let tran">
				<div [innerHTML]="noNewLines(tran.notes)" class=" comments"></div>
			</td>
		</ng-container>
		<ng-container matColumnDef="comments">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Comments</th>
			<td mat-cell *matCellDef="let tran">
				<div [innerHTML]="tran.comments" class=" comments"></div>
				<div *ngIf="tran.snoozed == '1' ">
					{{tran.snoozed_details.date_added | date: 'MM/dd/yyyy' }}
					<b>{{tran.snoozed_details.initials}}:</b> {{tran.snoozed_details.comments}}
				</div>

			</td>
		</ng-container>


		<!-- <ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
				<td mat-cell *matCellDef="let tran">{{ tran.email }}</td>
			</ng-container> -->

		<ng-container matColumnDef="takenby">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left"> Taken By </th>
			<td mat-cell *matCellDef="let tran" class="text-left">{{ tran.takenby }}</td>
		</ng-container>
		<ng-container matColumnDef="total">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<ng-container *ngIf="config.env.package === 'tires'">
					Total
				</ng-container>
				<ng-container *ngIf="config.env.package !== 'tires'">
					SubTotal
				</ng-container>
			</th>
			<td mat-cell *matCellDef="let tran">
				<ng-container *ngIf="config.env.package === 'tires'">
					{{ tran.total | currency}}
				</ng-container>
				<ng-container *ngIf="config.env.package !== 'tires'">
					{{ tran.subtotal | currency}}
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="void" *ngIf="!issalesman">
			<th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="!config.arpreauth ? 'd-none' : ''"> </th>
			<td mat-cell *matCellDef="let tran" [ngClass]="!config.arpreauth && config.env.package === 'beauty' ? 'd-none' : 'text-right'">
				<button mat-raised-button class="void-button" (click)="voidPreAuth(tran.orderno); tran.orderstatusid = 1;" *ngIf="tran.orderstatusid === '53'">
				  <mat-icon>cancel</mat-icon> VOID
				</button>

			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns" [ngClass]="'placedfrom-' + row.details.placedfrom"></tr>
	</table>
	<table *ngIf="openOrderData && !openOrderData.length">
		<tr>
			<td>
				<h4 class="mt-2 text-center">No Open Orders</h4>
			</td>
		</tr>
	</table>
	<ng-container *ngIf="config.env.package == 'tires'">
		<mat-paginator [ngClass]="openOrderData ? '': 'd-none'" [pageSizeOptions]="[100, 500, 1000]" showFirstLastButtons aria-label="select page">
		</mat-paginator>
	</ng-container>
</div>