import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PaymentsService } from '../../services/payments.service';
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-payment-report',
	templateUrl: './payment-report.component.html',
	styleUrls: ['./payment-report.component.scss']
})
export class PaymentReportComponent implements OnInit {
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('voidDetails') voidDetailsRef: ElementRef;
	@ViewChild('journalDetails') journalDetailsRef: ElementRef;

	color = 'blue';

	payments: any = [];
	orignal_results: any = [];

	today = new Date();
	filename = 'paymentreport';

	datefrom = new UntypedFormControl(this.today);
	dateto = new UntypedFormControl(this.today);
	paymenttypefilter = new UntypedFormControl('all');
	create_credit = new UntypedFormControl(true);
	voiddate = new UntypedFormControl(new Date());
	payment_type_edit = new UntypedFormControl();
	userjournal = new UntypedFormControl();
	payment_journal_edit = new UntypedFormControl();
	running = false;
	total_pay: any = '';
	voiding = false;

	journals: any = false;
	payment_methods: any = false;
	void_trans: any = false;
	payment_journal_editing: any = false;
	journalaction: any = false;
	openjournals: any = false;

	total_sub: any = 0;
	total_tax: any = 0;
	total_freight: any = 0;
	total_fee: any = 0;
	total_discount: any = 0;

	locations: any = [];
	user: any = false;
	defaultlocation = new UntypedFormControl('');
	ref_location: any = false;

	cctotal = 0;
	constructor(private location: Location, private printService: PrintService,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private route: ActivatedRoute, public router: Router,private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.payment_methods.subscribe(r => {
			this.payment_methods = r;
		});

		this.globalSearchService.user.subscribe( results => {
			//only run if user is definied
			if(this.user) {
				if(results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue([this.user.user.defaultlocation.loccode]);
					this.ref_location = false;
				}
			}

			this.user = results

			if(!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}

			this.globalSearchService.locations.subscribe(async (results: any) => {
				this.locations = results;

				if (results) {
					const val = [
						this.user.user.defaultlocation.loccode
					];
					this.defaultlocation.setValue(val)
					this.loadData();
				}
			});

		});
	}

	ngOnInit(): void {
		this.loadData();
		this.paymenttypefilter.valueChanges.subscribe(newValue => {
			if(this.orignal_results.transactions) {
				if(newValue != 'all') {

					this.payments.transactions = this.orignal_results.transactions.filter( p => {
						return newValue.paymentname == p.banktranstype;
					});
				} else {
					this.payments.transactions = this.orignal_results.transactions;
				}
			}
		});
	}

	getLocationName() {
		let name = 'N/A';
		if(this.defaultlocation.value.length) {
			name = '';
			this.defaultlocation.value.forEach(v => {

				const loc = this.locations.filter( r => {
					return v == r.loccode
				})[0];
				if(loc) {
					name += loc.locationname+ ' ';
				}
			});
		}

		return name;
	}

	savePaymentUpdate(payment: any) {

		const data = {
			trans: payment,
			type: this.payment_type_edit.value,
		}

		this.paymentsService.updateBankTrans(data).subscribe( (r:any) => {
			if(r.success) {
				payment.edit = false;
				payment.banktranstype = data.type;
				this.payment_type_edit.reset();
			}
		})
	}

	updateJournal() {

		const data = {
			payment: this.payment_journal_editing,
			tojournal: this.payment_journal_edit.value,
		}

		this.paymentsService.moveJournal(data).subscribe((result: any) => {
			this.modalService.dismissAll();
			this.payment_journal_editing=  false;
			this.loadDataNoLoader();
		});
	}

	getJournals() {
		const data = {};
		this.paymentsService.getAllJournals(data).subscribe((result: any) => {
			this.openjournals = result;
			if (result.length) {
				this.payment_journal_edit.setValue(result[0]);
			}
		});
	}

	openNewJournal(event: any) {
		event.stopPropagation();
		event.preventDefault();

		const data = {};
		this.journalaction = true;
		this.paymentsService.openJournal(data).subscribe((result: any) => {
			this.getJournals()
		});
	}


	toggleEditJournal(payment: any) {
		if(!payment.jounraledit) {
			payment.jounraledit = true;
			this.payment_journal_editing=  payment;
			this.modalService.open(this.journalDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {
				//this.payment_journal_edit.setValue(payment.banktranstype);

			}, (reason) => {
				this.void_trans = false;
				this.payment_journal_editing=  false;
				this.journalaction = true;
			});

			//this.payment_type_edit.setValue()
		} else {
			payment.jounraledit = false;
		}
	}

	toggleEdit(payment: any) {
		if(!payment.edit) {
			payment.edit = true;
			this.payment_type_edit.setValue(payment.banktranstype);
			//this.payment_type_edit.setValue()
		} else {
			payment.edit = false;
		}
	}

	loadDataNoLoader() {

		const data = { from: this.datefrom.value, to: this.dateto.value }
		this.paymentsService.getPaymentRange(data).subscribe( (results: any) => {
			const copyofresults = results;

			this.orignal_results.transactions = copyofresults.transactions;
			this.payments = results;
			this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.amount);
			}, 0);

			const parent = this;
			this.cctotal = results.summary.reduce(function(accumulator: number, items: any) {

				const ttype = parent.payment_methods.filter((t:any) => {
					return t.paymentname == items.type;
				})[0];
				let value = 0;

				if(ttype.cc_process === '1') {
					value = parseFloat(items.amount);
				}

				return accumulator + value;
			}, 0);

			this.getJournals();
		})
	}

	loadData() {
		this.running = true;
		const data = { from: this.datefrom.value, to: this.dateto.value, locations: this.defaultlocation.value }
		this.paymentsService.getPaymentRange(data).subscribe( (results: any) => {
			const copyofresults = results;
			this.orignal_results.transactions = copyofresults.transactions;
			this.running = false;
			this.payments = results;


			this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.amount);
			}, 0);

			const parent = this;
			this.cctotal = results.summary.reduce(function(accumulator: number, items: any) {

				const ttype = parent.payment_methods.filter((t:any) => {
					return t.paymentname == items.type;
				})[0];
				let value = 0;

				if(ttype && ttype.cc_process == '1') {
					value = parseFloat(items.amount);
				}

				return accumulator + value;
			}, 0);

			this.total_sub = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovamount);
			}, 0);

			this.total_discount = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.ovdiscount);
			}, 0);

			this.getJournals();
		})
	}

	processVoid() {
		const proceed = confirm('Void $'+this.financial(this.void_trans.amount)+' on XX'+this.void_trans.cc_data.card_last_four+'?');
		if(proceed) {
			const data = {
				transaction : this.void_trans,
				create_credit: this.create_credit.value,
				voiddate: this.voiddate.value
			}
			this.voiding = true;
			this.paymentsService.voidPayment(data).subscribe((result: any) => {
				this.voiding = false;
				if (result.success) {
					this.loadData();
					this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'left')
					this.modalService.dismissAll();
				} else {
					alert(result.message)
				}
			})
		}
	}

	voidPayment(pay: any) {

		this.void_trans = pay;
		this.modalService.open(this.voidDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {

		}, (reason) => {
			this.void_trans = false;
		});

	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}


	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename,
			landscape: true,
			title: 'Payment Report',
			subtitle: this.datefrom.value.toLocaleDateString()+' - '+this.dateto.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename,
			title: 'Payment Report',
			subtitle: 'From: '+this.datefrom.value.toLocaleDateString()+' - To:'+this.dateto.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	updateDayPayMents(event: any) {
		this.today = event;
		this.loadData();
	}

	back(): void {
		this.location.back()
	}
}
