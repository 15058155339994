<div class="container-fluid mt-4" *ngIf="statements">
	<div class="text-center mb-4">
		<a [href]="statementLink" target="_blank" class="btn btn-outline-primary btn-lg">
			<i class="fa fa-file-pdf-o mr-2"></i> View Current Statement (PDF)
		</a>
	</div>
	<ng-container *ngIf="!statements.success">
		<h4 class="text-center text-danger">No Statements Found</h4>
	</ng-container>
	<ng-container *ngIf="statements.success">
		<div class="d-flex justify-content-center mb-4">
			<div class="input-group w-50">
				<mat-form-field appearance="outline" class="flex-grow-1">
					<mat-label>Email Address</mat-label>
					<input tabindex="0" matInput [formControl]="email">
				</mat-form-field>
				<div class="input-group-append">
					<button mat-icon-button color="accent" (click)="emailStatement()" *ngIf="!sending" class="mt-3 ml-2">
						<mat-icon>send</mat-icon>
					</button>
				</div>
				<ckeditor [formControl]="emailMessage" [editor]="Editor" [config]="editorConfig"></ckeditor>

			</div>
		</div>
		<div class="bordered p-4 ml-auto mr-auto smallericonsplease" [innerHTML]="statements.content"></div>
	</ng-container>
</div>
