import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { OrdersService } from '../../../services/orders.service';
import { ReportsService } from '../../../services/reports.service';
import { merge, fromEvent } from 'rxjs';
import { interval, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { CardLists, Card } from '../../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

declare let $: any;


@Component({
	selector: 'app-cvsls-content',
	templateUrl: './cvsls-content.component.html',
	styleUrls: ['./cvsls-content.component.scss']
})
export class CvslsContentComponent implements OnInit {
	@Input() customer;
	@Input() vendor;
	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('print_tablexlsx') printtablexlsx: ElementRef;
	@ViewChild('print_tablepdf') printtablepdf: ElementRef;
	@ViewChild('brandFilter') brandsRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;


	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	plRef = new UntypedFormControl('1');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		todate: '',
		locs: [],
		conbr: '',
		plbreak: '',
		brands: [],
		brandname: '',
		supplierid: '',
	};

	@Input() name: string;

	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	brands = new UntypedFormControl('');
	sending: any = false;
	brandname: any = '';
	branches: any = [];
	salespeople: any = [];
	properties: any = [];

	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	plbreak: any = [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	dowloading = false;

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;


	ngOnInit(): void {
		this.setPagination([]);
	}

	constructor(private _changeDetectorRef: ChangeDetectorRef,private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.mesagesallowed.next(false);

		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		const allbrands = [];
		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});

		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);



	}

	subscription: any = false;
	public getCVSLS() {

		this.sending = true;
		this.properties.brands.forEach((brand) => {
			if (brand.manufacturer_id == this.brands.value[0]) {
				this.brandname = brand.name;
			}
		});

		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			locs: this.branchRef.value,
			conbr: this.conBrRef.value,
			plbreak: this.plRef.value,
			brands: this.brands.value,
			brandname: this.brandname,
			supplierid: (this.vendor) ? this.vendor.supplierid : ''
		};

		if(this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.reportsService.getCVSLS(this.reportCriteria).subscribe(r => {
			this.setPagination(r.lines);
			this.sending = false;
			this.orderResults = r;
		})
	}

	viewCustomer(debtorno: any, prodline: any, loccode: any) {
		const drilldown = {
			cust: debtorno,
			pl: prodline,
			loc: loccode,
			plbreak: this.reportCriteria.plbreak,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			brands: this.reportCriteria.brands,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr,
			supplierid: (this.vendor) ? this.vendor.supplierid : ''
		};

		this.reportsService.getCVSLSDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});

		});
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
		case 'Credit':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case 'Invoice':
			this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})

			break;
		}
	}

	exportPdf() {
		this.dowloading = true;
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateAndEncodeHTML());
		const data = {
			content: encoded,
			filename: 'Vendor Product Customer Sales ' + this.reportCriteria.fromdate + ' - ' + this.reportCriteria.todate,
			title: 'Vendor Product Customer Sales',
			subtitle: this.getTitle() + ' ' + this.fromdateCtrl.value.toLocaleDateString() + ' - ' + this.todateCtrl.value.toLocaleDateString() + ' created ' + today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.dowloading = false;
		});
	}

	generateAndEncodeHTML(): string {
	  let htmlString = '<table class="table table-sm table-hover"><thead><tr><th style="background-color: #183d78; color: white;">BR</th><th style="background-color: #183d78; color: white;">Customer</th><th style="background-color: #183d78; color: white;">Name</th><th style="background-color: #183d78; color: white;">Salesperson</th><th style="background-color: #183d78; color: white;">Zip Code</th><th style="background-color: #183d78; color: white;">Line</th><th style="background-color: #183d78; color: white;" class="text-center">Line Name</th><th style="background-color: #183d78; color: white;" class="text-center">Qty</th><th style="background-color: #183d78; color: white;" class="text-right">Sales</th></tr></thead><tbody>';

	  this.orderResults.lines.forEach((o: any) => {
			htmlString += `<tr><th colspan="9"> Product Line: ${o.name} </th></tr>`;
			o.details.forEach((line: any) => {
		  htmlString += `<tr><td><b>${line.BRANCH}</b></td><td><b>${line.CUSTOMER}</b></td><td>${line.NAME}</td><td><b>${line.SALESMAN}</b></td><td><b>${line.ZIP}</b></td><td>${line.LINEID}</td><td class="text-center">${line.PRDLINE}</td><td class="text-center">${line.QUANTITY}</td><td class="text-right">${this.financial(line.Sales)}</td></tr>`;
			});
			if (o.name !== 'ALL') {
		  htmlString += `<tr class="pl-rollup"><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th class="text-center">${o.name} Avg: ${o.avg}</th><th class="text-right">${o.name} Total: ${this.financial(o.total)}</th></tr>`;
			}
	  });

	  htmlString += `<tr><th></th><th></th><th></th><th></th><th></th><th></th><th class="text-center">Count : ${this.orderResults.totalrecords}</th><th class="text-center">Avg: ${this.financial(this.orderResults.avg)}</th><th class="text-right">Total: ${this.financial(this.orderResults.total)}</th></tr>`;
	  if(this.reportCriteria.brands.length > 0){
	  htmlString += `<tr><th colspan="9"> Selected Brands </th></tr>`;
	  this.reportCriteria.brands.forEach((b: any) => {
			htmlString += `<tr><td colspan="9">` + this.brandData(b,'name') + `</td></tr>`;
	  });
	  }
	  htmlString += `</tbody></table>`;

	  return htmlString;
	}

	exportXls() {
		this.dowloading = true;
		const today = new Date();
		const html = this.generateAndEncodeHTML();

		const encoded: string = this.globalSearchService.base64encode(html);
		const data = {
			content: encoded,
			filename: 'Vendor Product Customer Sales ' + this.reportCriteria.fromdate + ' - ' + this.reportCriteria.todate,
			title: 'Vendor Product Customer Sales',
			subtitle: this.getTitle() + ' ' + this.fromdateCtrl.value.toLocaleDateString() + ' - ' + this.todateCtrl.value.toLocaleDateString() + ' created ' + today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.dowloading = false;
		});
	}

	getTitle() {
		const consolidate_branch = (this.reportCriteria.conbr == 'C') ? 'Consolidated Report' : 'Branch Report';
		let locations = (this.reportCriteria.locs.length == this.branches.length) ? ' For All Locations' : ' ';
		const brands = (this.reportCriteria.brands.length == this.properties.brands.length) ? ' For All Brands' : ' ';

		if (this.reportCriteria.locs.length != this.branches.length) {
			locations = ' For Locations:';
			if (this.reportCriteria.locs.length) {
				this.reportCriteria.locs.forEach(l => {
					locations += ' ' + l + ' ';
				})
			} else {
				locations += ' All ';
			}
		}

		return consolidate_branch + locations;
	}

	back(): void {
		this.location.back()
	}

	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'</div>'
		});
	}

	brandData(id: any, type) {

		let out;
		let brand;
		brand = (this.properties.brands).filter((i) => {
			if (i.manufacturer_id == id) {
				return i;
			}
		})

		switch (type) {
		case 'code':
			out = brand[0].manufacturer_id
			break;
		case 'name':
			out = brand[0].name;
			break;
		}
		return out;
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch (filter_name) {
		case 'brand':
			filter = this.brandsRef;
			break;
		case 'branch':
			filter = this.branchesRef;
			break;
		default:
			return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}
}
