import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable, ElementRef, HostListener, Input, QueryList, ViewChildren } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DataService } from '../../../data.service';
import { ChatService } from '../../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { InventoryService } from '../../../services/inventory.service';
import { forkJoin } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-transfers-create',
	templateUrl: './transfers-create.component.html',
	styleUrls: ['./transfers-create.component.scss']
})
export class TransfersCreateComponent implements OnInit {
	@ViewChildren('inputElement') inputElements: QueryList < ElementRef > ;
	@Output() transferComplete = new EventEmitter < any > ();
	@Output() resetTransfer = new EventEmitter < any > ();
	@ViewChild('binSelect') binSelectRef: ElementRef;
	@Input() tabreset: any = false;
	@ViewChild('firstItem') firstItem: ElementRef;
	@ViewChild('itemrec') itemrec: ElementRef;
	@ViewChild('tableHeader') tableHeader: ElementRef;

	locations: any = [];
	tranlocations: any = [];
	items: any = [];
	needbin: any = [];
	fromaddress: any = '';
	toaddress: any = '';
	transferForm: UntypedFormGroup;
	transferData: any = {};
	searchVal: any = '';
	transfer_total = 0;
	transfer_qty = 0;
	tabIndex = 0;
	j = 0;
	searchsub: any = false;
	dataSource: MatTableDataSource < any > ;
	fillOpenOrdersOnly= new FormControl(false);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;

	private sort: MatSort;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	@ViewChild('print_items') print_itemsRef: ElementRef;

	items_per_page = [25, 50, 100];
	searching = false;
	default_location: any = [];

	constructor(private _changeDetectorRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private inventoryService: InventoryService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.setPagination([]);
		this.inventoryService.getTransferLocations().subscribe((locresults: any) => {
			if (locresults) {
				this.tranlocations = locresults;
			}

			this.globalsService.getUserLocations().subscribe((results: any) => {
				if (results) {
					this.default_location = results.filter(r => r.loccode === this.transferData.header.fromstkloc)[0] ?? results[0];
					this.locations = results;
					this.transferForm.get('fromstkloc').setValue(this.default_location.loccode);
					this.updateHeader();
				}
			});
		});

		this.setTransferForm();

	}

	updateHeader() {

		this.transferData.header.fromstkloc = this.transferForm.get('fromstkloc').value;
		this.transferData.header.tostkloc = this.transferForm.get('tostkloc').value;

		this.updateLocationAddress(this.transferData.header.fromstkloc, true);
		this.updateLocationAddress(this.transferData.header.tostkloc, false);

		if (this.transferForm.get('searchVal').value !== '') {
			this.itemSearch();
		}
	}

	updateLocationAddress(loccode: string, isFrom: boolean) {
		const req = { 'loccode': loccode };
		this.inventoryService.getLocationAddress(req).subscribe((results: any) => {
			if (results) {
				const address = `${results.locationname}\n${results.deladd1}\n${results.deladd3} ${results.deladd4}, ${results.deladd5}`;
				if (isFrom) {
					this.fromaddress = address;
				} else {
					this.toaddress = address;
				}
			}
		});
	}


	decrement(item: any) {
		const index = this.items.indexOf(item);
		if (this.items[index].quantity > 0) {
			this.items[index].quantity -= 1;
		}
		this.priceTransfer();
	}

	increment(item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity += 1;
		this.priceTransfer();
	}

	updateSearchQuantity(event: any, item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;

	}
	updateBinQuantity(event: any, part: any, bin: any) {
		let value = event.target.value;
		value = value ? Number(value) : 0;
		bin.quantity = value;
		part.quantity = part.bins.reduce((total, currentBin) => total + (currentBin.quantity || 0), 0);
	}

	addBinQtys(line) {
		if (line.bins) {
			const parent = this;
			line.bins.forEach((bin, index2) => {
				if (bin.quantity > 0) {
					parent.transferData.lines.push({
						stockid: line.stockid,
						description: line.description,
						bincode: bin.bin,
						transferqty: bin.quantity,
						releasedqty: 0,
						receivedqty: 0,
						shipdate: '0000-00-00',
						recdate: '0000-00-00',
						narrative: '',
						completed: 0,
						price: line.price
					});
					bin.quantity = '';
				}
			});
			parent.showSuccessNotification(line);
			parent.priceTransfer();
			parent.resetItemQuantities();
		}
	}

	addItems(event: any[]) {
		this.needbin = [];

		event.forEach((line, index) => {
			if (line.quantity > 0) {
				if (line.bins.length > 1) {
					line.bins.forEach((bin, index2) => {
						if (bin.maxqty > 0) {
							this.needbin.push({
								stockid: line.stockid,
								description: line.description,
								needquantity: line.quantity,
								quantity: 0,
								bin: bin.bin,
								binmax: bin.maxqty,
								price: line.price
							});
						}
					});
				} else {
					this.addTransferLine(line);
					this.showSuccessNotification(line);
				}
			}
		});

		if (this.needbin.length > 0) {
			this.openBinSelectModal();
		}

		this.priceTransfer();
		this.resetItemQuantities();
	}

	addTransferLine(line: any) {
		this.transferData.lines.push({
			stockid: line.stockid,
			description: line.description,
			bincode: line.bins[0].bin,
			transferqty: line.quantity,
			releasedqty: 0,
			receivedqty: 0,
			shipdate: '0000-00-00',
			recdate: '0000-00-00',
			narrative: '',
			completed: 0,
			price: line.price
		});
	}

	showSuccessNotification(line: any) {
		this.globalSearchService.showNotification(`Added ${line.quantity}x ${line.stockid}`, 'success', 'bottom', 'right');
	}

	openBinSelectModal() {
		this.modalService.open(this.binSelectRef, {
			ariaLabelledBy: 'modal-title',
			size: 'xl'
		}).result.then((result) => {
			// Handle modal close
		}, (reason) => {
			// Handle modal dismiss
		});
	}

	resetItemQuantities() {
		this.items.forEach((item) => {
			item.quantity = 0;
		});
	}

	addBins(event) {
		event.forEach((line: any, index) => {
			if (line.quantity > 0) {
				this.transferData.lines.push({
					'stockid': line.stockid,
					'description': line.description,
					'bincode': line.bin,
					'transferqty': line.quantity,
					'releasedqty': 0,
					'receivedqty': 0,
					'shipdate': '0000-00-00',
					'recdate': '0000-00-00',
					'narrative': '',
					'completed': 0,
					'price': line.price
				});
				this.globalSearchService.showNotification('Added ' + line.quantity + 'x ' + line.stockid, 'success', 'bottom', 'right');
			}
		});
		this.priceTransfer();
		this.needbin = [];
		this.modalService.dismissAll();

	}

	removeItem(item: any) {
		const index = this.transferData.lines.indexOf(item);
		this.transferData.lines.splice(index, 1)

		this.globalSearchService.showNotification('Removed ' + item.transferqty + 'x ' + item.stockid, 'warning', 'bottom', 'right');
		this.priceTransfer();

	}

	updateLineQty(event: any, item: any) {
		const index = this.transferData.lines.indexOf(item);
		item.transferqty = event.target.value;
		this.transferData.lines[index].transferqty = event.target.value;
		this.priceTransfer();
	}

	itemSearch() {


		const data = {
			keywords: this.transferForm.get('searchVal').value,
			fromstkloc: this.transferData.header.fromstkloc,
			tostkloc: this.transferData.header.tostkloc,
			fillonly: this.fillOpenOrdersOnly.value
		};

		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}
		this.searching = true;
		this.searchsub = this.inventoryService.getTransferItems(data).subscribe((results: any) => {
			if (results) {
				if (results.length) {
					this.items_per_page = [25, 50, 100, results.length];
					this.items = results;
					this.setPagination(results);
					this.focusFirstItem();
					this.tableHeader.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

				}
			}
			this.searching = false;
		});

	}

	private focusFirstItem() {
		setTimeout(() => {
			if (this.firstItem) {
				const firstInput = this.firstItem.nativeElement;
				firstInput.focus();
				this.selectText(firstInput);
			}
		});
	}

	focusSearch() {
		setTimeout(() => {
			if (this.itemrec) {
				const rec = this.itemrec.nativeElement;
				rec.focus();
			}
		});
	}


	displayBins(item) {
		if (!item.bins || item.bins.length === 0) {
			return 'No bins';
		}

		let binsHtml = '<div class="bins-container">';
		item.bins.forEach(bin => {
			if (bin.maxqty !== '0') {
				binsHtml += `<div class="bin-card-header">${bin.bin} (${bin.maxqty})</div>`;
			}
		});
		binsHtml += '</div>';
		return binsHtml;
	}

	selectText(event: FocusEvent) {
		const input = event.target as HTMLInputElement;
		if (input) {
			setTimeout(() => {
				input.select();
			}, 0);
		}
	}

	trackByStockId(index: number, item: any): string {
		return item.stockid;
	}

	submitTransfer() {
		if (this.transferData.header.fromstkloc == this.transferData.header.tostkloc) {
			this.globalSearchService.showNotification('Must Transfer to Different Location', 'warning', 'bottom', 'left');
		} else if (this.transferData.lines.length == 0) {
			this.globalSearchService.showNotification('Must Transfer at least One Item', 'warning', 'bottom', 'left');
		} else if (this.transferData.header.fromstkloc != undefined || this.transferData.tostkloc != undefined) {
			const data = { 'fromstkloc': this.transferData.header.fromstkloc, 'tostkloc': this.transferData.header.tostkloc, 'notes': this.transferForm.get('notes').value, 'ovasaletotal': this.transfer_total };
			this.inventoryService.createTransfer(data).subscribe((results: any) => {

				if (results.transno != '') {
					this.transferData.header.transno = results.transno;

					this.transferData.lines.forEach((line: any, index) => {
						line.transno = results.transno;
						line.transferlineno = index;
						this.inventoryService.addTransferLine(line).subscribe((results: any) => {});
					});
					this.transferForm = this.fb.group({
						'searchVal': '',
						'transno': '',
						'fromstkloc': '00',
						'tostkloc': '00',
						'inputdate': '2023-12-31',
						'notes': '',
						'status': 0,
						'lines': []
					});
					this.transferData = {
						'header': { 'transno': '', 'fromstkloc': '00', 'tostkloc': '00', 'inputdate': '2023-12-31', 'notes': '', 'status': 0 },
						'lines': []
					};
					this.items = [];
					this.transferComplete.emit(results.transno);

					this.setTransferForm()
					this.priceTransfer();
					this.tabreset = 0;
				}
			});
		}
	}
	submitAndMoveTransfer() {
	
	   let proceed = confirm('This will create a transfer and automatically release and receive all inventory. Continue?');
	   if(proceed){
		if (this.transferData.header.fromstkloc == this.transferData.header.tostkloc) {
			this.globalSearchService.showNotification('Must Transfer to Different Location', 'warning', 'bottom', 'left');
		} else if (this.transferData.lines.length == 0) {
			this.globalSearchService.showNotification('Must Transfer at least One Item', 'warning', 'bottom', 'left');
		} else if (this.transferData.header.fromstkloc != undefined || this.transferData.tostkloc != undefined) {
			const data = {'fromstkloc': this.transferData.header.fromstkloc, 'tostkloc': this.transferData.header.tostkloc, 'notes': this.transferForm.get('notes').value, 'ovasaletotal': this.transfer_total };
			this.inventoryService.createTransfer(data).subscribe((results: any) => {

				if (results.transno != '') {
					this.transferData.header.transno = results.transno;

					this.transferData.lines.forEach((line: any, index) => {
						line.transno = results.transno;
						line.transferlineno = index;
						this.inventoryService.addTransferLine(line).subscribe((results: any) => {});
					});
					this.transferForm = this.fb.group({
						'searchVal': '',
						'transno': '',
						'fromstkloc': '00',
						'tostkloc': '00',
						'inputdate': '2023-12-31',
						'notes': '',
						'status': 0,
						'lines': []
					});
					this.transferData = {
						'header': { 'transno': '', 'fromstkloc': '00', 'tostkloc': '00', 'inputdate': '2023-12-31', 'notes': '', 'status': 0 },
						'lines': []
					};
					this.items = [];
					this.transferComplete.emit(results.transno);

					this.setTransferForm()
					this.priceTransfer();
					this.tabreset = 0;
					let completeData = {transno: results.transno};
					this.inventoryService.autoMoveTransfer(completeData).subscribe((results: any) => {});
				}
			});
		}
		}
	}
	priceTransfer() {
		this.transfer_total = 0;
		this.transfer_qty = 0;
		this.transferData.lines.map((i) => {
			this.transfer_total += (i.price * i.transferqty);
			this.transfer_qty += Number(i.transferqty);
		})
	}

	setTransferForm() {
		this.transferForm = this.fb.group({
			'searchVal': '',
			'transno': '',
			'fromstkloc': '00',
			'tostkloc': '',
			'inputdate': '2023-12-31',
			'notes': '',
			'status': 0,
			'lines': []
		});
		this.transferData = {
			'header': { 'transno': '', 'fromstkloc': '00', 'tostkloc': '', 'inputdate': '2023-12-31', 'notes': '', 'status': 0 },
			'lines': []
		};
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}

	handleTabKey(event: KeyboardEvent, index: number) {
		const inputsArray = this.inputElements.toArray();
		if (event.key === 'Tab' && !event.shiftKey) {
			if (index === inputsArray.length - 1) {
				this.firstItem.nativeElement.focus();
				this.firstItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			} else {

				const nextElement = inputsArray[index + 1].nativeElement;
				nextElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}

	handleEnterKeyMulti(event, part, bin) {

		if (event.key === 'Enter') {
			event.preventDefault();
			this.addBinQtys(part)
		}
	}

	handleEnterKey(event: KeyboardEvent, items: any) {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.addItems(items)
		}
	}

	removeTransferLine(stockid: string, bincode: string) {
		this.transferData.lines = this.transferData.lines.filter(line => !(line.stockid === stockid && line.bincode === bincode));
		this.priceTransfer();
	}

	getTotalTransferQtyByPart(part) {
		const binQuantities = {};

		// Iterate over the transferData lines to match stockid and update bin quantities
		this.transferData.lines.forEach(r => {
			if (r.stockid === part.stockid) {
				if (!binQuantities[r.bincode]) {
					binQuantities[r.bincode] = 0;
				}
				binQuantities[r.bincode] += Number(r.transferqty || 0);
			}
		});

		const result = [];
		for (const bin in binQuantities) {
			if (binQuantities[bin] > 0) {
				result.push({ bin: bin, quantity: binQuantities[bin] });
			}
		}

		return result;
	}

	flip() {
		this.resetTransfer.emit();
		this.setTransferForm();
		this.items = [];
		this.priceTransfer();
	}
}
