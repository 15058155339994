import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { OrdersService } from 'app/services/orders.service';
import { PrintService } from 'app/services/print.service';
import { Location , DatePipe } from '@angular/common'
import { MatPaginator } from '@angular/material/paginator';
import { RFC_2822 } from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { timeout } from 'rxjs/operators';
import { setTimeout } from 'timers';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CustomerService } from 'app/services/customer.service';
import { SplitButtonModule } from 'primeng/splitbutton';



@Component({
	selector: 'app-productline-report',
	templateUrl: './productline-report.component.html',
	// changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./productline-report.component.scss']
})
export class ProductlineReportComponent implements OnInit, OnChanges, AfterViewInit {

	manufacturers: any;
	startdate: any = new Date();
	enddate: any = new Date();
	active_selections: any;
	reportdata: any = false;
	report_running: any = false;
	form_control_array: any = [];
	datasource_array: MatTableDataSource < any > [] = [];
	detailed_toggle = new UntypedFormControl(false);
	mfgControl = new UntypedFormControl('');
	mfgGroup: any = [];
	sending: boolean;

	@ViewChild('tableSales') tableSales: ElementRef;
	@ViewChild('tableNoSales') tableNoSales: ElementRef;
	@ViewChild('tableCombined') tableCombined: ElementRef;

	@ViewChild('detailsModal') detailsModalEle: ElementRef;
	@ViewChild('exportDialog') exportDialog: ElementRef;

	@ViewChild(MatSort) sort: MatSort;
	displayedColumns: string[] = ['id', 'name', 'prods', 'units', 'sales'];
	tree_view = true;
	config: any;
	locs: any = [];
	seasons: any[];
	season_selected: any = '';
	designation_selected: any = '';
	designations: any;
	show_zero_sales = true;

	//pagination
	totalRecords = 0;
	pageSize = 1;
	pageIndex = 0;
	filteredObj: any = {};
	viewing_mfgs: any[];
	mfgtotals: { units: number;sales: number; };
	mfginquiry: any;
	mfginquiryheader: any;
	mfginquiryready = false;
	exportType: any;
	exportRadioOptions: any[] = [{ 'view': 'Yes', 'value': 1 }, { 'view': 'No', 'value': 2 }]
	rollupSelection: any = this.exportRadioOptions[0].value
	breakoutSelection: any = this.exportRadioOptions[0].value
	mfgRollupSelection: any = this.exportRadioOptions[1].value
	cgroups: any;
	cgroup_selected: any = '';
	filterForm: UntypedFormGroup;
	plCounts: any = false;
	isMobile: any = window.innerWidth;
	button_options: { label: string;icon: string;command: () => void; } [];
	color: any = 'blue'
	constructor(private location: Location, private globalSearchService: GlobalSearchService, private ordersService: OrdersService, private printService: PrintService, private inventoryService: InventoryService, private cd: ChangeDetectorRef, private modalService: NgbModal, private customerService: CustomerService, private fb: FormBuilder) {

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.configsubscription.subscribe(conf => {
			this.config = conf;
		});

		this.button_options = [{
				label: 'PDF',
				icon: 'pi pi-refresh',
				command: () => {
					this.openExportDialog('pdf');
				}
			},
			{
				label: 'XLSX',
				icon: 'pi pi-refresh',
				command: () => {
					this.openExportDialog('xlsx');
				}
			}
		];


	}
	ngAfterViewInit(): void {

	}
	ngOnChanges(changes: SimpleChanges): void {}

	ngOnInit(): void {
		this.setFilterForm();
		this.getManufacturers();
		this.getLocs();
		this.getSeasons();
		this.getDesignations();
		this.getCustomerTypes();
		this.startdate.setDate(this.enddate.getDate() - 30);
		window.onresize = () => this.isMobile = window.innerWidth;


	}

	setFilterForm() {
		this.filterForm = this.fb.group({
			customerType: [''],
			seasonType: [''],
			desType: ['']
		});
	}

	back(): void {
		this.location.back()
	}

	getManufacturers() {
		this.mfgGroup = [];
		this.ordersService.productlinesByMfg().subscribe((results: any) => {
			this.manufacturers = results;
			for (const [k, v] of Object.entries(results)) {
				this.mfgGroup.push(v);
			}
		});
	}

	getSeasons() {
		this.inventoryService.getSeasons().subscribe((results: any) => {
			const sorted = results.sort((a, b) => a.viewValue > b.viewValue ? 1 : 0);
			this.seasons = sorted;
			this.filterForm.get('seasonType').setValue(results.map((i) => i.value));
		});
	}

	getDesignations() {
		this.inventoryService.getDesignations().subscribe((results: any) => {
			const sorted = results.sort((a, b) => a.viewValue > b.viewValue ? 1 : 0);
			this.designations = sorted;
			this.filterForm.get('desType').setValue(results.map((i) => i.value));
		});
	}

	getCustomerTypes() {
		this.globalSearchService.customertypes.subscribe(results => {
			if (results) {
				const sorted = results.sort((a, b) => a.typename > b.typename ? 1 : 0);
				this.cgroups = sorted;
				this.filterForm.get('customerType').setValue(results.map((i) => i.typeid));
			}
		});
	}

	getLocs() {
		this.ordersService.getLocs().subscribe((res) => {
			for (const [k, v] of Object.entries(res)) {
				this.locs.push(`Loc ${v['loccode']}`);
			}
		});
	}

	pageChangeEvent(event: PageEvent) {
		const currentPage = this.pageIndex;
		this.pageIndex = event.pageIndex;
		this.pageSize = event.pageSize;
		this.show_zero_sales = true;

		this.gen_form_control(this.reportdata, this.viewing_mfgs[this.pageIndex]);
		this.viewing_totals(this.viewing_mfgs[this.pageIndex]);
		this.getPagedData(this.pageIndex);

	}

	getPagedData(to) {
		this.filteredObj = {};
		if (to == 0) {
			this.filteredObj = Object.keys(this.reportdata).slice(0, 1).reduce((result, key) => {
				result[key] = this.reportdata[key];
				return result;
			}, {});
		}
		if (to == this.totalRecords) {
			this.filteredObj = Object.keys(this.reportdata).slice(to - 1, to).reduce((result, key) => {
				result[key] = this.reportdata[key];
				return result;
			}, {});
		} else {
			this.filteredObj = Object.keys(this.reportdata).slice(to, to + 1).reduce((result, key) => {
				result[key] = this.reportdata[key];
				return result;
			}, {});
		}
	}


	startRangeAltered(event: any) {
		if (typeof event.start.value !== 'undefined') {
			this.startdate = event.start.value;
		}
	}

	endRangeAltered(event: any) {
		if (typeof event.end.value !== 'undefined') {
			this.enddate = event.end.value;
		}
	}

	runReport() {
		this.pageIndex = 0;
		this.reportdata = false;
		this.sending = false;
		this.show_zero_sales = true;
		const data = {
			product_lines: this.active_selections['pls'],
			option: this.detailed_toggle.value,
			prod_options: {
				season: this.filterForm.get('seasonType').value,
				designation: this.filterForm.get('desType').value,
				custtype: this.filterForm.get('customerType').value
			},
			range: { start: this.startdate, end: this.enddate }
		}

		this.report_running = this.ordersService.productlineReport(data).subscribe((results: any) => {
			this.report_running = false;
			this.tree_view = false;
			this.gen_form_controls(results);
		});
	}

	toggle_tree(input: boolean) {
		input ? this.tree_view = true : this.tree_view = false;
	}

	updatePLSelection(data: any) {
		this.active_selections = data;
	}

	//used to load inital mfg product line tables
	gen_form_controls(input: any) {
		this.viewing_mfgs = [];
		let limit = 1;
		Object.entries(input).forEach(([key, value]) => { //main obj
			this.viewing_mfgs.push(key);

			if (limit > 0) {
				Object.entries(value).forEach(([k, mfg]) => { //for each mfg in obj
					Object.entries(mfg).forEach(([k2, pl]) => { //for each pl in pls
						if (typeof pl == 'object') { //pl object
							this.form_control_array[pl['id']] = new UntypedFormControl();
							this.datasource_array[pl['id']] = new MatTableDataSource(pl['prods']); //pl prods
						}
					});
				});
				limit--;
			}
		});

		this.totalRecords = this.viewing_mfgs.length;

		this.filteredObj = Object.keys(input).slice(0, 1).reduce((result, key) => {
			result[key] = input[key];
			return result;
		}, {});
		this.reportdata = input;
		this.getPlCounts(input);
		this.viewing_totals(this.viewing_mfgs[0]);
		// this.cd.detectChanges();
	}

	//used to load mfg product line tables on new selection
	gen_form_control(input: any, specifics) {
		Object.entries(input).forEach(([key, value]) => { //main obj
			if (key !== specifics) { return }
			Object.entries(value).forEach(([k, mfg]) => { //for each mfg in obj
				Object.entries(mfg).forEach(([k2, pl]) => { //for each pl in pls
					if (typeof pl == 'object' && this.form_control_array[pl['id']] == undefined) { //pl object
						this.form_control_array[pl['id']] = new UntypedFormControl();
						this.datasource_array[pl['id']] = new MatTableDataSource(pl['prods']); //pl prods
					}
				});
			});
		});
	}

	filter_pl = (input: string, mfg: any, pl: any) => {
		this.datasource_array[pl].filter = input.trim().toLocaleLowerCase();
	}


	xlsreport(selection: ElementRef, docname: string) {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(selection.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: docname,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);

		}, (err) => {
			alert(err);
			this.sending = false;
			// this.cd.markForCheck
		}, () => {
			this.sending = false;
			// this.cd.markForCheck();
		});
	}

	exportPdf(selection: ElementRef, docname: string) {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(selection.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: docname,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		}, (err) => {
			alert(err);
			this.sending = false;
			// this.cd.markForCheck();
		}, () => {
			this.sending = false;
			// this.cd.markForCheck();
		});
	}


	openExportDialog(type: any) {
		this.exportType = type;
		this.modalService.open(this.exportDialog, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => {});
	}

	toggle_sales() {
		Object.entries(this.filteredObj).forEach(([key, value]) => { //main obj
			Object.entries(value).forEach(([k, mfg]) => { //for each mfg in obj
				Object.entries(mfg).forEach(([k2, pl]) => { //for each pl in pls
					if (typeof pl == 'object') { //pl object
						if (this.show_zero_sales) {
							if (pl['sales'] <= 0) {
								document.getElementById(pl['id']).style.display = 'none';
								document.getElementById(pl['id'] + '-tbl').style.display = 'none';
								document.getElementById(pl['id'] + '-br').style.display = 'none';

							}
						} else {
							if (pl['sales'] <= 0) {
								document.getElementById(pl['id']).style.display = 'flex';
								document.getElementById(pl['id'] + '-tbl').style.display = 'inline';
								document.getElementById(pl['id'] + '-br').style.display = 'initial';
							}
						}
					}
				});
			});
		});

		this.show_zero_sales = this.show_zero_sales == true ? false : true;
	}



	jump(index: any) {
		if (index != this.pageIndex) {
			this.pageIndex = index;
			this.gen_form_control(this.reportdata, this.viewing_mfgs[index]);
			this.viewing_totals(this.viewing_mfgs[index]);
			this.show_zero_sales = true;
			this.getPagedData(index);
		} else {
			this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => {

			});
		}
	}

	viewing_totals(mfg) {
		this.mfginquiryready = false;

		this.mfgtotals = {
			units: 0,
			sales: 0
		}

		this.mfginquiry = [];
		this.mfginquiryheader = this.reportdata[mfg];

		Object.entries(this.reportdata[mfg]['pls']).forEach(([key, value]: any) => {
			const row = {};
			this.mfgtotals.sales += value.sales;
			this.mfgtotals.units += value.totalunits;
			row['name'] = value.name;
			row['sales'] = value.sales;
			row['units'] = value.totalunits;
			row['prods'] = value.prods.length;
			row['id'] = value.id;
			this.mfginquiry.push(row);
		});

		this.mfginquiry = this.mfginquiry.sort((a, b) => b.sales - a.sales);

		this.mfginquiry = new MatTableDataSource(this.mfginquiry);
		this.mfginquiryready = true;
	}

	report_totals(mfg: any, type: any) {
		let output = 0;
		Object.entries(this.reportdata[mfg]['pls']).forEach(([key, value]: any) => {
			type == 'units' ? output += value.totalunits : output += value.sales;
		});

		return output;

	}




	scrollToPl(id) {
		if (!this.show_zero_sales) {
			this.toggle_sales()
		}

		this.modalService.dismissAll();

		document.getElementById(id).scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest'
		});
	}

	getDateRange() {
		const start = new Date(this.startdate);
		const end = new Date(this.enddate);
		return (start.getMonth() + 1) + '/' + start.getDate() + '/' + start.getFullYear() + '    -    ' + (end.getMonth() + 1) + '/' + end.getDate() + '/' + end.getFullYear();
	}

	setExportOption(selection: any) {
		const date = new Date;
		const timestamp = date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
		let docname = 'PLR_';
		this.breakoutSelection == 1 ? docname += ('breakout_' + selection) : docname += selection;
		docname += ('_' + timestamp);

		switch (selection) {
			case 'sales':
				this.exportType == 'xlsx' ? this.xlsreport(this.tableSales, docname) : this.exportPdf(this.tableSales, docname);
				break;
			case 'nosales':
				this.exportType == 'xlsx' ? this.xlsreport(this.tableNoSales, docname) : this.exportPdf(this.tableNoSales, docname);
				break;
			case 'combined':
				this.exportType == 'xlsx' ? this.xlsreport(this.tableCombined, docname) : this.exportPdf(this.tableCombined, docname);
				break;
		}
		this.modalService.dismissAll();
	}


	limitRollup(input: any) {
		input == 1 ? '' : this.rollupSelection = 2;
	}


	selectAllToggle(ref: MatSelect) {
		const selected = ref.options.find(opt => opt.selected);
		if (selected) {
			ref.options.forEach((item: MatOption) => item.deselect());
		} else {
			ref.options.forEach((item: MatOption) => item.select());
		}
	}


	getPlCounts(input: any) {
		const temp = {};
		Object.entries(input).forEach(([key, value]): any => {
			let plcount = 0;
			let prodcount = 0;
			let custcount = 0;
			let lastOrderCount = 0;
			Object.entries(value['pls']).forEach((line): any => {
				plcount++;
				prodcount = line[1]['prods'].length - 1;
				Object.entries(line[1]['prods']).forEach((prod): any => {
					custcount = prod[1]['custs'].length - 1;
					Object.entries(prod[1]['custs']).forEach((cust): any => {
						lastOrderCount = Object.entries(cust[1]['orders']).length - 1;
					})
				})
			})
			temp[key] = {};
			temp[key]['pls'] = plcount - 1;
			temp[key]['prods'] = prodcount;
			temp[key]['custs'] = custcount;
			temp[key]['orders'] = lastOrderCount;
		})
		this.plCounts = temp;
	}







}