import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';
import { interval, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

declare let $: any;

@Component({
	selector: 'app-weekly-reorder',
	templateUrl: './weekly-reorder.component.html',
	styleUrls: ['./weekly-reorder.component.scss']
})
export class WeeklyReorderComponent implements OnInit {
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('partSelect') partSelectRef: ElementRef;
	@ViewChild('nonStock') nsRef: ElementRef;
	@ViewChild('chipList') chipListRef: ElementRef;
    private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}
	color: any = 'blue';
	locations: any = [];
	searchForm: UntypedFormGroup;
	nonStockForm: UntypedFormGroup;
	filename = 'breord';
	title = 'BREORD - Store Reordering';
	today = new Date();
	results: any = [];
	loading = false;
	plines: any = [];
	user: any = [];
	items: any = [];
	branches: any = [];
	branch: any = new UntypedFormControl('');
	dateCtrl: any = new UntypedFormControl('');
	reportType: any = new UntypedFormControl(0);
	showZeroes: any = new UntypedFormControl(1);
	showOverstock: any = new UntypedFormControl(1);
	planWeeks: any = new UntypedFormControl('8');
	appendResults: any = new UntypedFormControl(1);
	transferData: any = [];
	servicelocation: any = '';
	searchVal: any = '';
	//chips
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	dirty: any = false;
	canload: any = false;
	config: any = false;
	readonly separatorKeysCodes = [ENTER, COMMA] as
	const;
	prodCtrl = new UntypedFormControl('');
	filteredProds: any = [];
	prods: any = [];
	allProds: any = [];
	sortedReportResults: any = [];
	sortByColumn: string;
	sortDirection: string;
	@ViewChild('prodInput', { static: false }) prodInput: ElementRef < HTMLInputElement > ;
	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

	dowloading = false;

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;

	constructor(private _changeDetectorRef: ChangeDetectorRef, private reportsService: ReportsService, private inventoryService: InventoryService, private printService: PrintService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			this.branch.value = this.user.user.defaultlocation.loccode;

		});
		this.inventoryService.getItemProductlines().subscribe((results: any) => {
			this.plines = results;

		})

		this.searchForm = this.fb.group({
			searchVal: ['', Validators.required]
		});

		this.nonStockForm = this.fb.group({
			itemDesc: ['', Validators.required],
			itemQty: ['0', Validators.required],
			itemPrice: ['0', Validators.required]
		});

		this.inventoryService.getServiceLocation({ loccode: this.branch.value }).subscribe((results: any) => {
			this.servicelocation = results.loccode;
		});
		this.results = [];
		this.inventoryService.getItemProductlineDetails().subscribe(results => {
			this.allProds = results;
			this.filteredProds = results;
		});

		this.prodCtrl.valueChanges.subscribe(newValue => {
			this.filter();
		});
		const today = new Date();

		this.dateCtrl.setValue(today);

		this.setPagination([]);

		if (localStorage.getItem('weeklybreord')) {
			this.canload = true;
			this.load();
		}
	
		this.globalSearchService.configsubscription.subscribe(c => {
			this.config = c;
		});

	}

	decrementQty(item: any): void {
	  if (item.qty_purchase > 0) {
			item.qty_purchase--;
			this.updateBuyQuantity(null, item);
	  }
	}

	incrementQty(item: any): void {
	  item.qty_purchase++;
	  this.updateBuyQuantity(null, item);
	}

	getTotalQtyTimesPrice(): number {
	  return this.results.reduce((total, item) => total + (item.qty_purchase * item.price), 0);
	}

	getTotalQtyPurchase(): number {
	  return this.results.reduce((total, item) => total + (parseFloat(item.qty_purchase) || 0), 0);
	}

	highlightRow(item: any): void {
		item.highlighted = true;
	}
	resetRowHighlight(item: any): void {
		item.highlighted = false;
	}

	save() {
		localStorage.setItem('weeklybreord', JSON.stringify(this.results));
		this.globalSearchService.showNotification('Saved', 'success', 'bottom', 'right')
		//this.results = [];
		this.canload = true;
	}

	load() {
		this.results = JSON.parse(localStorage.getItem('weeklybreord'));
		this.setPagination(this.results);
		//localStorage.removeItem('weeklybreord');
		this.canload = false;
	}


	getRecomendations() {

		this.loading = true;
		if(this.appendResults == 0){
		this.results = [];
		this.setPagination([]);
		}
		const fromdate = this.dateCtrl.value.toISOString().split('T')[0];
		const data = { plines: this.prods, loccode: this.branch.value, serviceloc: this.servicelocation, datefrom: fromdate, showoverstock: this.showOverstock.value, planweeks: this.planWeeks.value, showzero: this.showZeroes.value, liveinventory: this.config.liveinventory };
		if(this.reportType.value == 0){
			this.inventoryService.getReStockWeekly(data).subscribe((results: any) => {
				if(this.appendResults.value == 0){
				this.results = results;
				} else {
				results.forEach((r) => {
				const obj = this.results.find(x => x.stockid === r.stockid);
				let index = this.results.indexOf(obj);
				if(index >= 0){				
				} else {
						this.results.push(r);					
				}
				});
				}
				this.loading = false;
				this.setPagination(this.results);
			});
		} else {
			this.inventoryService.getReStock(data).subscribe((results: any) => {
				if(this.appendResults.value == 0){
				this.results = results;
				} else {
				results.forEach((r) => {
				const obj = this.results.find(x => x.stockid === r.stockid);
				let index = this.results.indexOf(obj);
				if(index >= 0){				
				} else {
						this.results.push(r);					
				}
				});
				}
				this.loading = false;
				this.setPagination(this.results);
			});
		}



	}
	resetReorder() {
		this.inventoryService.getServiceLocation({ loccode: this.branch.value }).subscribe((results: any) => {
			this.servicelocation = results.loccode;
		});

		if (this.results.length != 0) {
			this.results = [];
			localStorage.removeItem('weeklybreord');
			this.globalSearchService.showNotification('Parameters changed, report reset', 'warning', 'bottom', 'left');
		}
	}
	updateBuyQuantity(event: any, item: any) {

		const index = this.results.indexOf(item);
		if(event) {
			this.results[index].qty_purchase = event.target.value;
			this.setPagination(this.results);
		}
	}

	addMore() {
		this.modalService.open(this.partSelectRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	confirmRemoveItem(item: any): void {

		this.removeItem(item);
	// 	  const confirmation = confirm('Are you sure you want to remove this item?');
	//
	// 	  if (confirmation) {
	//
	// 	  } else {
	// 	  }
	}

	addNonStock() {
		this.modalService.open(this.nsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	searching: any = false;
	issearching : boolean = false;
	itemSearch() {

		const data = { 'keywords': this.searchForm.get('searchVal').value, 'fromstkloc': this.servicelocation, 'tostkloc': this.branch.value };
		
		if(this.searching) {
			this.searching.unsubscribe();
		}
		
		this.issearching = true;
		this.searching = this.inventoryService.getTransferItems(data).subscribe((results: any) => {
			if (results) {

				this.items = results;
			}
			this.issearching = false;
		});

	}
	removeItem(item: any): void {
		const index = this.results.indexOf(item);
		if (index >= 0) {
			this.results.splice(index, 1);
			//this.results.sort((a, b) => a.stockid.localeCompare(b.stockid));
			this.setPagination(this.results);
		}
	}
	decrement(item: any) {
		const index = this.items.indexOf(item);
		if (this.items[index].quantity > 0) {
			this.items[index].quantity -= 1;
		}
	}

	increment(item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity += 1;
	}
	updateSearchQuantity(event: any, item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}
	addItems(event) {
		const fromdate = this.dateCtrl.value.toISOString().split('T')[0];
		event.forEach((line: any, index) => {

			if (line.quantity > 0) {
				//let data = { stockid: line.stockid, loccode: this.branch.value, datefrom: fromdate, quantity: line.quantity };
				//this.inventoryService.getSingleRestock(data).subscribe((result: any) => {
				//	this.results.push(result);
				//	this.results.sort((a, b) => a.stockid.localeCompare(b.stockid));
				//});
				const data = { stockid: line.stockid, loccode: this.branch.value, datefrom: fromdate, quantity: line.quantity };

				const obj = this.results.find(x => x.stockid === line.stockid);
				let index = this.results.indexOf(obj);
				if(index >= 0){

					this.results.fill( obj.qty_purchase = (parseInt(obj.qty_purchase) + parseInt(line.quantity)), index, index++);
				} else {
					this.inventoryService.getSingleRestock(data).subscribe((result: any) => {
						this.results.push(result);
						//this.results.sort((a, b) => a.stockid.localeCompare(b.stockid));

					});
				}
			}

		});

		this.setPagination(this.results);

		this.items = [];
	}
	nsAdd() {
		const data = { 'description': '' };
		if(this.reportType.value == 0){
			const data = { 'stockid': 'MISC', 'description': this.nonStockForm.get('itemDesc').value, 'qoh': 0, 'thismo': 0, 'avail': 0, 'qos': 0, 'qty_purchase': this.nonStockForm.get('itemQty').value, 'price':this.nonStockForm.get('itemPrice').value };
			this.results.push(data);
		} else {
			const data = { 'stockid': 'MISC', 'description': this.nonStockForm.get('itemDesc').value, 'qoh': 0, 'threemo': 0, 'twomo': 0, 'onemo': 0, 'thismo': 0, 'avail': 0, 'qos': 0, 'qty_purchase': this.nonStockForm.get('itemQty').value, 'price': this.nonStockForm.get('itemPrice').value };
			this.results.push(data);
		}
		//this.results.sort((a, b) => a.stockid.localeCompare(b.stockid));
		this.setPagination(this.results);
		this.nonStockForm.reset();
		this.globalSearchService.showNotification(data.description + ' Added', 'success', 'bottom', 'left');
	}
	createTransfer() {
		const ovasaletotal = this.priceTransfer();
		if(this.config.transfersort == 'bin'){
		this.results.sort((a, b) => a.bin.localeCompare(b.bin));
		} else {
		this.results.sort((a, b) => a.stockid.localeCompare(b.stockid));
		}
		const data = { 'fromstkloc': this.servicelocation, 'tostkloc': this.branch.value, 'notes': 'Created by Store Reorder', 'ovasaletotal': ovasaletotal };

		this.inventoryService.createTransfer(data).subscribe((results: any) => {

			if (results.transno != '') {

				this.results.forEach((line: any, index) => {
					line.transno = results.transno;
					line.transferlineno = index;
					const data = { transno: results.transno, transferlineno: index, stockid: line.stockid, transferqty: line.qty_purchase, description: line.description }
					this.inventoryService.addTransferLineRestock(data).subscribe((results: any) => {});
				});

				this.results = [];
				this.globalSearchService.showNotification('Reorder Submitted: Transfer #' + results.transno + ' Created', 'success', 'bottom', 'left');

				this.clearAll();
				localStorage.removeItem('weeklybreord');

			} else {
				this.globalSearchService.showNotification('Error in creation process', 'danger', 'bottom', 'left');
			}

		});
	}

	priceTransfer() {
		const init = 0;
		return this.results.reduce((accmulator, item) => { return accmulator + item.price }, init);
	}
	sortBy(property: string): void {
		if (this.sortByColumn === property) {
			// Toggle sort direction if the same column is clicked
			this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
		} else {
			// Default to ascending order when a new column is clicked
			this.sortDirection = 'asc';
			this.sortByColumn = property;
		}

		this.results.sort((a, b) => {
			// Check if the property values are numeric
			const isNumeric = !isNaN(parseFloat(a[property])) && isFinite(a[property]) && !isNaN(parseFloat(b[property])) && isFinite(b[property]);

			if (isNumeric) {
				// Sort as numbers if the property values are numeric
				const numA = parseFloat(a[property]);
				const numB = parseFloat(b[property]);
				return this.sortDirection === 'asc' ? numA - numB : numB - numA;
			} else {
				// Sort as strings if the property values are not numeric
				const comparison = a[property].localeCompare(b[property]);
				return this.sortDirection === 'asc' ? comparison : -comparison;
			}
		});
		this.setPagination(this.results);
	}
	exportPdf() {
		
		this.dowloading = true;
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateAndEncodeHTML());
		const data = {
			content: encoded,
			filename: this.filename,
			title: this.getReportTitle(),
			subtitle: 'For Branch ' + this.branch.value,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.dowloading = false;
		});
	}
	exportXls() {
	
	this.results.sort((a, b) => a.prodline.localeCompare(b.prodline));
		const encoded: string = this.globalSearchService.base64encode(this.generateAndEncodeHTML());
		
		const data = {
			content: encoded,
			filename: this.filename,
			title: this.getReportTitle(),
			subtitle: 'For Branch ' + this.branch.value,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	getReportTitle(){
	
		let title= '';
		if(this.reportType.value == 1){
		title = 'Reordering Report - 3 Month Average';
		} else {
		title = 'Reordering Report - Weekly Sales Data';
		}
		return title;
	}
	//chips
	filter() {
		const prodsLineFields = new Set(this.prods.map(prod => prod.line_field));
		const tempProds = this.allProds.filter(prod => !prodsLineFields.has(prod.line_field));

		if (this.prodCtrl.value == '' || !this.prodCtrl.value) {
			this.filteredProds = tempProds;
		} else {
			const filterval = this.prodCtrl.value.replace('*', '');
			this.filteredProds = this.globalSearchService.filterItem(tempProds, filterval, 'line_field,line_description')
		}
	}

	add(event: MatChipInputEvent): void {

		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
			if (value.includes('*')) {
				this.prods.push(value.trim());
			}
			if (input) {
				input.value = '';
			}
			this.prodCtrl.setValue(null);
		} else {

			if (event.value.includes('*')) {
				this.filteredProds.forEach((prod) => {
					this.prods.push({ line_field: prod.line_field, line_description: prod.line_description });
				});
				event.input.value = '';
				this.prodCtrl.setValue(null);
			}
		}

		this.prodInput.nativeElement.blur();

	}

	remove(prod: string): void {
	  const index = this.prods.indexOf(prod);
	  if (index >= 0) {
		// Add the removed item back to filteredProds
			const removedItem = this.prods.splice(index, 1)[0]; // Remove the item from this.prods and get the removed item
			this.filteredProds.push({ line_field: removedItem.line_field, line_description: removedItem.line_description });
	  }
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const selectedLineField = event.option.value;
		const selectedLineDescription = event.option.viewValue;
		// Add the selected item to this.prods
		if (!this.prods.some(prod => prod.line_field === selectedLineField)) {
			this.prods.push({ line_field: selectedLineField, line_description: selectedLineDescription });

			const index = this.filteredProds.findIndex(prod => prod.line_field === selectedLineField);
			if (index !== -1) {
				this.filteredProds.splice(index, 1);
			}
		}
		// Reset the input value
		this.prodInput.nativeElement.value = '';
		this.prodInput.nativeElement.blur();
		this.prodCtrl.setValue(null);
	}

	back() {

	}

	clearAll() {
		this.prods = [];
		this.filteredProds = this.allProds;
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}

	generateAndEncodeHTML(): string {
		let htmlString = '<table class="table table-sm table-hover"><thead><tr><th style="background-color: #183d78; color: white;"></th><th style="background-color: #183d78; color: white;">Item #</th><th style="background-color: #183d78; color: white;">Description</th><th style="background-color: #183d78; color: white;">QOH</th><th style="background-color: #183d78; color: white;">Price</th>';
		if(this.reportType.value == 1){
	  htmlString += '<th style="background-color: #183d78; color: white;">3Mos</th><th style="background-color: #183d78; color: white;">2Mos</th><th style="background-color: #183d78; color: white;">1Mos</th><th style="background-color: #183d78; color: white;">MTD</th>';
		} else {
		htmlString += '<th style="background-color: #183d78; color: white;">Past 7 Days</th>';
		}
		htmlString += '<th style="background-color: #183d78; color: white;">Available</th><th style="background-color: #183d78; color: white;">Back/Ord</th>';
		
		if(this.config.liveinventory){
		htmlString += '<th style="background-color: #183d78; color: white;">On Order</th>';
		}
		htmlString+='<th style="background-color: #183d78; color: white;">Recommended Request</th><th></th></tr></thead><tbody>';
	  this.results.forEach((o: any, index) => {
	      htmlString += `<tr><td><b>${index + 1}</b></td><td><b>${o.stockid}</b></td><td>${o.description}</td><td><b>${o.qoh}</b></td><td>${this.financial(o.price)}</td>`;
	  if(this.reportType.value == 1){
		  htmlString += `<td>${o.threemo}</td><td class="text-center">${o.twomo}</td><td class="text-center">${o.onemo}</td>`;
	  }
	   htmlString += `<td>${o.thismo}</td><td>${o.avail}</td><td>${o.qos}</td>`;
	   if(this.config.liveinventory){
		htmlString += `<td>${o.qop}</td>`;
		}
	   htmlString += `<td>${o.qty_purchase}</td><td>________</td></tr>`;
    	});

	  htmlString += `<tr></tbody></table>`;

	  return htmlString;
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
}
