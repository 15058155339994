import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
	@ViewChild('userdetails') userDetails: ElementRef;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	title = 'users';
	users: any = [];
	allUsers: any = [];
	userSearch = new FormControl('')
	color = '';
	selecteduser: any = [];
	isSearching = false;
	filterdisabled = new FormControl(false);

	headercolumns: string[] = [
		'actions',
		'userid',
		'realname',
		'groupname',
		'blocked',
		'viewcost'
	]

	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	constructor(private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, private userService: UsersService, private location: Location, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	openUser(user) {
		this.selecteduser = user;
		this.modalService.open( this.userDetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
		}, (reason) => {
			this.selecteduser = false;
		});
	}

	updateUser(event: any) {
		this.modalService.dismissAll();
		this.userService.getUsers().subscribe((users: any) => {
			this.allUsers = users
			this.setTableData(users);
			this.searchUsers();
		})
	}

	ngOnInit(): void {
		this.userService.getUsers().subscribe((users: any) => {
			this.allUsers = users
			this.setTableData(users)
		})
	}

	ngOnDestroy(): void {

	}

	back(): void {
		this.location.back()
	}

	setTableData(users: any[]) {

		//filterdisabled

		if(!this.filterdisabled.value) {
			users = users.filter((user: any) => user.blocked !== '1');
		}

		this.users = new MatTableDataSource(users)
		this.users.sort = this.sort
		this.users.paginator = this.paginator

		//Set sorting to work with lower or upper case
		this.users.sortingDataAccessor = this.sortingDataAccessor
	}

	sortingDataAccessor(data: any, sortHeaderID: string) : string {
		return typeof data[sortHeaderID] === 'string'
			? data[sortHeaderID].toLowerCase()
			: data[sortHeaderID]
	}

	onInput() {
		const search = this.userSearch.value
		this.isSearching = true
		this.setTableData(this.globalSearchService.filterItem(this.allUsers, search, 'userid,realname,groupname'))
		this.isSearching = false
	}

	searchUsers() {
		this.onInput()
	}

	announceSortChange(event: any) {

	}

}
