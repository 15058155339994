import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl, UntypedFormControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { TaxesService } from '../../services/taxes.service'
import { GeneralLedgerService } from '../../services/general-ledger.service'
import { InventoryService } from '../../services/inventory.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { Console } from 'console';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-inventory-settings',
	templateUrl: './inventory-settings.component.html',
	styleUrls: ['./inventory-settings.component.scss'],
	animations: [
		trigger('slide', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: 0, width: 0, overflow: 'hidden' }),
				animate(100, style({ height: '*', width: '*', transform: 'translateX(2000px)', overflow: 'hidden' })),
				animate('100ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(150, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('150ms', style({ height: '0', width: '0', opacity: 0, transform: 'translateX(2000px)' }))
			])
		]),
	]
})
export class InventorySettingsComponent implements OnInit {
	@ViewChild('lineEdit') lineEditRef: ElementRef;
	@ViewChild('categoryEdit') categoryEditRef: ElementRef;
	@ViewChild('manuEdit') manuEditRef: ElementRef;
	@ViewChild('plmEdit') plmEditRef: ElementRef;
	@ViewChild('plineMerge') plineMergeRef: ElementRef;
	@ViewChild('plinebatchdis') plinebatchdisRef: ElementRef;
	@ViewChild('plist') plistRef: ElementRef;

	private sort: MatSort;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	color = 'blue';
	categories: any = [];
	productlines: any = [];
	filteredproductlines: any = [];
	rebates: any = [];
	hazards: any = [];
	glaccounts: any = [];
	manufacturers: any = [];
	taxcats: any = [];
	cattypes: any = [];
	selected_linefield: any = '';
	productLineForm: UntypedFormGroup;
	plinesearch = new FormControl('');
	categoryForm: UntypedFormGroup;
	manufacturerForm: UntypedFormGroup;
	productLineMergeForm: UntypedFormGroup;
	vieingpline: any = false;
	salestypes: any = false;
	merging: any = false;
	searchingproductlines: any = false;
	selectedto: any = '';
	searchsub: any = false;
	searchResults: any;
	to = new FormControl('', Validators.required);
	from = new FormControl('', Validators.required);
	partialmerge = new FormControl(false);
	partialMergeItemsControls: FormControl < any > [] = [];
	// partialMergeItemsControls: UntypedFormGroup;
	searchbar = new FormControl('');
	viewSearch = false;
	validateSub: any;
	stockidChanged: any = { status: false, data: {} };
	toproductline: any = false;
	allowmmerge: any = [];
	controlsReady = false;
	sanitized_ID: any = false;
	batchType: string;
	batchComplete: boolean;
	config: any;

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 500];
	pagesizedefault = 25;

	constructor(private printService: PrintService, private _changeDetectorRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private taxesService: TaxesService, private inventoryService: InventoryService, private generalLedgerService: GeneralLedgerService, private globalSearchService: GlobalSearchService, private location: Location, public cdr: ChangeDetectorRef, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.salestypes.subscribe(v => { this.salestypes = v; });
		this.globalSearchService.configsubscription.subscribe((r) => this.config = r);

		this.inventoryService.getItemCategories().subscribe(async results => {
			this.categories = results;
		});

		this.inventoryService.getItemRebates().subscribe(async results => {
			this.rebates = results;
		});
		this.inventoryService.getItemHazards().subscribe(async results => {
			this.hazards = results;
		});
		this.inventoryService.getItemManufacturer().subscribe(async results => {
			this.manufacturers = results;
		});
		this.inventoryService.getItemProductlineDetails().subscribe(async results => {
			this.productlines = results;
			this.filteredproductlines = results;
			this.searchingproductlines = results;
			this.setPagination(results);
		});
		this.taxesService.getTaxCategories().subscribe(async results => {
			this.taxcats = results;
		});
		this.inventoryService.getStockCatTypes().subscribe(async (results: any) => {
			this.cattypes = results;
			this.cattypes.map(e => {
				e.catLabel = `${e.label} - ${e.description}`;
			}); //Add map in here.
		});
		this.generalLedgerService.getAcctListing().subscribe(async (results: any) => {
			this.glaccounts = results;
			this.glaccounts.map(e => {
				e.acctLabel = `${e.accountcode} - ${e.accountname}`;
			});
		});


	}

	ngOnInit(): void {

		this.setPagination([]);
		this.toproductline = false;
		this.productLineMergeForm = this.fb.group({
			fromlineid: ['', Validators.required],
			tolineid: ['', Validators.required],
			renameitems: [true, Validators.required],
			removeold: [true, Validators.required],
			partialmerge: [false]
		});

		this.productLineMergeForm.get('fromlineid').valueChanges.subscribe(newValue => {
			this.search(newValue);
		})

		this.productLineMergeForm.get('tolineid').valueChanges.subscribe(newValue => {
			this.selectedto = newValue.line_field;
			this.search(newValue);
		});

		this.productLineMergeForm.get('partialmerge').valueChanges.subscribe(newValue => {
			this.partialmerge.setValue(newValue);
			if (newValue == true) {
				this.productLineMergeForm.get('removeold').setValue(false);
			}
		});

		this.plinesearch.valueChanges.subscribe(newValue => {
			this.filterProdLines();
		});


	}

	v8search(event: any, validate: boolean) {
		if (this.searchsub) { this.searchsub.unsubscribe(); }
		const data = { stockid: event.value }
		if (!validate) {
			if (data.stockid == '') { this.searchsub = false; return; }
			this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.searchResults = results;
				this.viewSearch = true;
				this.searchsub = false;
			});
		} else {
			if (data.stockid == '') {
				this.validateSub = false;
				this.to.addValidators(Validators.required);
				this.to.updateValueAndValidity();
				return;
			}
			this.validateSub = this.inventoryService.absoluteItemSearch(data).subscribe((results: any) => {
				if (results.length == 0) {
					this.to.clearValidators();
				} else {
					this.to.addValidators([Validators.required, this.validateStockid()]);
				}
				this.to.updateValueAndValidity();
			});
		}
	}

	reset() {
		this.stockidChanged = { status: false, data: {} };
	}

	private validateStockid(): ValidatorFn {
		return (control: AbstractControl): {
			[key: string]: any } => {
			const input = { stockid: control.value };
			return this.inventoryService.absoluteItemSearch(input).subscribe(({ data }) => {
					return data;
				},
				(error) => {
					return error;
				}
			);
		}
	}


	showSearchResults() {
		this.viewSearch = true;
	}

	closeSearch() {
		this.viewSearch = false;
	}

	loadFrom(event: any) {
		this.from.setValue(event.stockid);
		this.viewSearch = false;
	}


	@ViewChild('productLines') productLinesRef: ElementRef;

	pdfPline() {
		const encoded: string = this.globalSearchService.base64encode(this.generateHtml());
		const today = new Date();
		const data = {
			content: encoded,
			filename: 'PLM.pdf',
			title: 'Product Line Markups',
			subtitle: ' created ' + today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	excelPline() {
		const encoded: string = this.globalSearchService.base64encode(this.generateHtml());
		const today = new Date();
		const data = {
			content: encoded,
			filename: 'PLM.xlsx',
			title: 'Product Line Markups',
			subtitle: ' created ' + today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	generateHtml(): string {
		let html = '<table class="table table-sm table-striped table-hover table-bordered table-fixed ov">';
		html += '<thead class="header"><tr>';
		html += '<th class="header" scope="col">Code#</th>';
		html += '<th class="header" scope="col">Description</th>';
		html += '<th class="header text-center" scope="col">#Items</th>';
		this.salestypes.forEach(t => {
			html += `<th class="header text-center w-10" scope="col">${t.typeabbrev || ''} - ${t.sales_type || ''}</th>`;
		});
		html += '</tr></thead><tbody>';

		this.productlines.forEach(p => {
			html += '<tr>';
			html += `<td>${p.line_field || ''}</td>`;
			html += `<td>${p.line_field || ''} - ${p.line_description || ''}</td>`;
			html += `<td class="text-center">${p.count || ''}</td>`;
			p.plm.forEach(pa => {
				html += '<td class="text-center">';
				if (pa.ispricebook && this.getFallBack(p.line_field, p.typeabbrev)) {
					const fallback = this.getFallBack(p.line_field, p.typeabbrev);
					html += `${fallback.pricebasis || ''}<br>${fallback.operator_label || ''} ${fallback.amount || ''}`;
				} else {
					html += `${pa.pricebasis || ''}<br>${pa.operator_label || ''} ${pa.amount || ''}`;
				}
				html += '</td>';
			});
			html += '</tr>';
		});

		html += '</tbody></table>';
		return html;
	}


	changeStockID() {
		if (this.to.valid && this.from.value !== '') {
			const data = { from: this.from.value, to: this.to.value };

			const opt = confirm(`Changing stockid: '${data.from}'  to  '${data.to}'`);
			if (opt) {
				this.inventoryService.changeStockId(data).subscribe((results: any) => {

					this.stockidChanged = { status: true, data: { from: this.from.value, to: this.to.value } };
					this.to.setValue('');
					this.from.setValue('');
					this.searchbar.setValue('');
					this.viewSearch = false;
					this.searchResults = '';
				});
			}
		} else {
			alert(`The ID '${this.to.value}' already exists.`)
		}

	}

	previewNewStockid(oldid: any, newid: any) {
		if (newid !== undefined) {
			if (this.config.env.package == 'tires') {
				const pre = oldid.split('-');
				pre[0] = newid;
				const implode = pre.join('-');
				return implode;
			} else {
				const pre = oldid.slice(3, oldid.length);
				newid = newid + pre;
				return newid;
			}
		}
	}


	filterProdLines() {

		if (this.plinesearch.value == '') {
			this.searchingproductlines = this.productlines;
			this.setPagination(this.searchingproductlines);
		} else {
			this.searchingproductlines = this.globalSearchService.filterItem(this.productlines, this.plinesearch.value, 'line_field,line_description')
			this.setPagination(this.searchingproductlines);
		}

	}

	search(searchValue: any) {

		if (searchValue == '') {
			this.filteredproductlines = this.productlines;

		} else {
			this.filteredproductlines = this.globalSearchService.filterItem(this.productlines, searchValue, 'line_field,line_description')
		}
	}

	viewProducts(pline: any) {
		this.vieingpline = pline;
		this.toproductline = false;

		this.modalService.open(this.plistRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}


	editCategory(cat: any) {
		this.categoryForm = this.fb.group({
			categoryid: [cat.categoryid],
			categorydescription: [cat.categorydescription, Validators.required],
			stocktype: [cat.stocktype, Validators.required],
			stockact: [cat.stockact, Validators.required],
			adjglact: [cat.adjglact, Validators.required],
			issueglact: [cat.issueglact, Validators.required],
			purchpricevaract: [cat.purchpricevaract, Validators.required],
			materialuseagevarac: [cat.materialuseagevarac, Validators.required],
			wipact: [cat.wipact, Validators.required],
			defaulttaxcatid: [cat.defaulttaxcatid, Validators.required],
			additional: [cat.additional, Validators.required],
			supercategoryid: [cat.supercategoryid, Validators.required],
		});

		this.categoryForm.get('categoryid').disable();
		this.modalService.open(this.categoryEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	lineMergeForm(pl: any) {
		this.vieingpline = pl;
		(this.vieingpline['details']).forEach(item => {
			this.partialMergeItemsControls[item.stockid] = new UntypedFormControl(false);
		});

		this.productLineMergeForm.get('fromlineid').setValue(pl);
		this.productLineMergeForm.get('tolineid').setValue('');

		this.controlsReady = true;
		this.modalService.open(this.plineMergeRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.controlsReady = false;
			this.partialMergeItemsControls = [];
		});
	}


	removeCategory(line: any) {
		this.inventoryService.removeCategory(line).subscribe(result => {
			this.categories = result
			this.modalService.dismissAll();
		})
	}

	addCategory() {
		this.categoryForm = this.fb.group({
			categoryid: ['', Validators.required],
			categorydescription: ['', Validators.required],
			stocktype: ['', Validators.required],
			stockact: ['', Validators.required],
			adjglact: ['', Validators.required],
			issueglact: ['', Validators.required],
			purchpricevaract: ['', Validators.required],
			materialuseagevarac: ['', Validators.required],
			wipact: [''],
			defaulttaxcatid: [],
			additional: [],
		});

		this.modalService.open(this.categoryEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	saveCategory() {
		if (this.categoryForm.valid) {
			this.categoryForm.get('categoryid').enable();
			this.inventoryService.saveCategory(this.categoryForm.value).subscribe(result => {
				this.categories = result
				this.modalService.dismissAll();
			})
		}
	}

	editProductLine(line: any) {
		const requirescontract = (line.requirescontract == '0') ? false : true;
		this.productLineForm = this.fb.group({
			line_field: [line.line_field],
			line_description: [line.line_description, Validators.required],
			requirescontract: [requirescontract]
		});

		this.productLineForm.get('line_field').disable();
		this.modalService.open(this.lineEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}
	removeProductLine(line: any) {
		this.inventoryService.removeProductLine(line).subscribe((result: any) => {
			if (result.success) {
				this.loadProductLines();
				this.modalService.dismissAll();
			} else {
				if (confirm(result.message)) {
					line['force'] = true;
					this.inventoryService.removeProductLine(line).subscribe((result: any) => {
						if (result.success) {
							this.loadProductLines();
							this.modalService.dismissAll();
						}
					});
				}
			}
		})
	}

	addProductLine() {
		this.productLineForm = this.fb.group({
			line_field: ['', Validators.required],
			line_description: ['', Validators.required],
		});
		this.modalService.open(this.lineEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((r) => {

		}, (reason) => {

		});
	}

	mergeProductLines() {
		const c = confirm('You are about to merge Product Lines, Continue?');
		if (c) {
			if (this.productLineMergeForm.valid) {
				this.merging = true;
				const selected_merge_items = [];
				for (const [id, control] of Object.entries(this.partialMergeItemsControls)) {
					control.value == true ? selected_merge_items.push(id) : '';
				}
				const data = {
					plform: this.productLineMergeForm.value,
					items: selected_merge_items
				}


				this.inventoryService.mergeProductLines(data).subscribe(result => {
					this.merging = false;
					if (result.success) {
						this.loadProductLines();
						this.modalService.dismissAll();
					}
				})
			}
		}
	}

	saveProductLine() {
		if (this.productLineForm.valid) {
			this.productLineForm.get('line_field').enable()
			this.inventoryService.saveProductLine(this.productLineForm.value).subscribe(result => {
				this.loadProductLines();
				this.modalService.dismissAll();
			})
		}
	}

	editPLM(prodline: any) {
		this.selected_linefield = prodline.line_field;
		this.modalService.open(this.plmEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	editManufacturer(mfg: any) {
		this.manufacturerForm = this.fb.group({
			manufacturer_id: [mfg.manufacturer_id],
			isactive: [mfg.isactive],
			active: [mfg.active],
			mfg_code: [mfg.mfg_code, Validators.required],
			name: [mfg.name, Validators.required],
			shorthand_id: [mfg.shorthand_id],
			sort_order: [mfg.sort_order],
			discount_percent: [mfg.discount_percent],
			discount_value: [mfg.discount_value],
			warehouses: [mfg.warehouses],
			url: [mfg.url],
			//email: [mfg.email]
		});

		this.modalService.open(this.manuEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}
	removeManufacturer(line: any) {
		this.inventoryService.removeManufacturer(line).subscribe(result => {
			if (result.success) {
				this.manufacturers = result.mfgs
				this.loadProductLines();
				this.modalService.dismissAll();
				this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'left');
			} else {
				if (confirm(result.message)) {
					line['force'] = true;
					this.inventoryService.removeManufacturer(line).subscribe(r => {
						if (r.success) {
							this.manufacturers = r.mfgs
							this.loadProductLines();
							this.globalSearchService.showNotification(r.message, 'success', 'bottom', 'left');
						}
					});
				}
			}

		})
	}

	disableManufacturer(line: any) {
		const data = {
			mfg_id: line.manufacturer_id,
			mfg_name: line.name
		}
		this.inventoryService.batchDisableBrand(data).subscribe((results: any) => {
			if (confirm(results.message)) {
				data['continue'] = true
				this.inventoryService.batchDisableBrand(data).subscribe((r: any) => {
					if (r.success) {
						this.loadProductLines();
					}
					this.globalSearchService.showNotification(`${r.message}`, 'success', 'bottom', 'left');
				});
			}
		});

	}

	discontinueManufacturer(line: any) {
		const data = {
			mfg_id: line.manufacturer_id,
			mfg_name: line.name
		}
		this.inventoryService.batchDiscontinueBrand(data).subscribe((results: any) => {
			if (confirm(results.message)) {
				data['continue'] = true
				this.inventoryService.batchDiscontinueBrand(data).subscribe((r: any) => {
					if (r.success) {
						this.loadProductLines();
					}
					this.globalSearchService.showNotification(`${r.message}`, 'success', 'bottom', 'left');
				});
			}
		});
	}

	addManufacturer() {
		this.manufacturerForm = this.fb.group({
			manufacturer_id: [''],
			isactive: [1],
			active: [1],
			mfg_code: ['', Validators.required],
			name: ['', Validators.required],
			shorthand_id: [''],
			discount_percent: [0],
			discount_value: [0.00],
			sort_order: [],
			warehouses: [''],
			url: [''],
			//email: ['']
		});
		this.modalService.open(this.manuEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	saveManufacturer() {
		if (this.manufacturerForm.valid) {

			this.manufacturerForm.get('active').setValue(this.manufacturerForm.get('isactive').value);

			this.inventoryService.saveManufacturer(this.manufacturerForm.value).subscribe(result => {
				this.manufacturers = result
				this.modalService.dismissAll();
			})
		}
	}

	getFallBack(pline: any, type: any) {
		const substitute = this.searchingproductlines.filter(r => {
			return (r.typeabbrev == type && pline == r.line_field)
		})[0];

		if (substitute) {
			return substitute;
		}

		return false;
	}

	loadProductLines() {
		this.inventoryService.getItemProductlineDetails().subscribe(results => {
			this.productlines = results;
			this.filteredproductlines = results;
			this.searchingproductlines = results;
			this.setPagination(results);
		});
	}
	ngAfterViewInit() {


	}


	back(): void {
		this.location.back()
	}

	checkStockid(stockid: any) {


	}

	itemExist(stockid: any) {

		let exists = '';
		if (this.toproductline) {

			const newexists = this.toproductline.filter((r: any) => {
				return r.stockid == stockid;
			})[0];

			if (newexists) {
				const pushed = newexists.stockid;

				if (this.allowmmerge.indexOf(pushed) >= 0) {

				} else {
					this.allowmmerge.push(pushed);
				}

				exists = 'ITEM EXISTS';
			}
		}

		return exists;
	}

	selectPline(line: any) {

		const selected = this.productlines.filter(r => {
			return line.option.value.line_field == r.line_field;
		});
		if (selected) {
			this.toproductline = selected[0].details;
		}


	}

	displayFn(pline: any) {

		if (!pline) {
			return '';
		}

		return pline.line_field + '-' + pline.line_description
	}

	disableProductline(line: any) {
		this.vieingpline = line;
		this.batchType = 'Disable';
		this.batchComplete = false;
		this.partialMergeItemsControls = [];
		line.details.map((i) => {
			if (i.enabled == '1') {
				this.partialMergeItemsControls[i.stockid] = new UntypedFormControl(false);
			} else {
				this.partialMergeItemsControls[i.stockid] = new UntypedFormControl(true);
			}
		})

		this.modalService.open(this.plinebatchdisRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			if (this.batchComplete) {
				const dis = [];
				const enable = [];
				Object.entries(this.partialMergeItemsControls).map(([key, value]: any) => {
					if (value.value == true) {
						dis.push(key);
					} else {
						enable.push(key);
					}
				});

				const data = {
					dis: dis,
					enable: enable
				}

				this.inventoryService.batchDisableProducts(data).subscribe((results: any) => {
					this.globalSearchService.showNotification(`${results.count} Items Affected`, 'success', 'bottom', 'left');
					this.loadProductLines();
				});
			} else {
				this.globalSearchService.showNotification('Cancelled Batch Update', 'warning', 'bottom', 'left');
			}
		});
	}

	discontinueProductline(line: any) {
		this.vieingpline = line;
		this.batchType = 'Discontinue';
		this.batchComplete = false;
		this.partialMergeItemsControls = [];
		line.details.map((i) => {
			if (i.discontinued == '0') {
				this.partialMergeItemsControls[i.stockid] = new UntypedFormControl(false);
			} else {
				this.partialMergeItemsControls[i.stockid] = new UntypedFormControl(true);
			}
		});

		this.modalService.open(this.plinebatchdisRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			if (this.batchComplete) {
				const dis = [];
				const enable = [];
				Object.entries(this.partialMergeItemsControls).map(([key, value]: any) => {
					if (value.value == true) {
						dis.push(key);
					} else {
						enable.push(key);
					}
				});

				const data = {
					dis: dis,
					enable: enable
				}

				this.inventoryService.batchDiscontinueProducts(data).subscribe((results: any) => {
					this.globalSearchService.showNotification(`${results.count} Items Affected`, 'success', 'bottom', 'left');
					this.loadProductLines();
				});
			} else {
				this.globalSearchService.showNotification('Cancelled Batch Update', 'warning', 'bottom', 'left');
			}
		});
	}

	allControls(type: any) {
		switch (type) {
			case 'select':
				Object.entries(this.partialMergeItemsControls).map(([key, value]: any) => {
					value.setValue(true);
				});
				break;
			case 'deselect':
				Object.entries(this.partialMergeItemsControls).map(([key, value]: any) => {
					value.setValue(false);
				});
				break;
		}
	}

	completeBatch() {
		this.batchComplete = true;
		this.modalService.dismissAll();
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

}
