import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { TireGuideComponent } from './tire-guide/tire-guide.component';
import { BeautyItemViewComponent } from './beauty-item-view/beauty-item-view.component';
import { StandardItemViewComponent } from './standard-item-view/standard-item-view.component';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRippleModule , MatNativeDateModule } from '@angular/material/core';
import { AddressLookupComponent } from './inputs/address-lookup/address-lookup.component';
import { CustomerLookupComponent } from './inputs/customer-lookup/customer-lookup.component';
import { VendorLookupComponent } from './inputs/vendor-lookup/vendor-lookup.component';
import { IframeViewComponent } from './inputs/iframe-view/iframe-view.component';
import { GlAccountLookupComponent } from './inputs/gl-account-lookup/gl-account-lookup.component';

//import { LabelDesignerModule } from '@luomus/label-designer';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { TiresItemViewComponent } from './tires-item-view/tires-item-view.component';
import { MatChipsModule } from '@angular/material/chips';

import { DateAgoPipe } from './pipes/dateago.pipe';
import { AbsPipe } from './pipes/abs.pipe';
import { PhoneFormatPipe } from './pipes/phoneFormat.pipe';

import { ItemFiltersComponent } from './item-filters/item-filters.component';
import { PurchaseDataComponent } from './purchase-data/purchase-data.component';
import { LazyForDirective } from './directives/lazy-for.directive';
import { PaymentComponent } from './payment/payment.component';
import { PaymentReversalComponent } from './payment-reversal/payment-reversal.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { AllocationsComponent } from './allocations/allocations.component';
import { CurrencyMaskDirectiveDirective } from './currency-mask-directive.directive';
import { HighlightPipe } from './pipes/highlight.pipe';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { OpenOrdersComponent } from './open-orders/open-orders.component';
import { NonstockComponent } from './nonstock/nonstock.component';

import { VendorOpenPosComponent } from './vendor-open-pos/vendor-open-pos.component';
import { MoneyPipePipe } from './pipes/money-pipe.pipe';
import { TrialBalanceComponent } from './trial-balance/trial-balance.component';
import { CustomerTotalComponent } from './customer/customer-total/customer-total.component';
import { CustomerDisabledComponent } from './customer/customer-disabled/customer-disabled.component';


import { DispatchDeliverysheetComponent } from './dispatch-deliverysheet/dispatch-deliverysheet.component';
import { CustomerAgingComponent } from './customer/customer-aging/customer-aging.component';



import { SelectOnFocusDirective } from './pipes/select-on-focus.directive';
import { SharedOrderEntryComponent } from './shared-order-entry/shared-order-entry.component';
import { ItemSearchComponent } from './cart-components/item-search/item-search.component';
import { OrderReviewComponent } from './cart-components/order-review/order-review.component';
import { CreditReviewComponent } from './cart-components/credit-review/credit-review.component';
import { OrderEntryPoComponent } from './order-entry-po/order-entry-po.component';
import { SharedCreditEntryComponent } from './shared-credit-entry/shared-credit-entry.component';
import { OpenCreditsComponent } from './open-credits/open-credits.component';

import { OrderInvoiceComponent } from './order-invoice/order-invoice.component';

import { OrderPaymentsComponent } from './order-payments/order-payments.component';
import { OpenPurchaseOrdersComponent } from './open-purchase-orders/open-purchase-orders.component';

import { PoReviewComponent } from './cart-components/po-review/po-review.component';
import { VendorReturnReviewComponent } from './cart-components/vendor-return-review/vendor-return-review.component';

import { SharedPurchaseOrderEntryComponent } from './shared-purchase-order-entry/shared-purchase-order-entry.component';

import { NeedlocationComponent } from './needlocation/needlocation.component';
import { ZeroCostComponent } from './zero-cost/zero-cost.component';
import { MissingimagesComponent } from './missingimages/missingimages.component';
import { MissingpurchdataComponent } from './missingpurchdata/missingpurchdata.component';
import { NegativeInventoryComponent } from './negative-inventory/negative-inventory.component';
import { VendorAgingComponent } from './vendor-aging/vendor-aging.component';
import { VendorAutoCompleteComponent } from './inputs/vendor-auto-complete/vendor-auto-complete.component';
import { CategoryAutocompleteComponent } from './inputs/category-autocomplete/category-autocomplete.component';
import { ProductlineAutocompleteComponent } from './inputs/productline-autocomplete/productline-autocomplete.component';
import { EditMinMaxComponent } from './edit-min-max/edit-min-max.component';
import { LocationLookupComponent } from './inputs/location-lookup/location-lookup.component';
import { LocationAutocompleteComponent } from './inputs/location-autocomplete/location-autocomplete.component';
import { CreditInvoiceComponent } from './credit-invoice/credit-invoice.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { VendorReturnComponent } from './vendor-return/vendor-return.component';
import { OpenRgasComponent } from './open-rgas/open-rgas.component';
import { DaySalesComponent } from './day-sales/day-sales.component';
import { CardManagerComponent } from './card-manager/card-manager.component';
import { DisablebuttononsubmitDirective } from './directives/disablebuttononsubmit.directive';
import { DebounceBlurDirective } from './directives/debounce-blur.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { CreditCardProfileSelectComponent } from './credit-card-profile-select/credit-card-profile-select.component';
import { BomsComponent } from './boms/boms.component';
import { DragDropModule, CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { BommodalComponent } from './boms/bommodal/bommodal.component';
import { MatchinDocumentsComponent } from './matchin-documents/matchin-documents.component';
import { SharedPosOrderEntryComponent } from './shared-pos-order-entry/shared-pos-order-entry.component';

import { CouponsComponent } from './coupons/coupons.component';
import { PosItemSearchComponent } from './cart-components/pos-item-search/pos-item-search.component';
import { PosOrderReviewComponent } from './cart-components/pos-order-review/pos-order-review.component';
import { PosAddpaymentsComponent } from './pos-addpayments/pos-addpayments.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InventorySearcherComponent } from './inventory-searcher/inventory-searcher.component';
import { DepositsComponent } from './deposits/deposits.component';
import { ItemPickHistoryComponent } from './item-pick-history/item-pick-history.component';
import { CustomerNoPurchaseComponent } from './customer-no-purchase/customer-no-purchase.component';
import { TelegramReportsComponent } from './telegram-reports/telegram-reports.component';
import { IdetReportComponent } from './idet-report/idet-report.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { TableSortDirective } from './directives/table-sort.directive';
import { CustomerLookupPosComponent } from './inputs/customer-lookup-pos/customer-lookup-pos.component';
import { PosOrderPaymentsComponent } from './pos-order-payments/pos-order-payments.component';
import { SelectAllComponent } from './inputs/select-all/select-all.component';
import { CustomerInactiveComponent } from './customer-inactive/customer-inactive.component';
import { LabelDesignerComponent } from './label-designer/label-designer.component';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { BrandReportComponent } from './brand-report/brand-report.component';
import { MultiselectDirective } from './directives/multiselect.directive';
import { NumericFieldDirective } from './directives/numeric-field.directive';
import { NoPlusMinusDirective } from './directives/no-plus-minus.directive';
import { NoEnterKeyDirective } from './directives/no-enter-key.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ItemMissingbrandsComponent } from './item-missingbrands/item-missingbrands.component';
import { GappReportComponent } from './gapp-report/gapp-report.component';
import { CollapseKeyboardDirective } from './directives/collapsekeyboard.directive';
import { OpenQuotesComponent } from './open-quotes/open-quotes.component';
import { NgxMaskModule } from 'ngx-mask';
import { PosNewCustomerComponent } from './pos-new-customer/pos-new-customer.component';
import { TiresTemplateComponent } from './cart-components/item-search/search-templates/tires-template/tires-template.component';
import { BeautyTemplateComponent } from './cart-components/item-search/search-templates/beauty-template/beauty-template.component';
import { PosCustomerNotesComponent } from './pos-customer-notes/pos-customer-notes.component';
import { SalesmanTemplateComponent } from './cart-components/item-search/search-templates/salesman-template/salesman-template.component';
import { OpenordersAndInvoicesComponent } from './openorders-and-invoices/openorders-and-invoices.component';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { TopHundredComponent } from './top-hundred/top-hundred.component';
import { PosOrderInvoiceComponent } from './pos-order-invoice/pos-order-invoice.component';
import { PosOrderPaymentsAfterComponent } from './pos-order-payments-after/pos-order-payments-after.component';
import { CidReportComponent } from './cid-report/cid-report.component';
import { SelectLengthDirective } from './directives/select-length.directive';
import { CustomerTransComponent } from './customer-trans/customer-trans.component';
import { JpmsReportComponent } from './jpms-report/jpms-report.component';
import { ReportsPmmComponent } from './reports-pmm/reports-pmm.component';
import { NegativeItemsComponent } from './negative-items/negative-items.component';
import { NeedBinLocationComponent } from './need-bin-location/need-bin-location.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { BeautyWqohComponent } from './cart-components/item-search/search-templates/beauty-wqoh/beauty-wqoh.component';
import { BarcodeAssignerComponent } from './barcode-assigner/barcode-assigner.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { SalesmanwqohTemplateComponent } from './cart-components/item-search/search-templates/salesmanwqoh-template/salesmanwqoh-template.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../environments/environment';
import { PriceLevelPricingComponent } from './price-level-pricing/price-level-pricing.component';
import { StickyDirective } from './directives/sticky.directive';


@NgModule({
	declarations: [
		TireGuideComponent,
		AddressLookupComponent,
		BeautyItemViewComponent,
		StandardItemViewComponent,
		TiresItemViewComponent,
		DateAgoPipe,
		AbsPipe,
		ItemFiltersComponent,
		PurchaseDataComponent,
		LazyForDirective,
		PaymentComponent,
		PaymentReversalComponent,
		SanitizeHtmlPipe,
		AllocationsComponent,
		CurrencyMaskDirectiveDirective,
		HighlightPipe,
		OpenOrdersComponent,
		NonstockComponent,
		IframeViewComponent,
		VendorOpenPosComponent,
		MoneyPipePipe,
		TrialBalanceComponent,
		CustomerTotalComponent,
		CustomerDisabledComponent,
		DispatchDeliverysheetComponent,
		CustomerAgingComponent,
		SelectOnFocusDirective,
		SharedOrderEntryComponent,
		ItemSearchComponent,
		OrderReviewComponent,
		CreditReviewComponent,
		OrderEntryPoComponent,
		SharedCreditEntryComponent,
		OpenCreditsComponent,
		CustomerLookupComponent,
		PhoneFormatPipe,
		OrderInvoiceComponent,
		OrderPaymentsComponent,
		OpenPurchaseOrdersComponent,
		PoReviewComponent,
		SharedPurchaseOrderEntryComponent,
		NeedlocationComponent,
		ZeroCostComponent,
		MissingimagesComponent,
		MissingpurchdataComponent,
		NegativeInventoryComponent,
		VendorLookupComponent,
		VendorAgingComponent,
		GlAccountLookupComponent,
		VendorAutoCompleteComponent,
		CategoryAutocompleteComponent,
		ProductlineAutocompleteComponent,
		EditMinMaxComponent,
		LocationLookupComponent,
		LocationAutocompleteComponent,
		CreditInvoiceComponent,
		CreditCardFormComponent,
		VendorReturnReviewComponent,
		VendorReturnComponent,
		OpenRgasComponent,
		DaySalesComponent,
		CardManagerComponent,
		DisablebuttononsubmitDirective,
		DebounceBlurDirective,
		AutofocusDirective,
		CreditCardProfileSelectComponent,
		BomsComponent,
		BommodalComponent,
		MatchinDocumentsComponent,
		SharedPosOrderEntryComponent,
		CouponsComponent,
		PosItemSearchComponent,
		PosOrderReviewComponent,
		PosAddpaymentsComponent,
		InventorySearcherComponent,
		ItemPickHistoryComponent,
		DepositsComponent,
		CustomerNoPurchaseComponent,
		BrandReportComponent,
		TelegramReportsComponent,
		IdetReportComponent,
		TableSortDirective,
		CustomerLookupPosComponent,
		PosOrderPaymentsComponent,
		SelectAllComponent,
		CustomerInactiveComponent,
		LabelDesignerComponent,
		QuoteListComponent,
		MultiselectDirective,
		NumericFieldDirective,
		NoPlusMinusDirective,
		NoEnterKeyDirective,
		ItemMissingbrandsComponent,
		GappReportComponent,
		CollapseKeyboardDirective,
		OpenQuotesComponent,
		PosNewCustomerComponent,

		TiresTemplateComponent,
		BeautyTemplateComponent,
		PosCustomerNotesComponent,
		SalesmanTemplateComponent,
		OpenordersAndInvoicesComponent,
		HtmlToTextPipe,
		TopHundredComponent,
		PosOrderInvoiceComponent,
		PosOrderPaymentsAfterComponent,
		CidReportComponent,
  SelectLengthDirective,
  CustomerTransComponent,
  JpmsReportComponent,
  ReportsPmmComponent,
  NegativeItemsComponent,
  NeedBinLocationComponent,
  ItemDetailsComponent,
  BeautyWqohComponent,
  BarcodeAssignerComponent,
  FormBuilderComponent,
  SalesmanwqohTemplateComponent,
  PriceLevelPricingComponent,
  StickyDirective
	],
	imports: [
		CommonModule,
		MatTooltipModule,
		MatRippleModule,
		FormsModule,
		NgbModule,
		RouterModule,
		ReactiveFormsModule,
		ScrollingModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatChipsModule,
		MatTabsModule,
		MatListModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatExpansionModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatButtonToggleModule,
		MatSidenavModule,
		MatBadgeModule,
		MatRadioModule,
		MatProgressBarModule,
		CreditCardDirectivesModule,
		DragDropModule,
		TableModule,
		NgxDropzoneModule,
		NgxPaginationModule,
		NgxExtendedPdfViewerModule,
		//ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		//LabelDesignerModule,
		NgxWebstorageModule.forRoot({
			prefix: 'LD-',
			separator: ''
		}),
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		NgxMaskModule.forRoot()

	],
	exports: [
		TireGuideComponent,
		AddressLookupComponent,
		TiresItemViewComponent,
		StandardItemViewComponent,
		BeautyItemViewComponent,
		ItemFiltersComponent,
		DateAgoPipe,
		PurchaseDataComponent,
		LazyForDirective,
		PaymentReversalComponent,
		AbsPipe,
		AllocationsComponent,
		PaymentComponent,
		CurrencyMaskDirectiveDirective,
		OpenOrdersComponent,
		NonstockComponent,
		IframeViewComponent,
		VendorOpenPosComponent,
		MoneyPipePipe,
		TrialBalanceComponent,
		CustomerTotalComponent,
		CustomerDisabledComponent,
		DispatchDeliverysheetComponent,
		CustomerAgingComponent,
		SelectOnFocusDirective,
		SharedOrderEntryComponent,
		SharedCreditEntryComponent,
		OpenCreditsComponent,
		PhoneFormatPipe,
		CustomerLookupComponent,
		OrderPaymentsComponent,
		OpenPurchaseOrdersComponent,
		SharedPurchaseOrderEntryComponent,
		NeedlocationComponent,
		ZeroCostComponent,
		MissingimagesComponent,
		MissingpurchdataComponent,
		NegativeInventoryComponent,
		VendorLookupComponent,
		VendorAgingComponent,
		GlAccountLookupComponent,
		VendorAutoCompleteComponent,
		CategoryAutocompleteComponent,
		ProductlineAutocompleteComponent,
		EditMinMaxComponent,
		LocationAutocompleteComponent,
		CreditInvoiceComponent,
		OrderInvoiceComponent,
		CreditCardFormComponent,
		VendorReturnReviewComponent,
		VendorReturnComponent,
		OpenRgasComponent,
		DaySalesComponent,
		CardManagerComponent,
		DisablebuttononsubmitDirective,
		DebounceBlurDirective,
		BomsComponent,
		BommodalComponent,
		ItemSearchComponent,
		MatchinDocumentsComponent,
		SharedPosOrderEntryComponent,
		CouponsComponent,
		PosItemSearchComponent,
		PosOrderReviewComponent,
		InventorySearcherComponent,
		ItemPickHistoryComponent,
		CustomerNoPurchaseComponent,
		TelegramReportsComponent,
		IdetReportComponent,
		TableSortDirective,
		CustomerLookupPosComponent,
		PosOrderPaymentsComponent,
		SelectAllComponent,
		CustomerInactiveComponent,
		QuoteListComponent,
		BrandReportComponent,
		MultiselectDirective,
		NumericFieldDirective,
		NoPlusMinusDirective,
		ItemMissingbrandsComponent,
		GappReportComponent,
		CollapseKeyboardDirective,
		OpenQuotesComponent,
		PosNewCustomerComponent,
		PosCustomerNotesComponent,
		OpenordersAndInvoicesComponent,
		HtmlToTextPipe,
		TopHundredComponent,
		PosOrderInvoiceComponent,
		PosOrderPaymentsAfterComponent,
		SelectLengthDirective,
		NegativeItemsComponent,
		NeedBinLocationComponent,
		ItemDetailsComponent,
		PriceLevelPricingComponent
	]
})
export class SharedModule {}
