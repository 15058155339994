import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OrdersDashboardComponent } from './orders-dashboard/orders-dashboard.component';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { OrderLookupComponent } from './order-lookup/order-lookup.component';


import { OrderSearchComponent } from './order-search/order-search.component';
import { OrderOpenlistComponent } from './order-openlist/order-openlist.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { OrderListComponent } from './order-list/order-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';

import * as _ from 'underscore';

import { OrderPickComponent } from './order-pick/order-pick.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AgmCoreModule } from '@agm/core';

import { NgxImageZoomModule } from 'ngx-image-zoom';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';

import { TimelineComponent } from './timeline/timeline.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';

import { RouteBuilderComponent } from './route-builder/route-builder.component';
import { RouteComponent } from './route/route.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { OrderShortSearchComponent } from './order-short-search/order-short-search.component';
import { OrderLoadComponent } from './order-load/order-load.component';
import { OrderEntryComponent } from './order-entry/order-entry.component';
import { CustomerOpenOrdersComponent } from './order-entry/customer-open-orders/customer-open-orders.component';
import { OrderChangeCustomerComponent } from './order-entry/order-change-customer/order-change-customer.component';

import { OrderDispatchComponent } from './order-entry/order-dispatch/order-dispatch.component';
import { OrderSuccessComponent } from './order-entry/order-success/order-success.component';
import { OrderReprintPickComponent } from './order-reprint-pick/order-reprint-pick.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { BtoConfigComponent } from './order-entry/bto-config/bto-config.component';

import { SharedModule } from '../shared/shared.module';
import { CustomersModule } from '../customers/customers.module';
import { DashboardComponentsModule } from '../dashboard-components/dashboard-components.module';
import { AdrsComponent } from './adrs/adrs.component'
import { TextMaskModule } from 'angular2-text-mask';
import { OrderStatusViewComponent } from './order-status-view/order-status-view.component';
import { OrderInquiryComponent } from './order-inquiry/order-inquiry.component';
import { OrderCheckComponent } from './order-check/order-check.component';
import { ProductlineReportComponent } from './productline-report/productline-report.component';
import { MatTreeModule } from '@angular/material/tree';
import { ProductlinetreeComponent } from './productline-report/productlinetree/productlinetree.component';
import { CdkDetailRowDirective } from './productline-report/cdk-detail-row.directive';
import { ProductlinetableComponent } from './productline-report/productlinetable/productlinetable.component';
import { ProductlinecusttableComponent } from './productline-report/productlinecusttable/productlinecusttable.component';
import { ProductlineordertableComponent } from './productline-report/productlineordertable/productlineordertable.component';
import { OrderItemInquiryComponent } from './order-item-inquiry/order-item-inquiry.component';
import { OrdersOpenQuotesComponent } from './orders-open-quotes/orders-open-quotes.component';

import { SpslsComponent } from './spsls/spsls.component';

@NgModule({
	declarations: [
		OrdersDashboardComponent,
		OrderLookupComponent,
		OrderSearchComponent,
		OrderViewComponent,
		OrderManagementComponent,
		OrderListComponent,
		OrderPickComponent,
		TimelineComponent,
		RouteBuilderComponent,
		RouteComponent,
		CustomerSearchComponent,
		OrderShortSearchComponent,
		OrderOpenlistComponent,
		OrderLoadComponent,
		OrderEntryComponent,
		CustomerOpenOrdersComponent,
		OrderChangeCustomerComponent,
		OrderDispatchComponent,
		OrderSuccessComponent,
		OrderReprintPickComponent,
		OrderConfirmationComponent,
		BtoConfigComponent,
		AdrsComponent,
		OrderStatusViewComponent,
		OrderInquiryComponent,
		OrderCheckComponent,
		ProductlineReportComponent,
		ProductlinetreeComponent,
		CdkDetailRowDirective,
		ProductlinetableComponent,
		ProductlinecusttableComponent,
		ProductlineordertableComponent,
		OrderItemInquiryComponent,
		OrdersOpenQuotesComponent,
		SpslsComponent
	],
	imports: [
		MatTreeModule,
		CommonModule,
		BrowserModule,
		RouterModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		MatCheckboxModule,
		ScrollingModule,
		DragDropModule,
		NgSelectModule,

		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatListModule,
		MatRadioModule,
		PopoverModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatExpansionModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		MatBadgeModule,
		MatChipsModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		NgxImageZoomModule,
		SharedModule,
		CustomersModule,
		TextMaskModule,
		DashboardComponentsModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyDA53SUChfib7syOI2S45eR9ByL8SHXFkU'
		})
	],
	providers: [

	],
})
export class OrdersModule {}
