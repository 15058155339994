import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DispatchService } from 'app/services/dispatch.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { OmsService } from 'app/services/oms.service';
import { OrdersService } from 'app/services/orders.service';

@Component({
	selector: 'app-dispatches-load-priority',
	templateUrl: './dispatches-load-priority.component.html',
	styleUrls: ['./dispatches-load-priority.component.scss']
})
export class DispatchesLoadPriorityComponent implements OnInit {

	bays: any = false;
	changesMade: boolean = false;
	trucks: any = false;
	color = 'blue';
	CHAT_ROOM = 'OrderBoard';

	constructor(private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {

	}

	ngOnInit(): void {
		this.loadData();
	}

	loadData() {
		this.dispatchService.getAllTrucks().subscribe((res) => {
			this.trucks = res;
		})
		this.dispatchService.getBayStatus().subscribe((res: any) => {
			this.bays = {
				'1-One': res.one,
				'2-Two': res.two,
				'3-Three': res.three
			}
		})
	}

	formatKey(key) {
		return key.split('-')[1];
	}

	saveEdits() {
		var data = {
			one: this.bays['1-One'],
			two: this.bays['2-Two'],
			three: this.bays['3-Three']
		}
		this.dispatchService.updateBayPriority(data).subscribe((res) => {
			if (res.success) {
				this.omsService.loadingBayAssigned({ data, roomName: this.CHAT_ROOM }, cb => {
					if (cb.status == 'ok') {
						this.globalSearchService.showNotification('Bays updated', 'success', 'bottom', 'left');
						this.reset();
					}
				});
			}
		})
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousIndex !== event.currentIndex || event.previousContainer !== event.container) {
			this.changesMade = true;
		}
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			//dont allow for active replace unless moving to empty bay
			if(event.currentIndex > 0 || event.container.data.length == 0){
				transferArrayItem(
					event.previousContainer.data,
					event.container.data,
					event.previousIndex,
					event.currentIndex,
				);
			}
		}
	}

	reset() {
		this.changesMade = false;
		this.loadData();
	}

	openDisp(){
		console.log('open disp modal for disp contents');
		//todo modal for order viewing
			//maybe pull from old loading or active orders
	}


	evenPredicate(index, item: CdkDrag<any>) {
		//don't allow active replace
		return item.data.active ? false: true;
	}

	noActiveEdits(index, item: CdkDrag<any>){
		//don't allow active replace
		return index == 0  ? false : true;
	}
}
