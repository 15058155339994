import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from 'file-saver';
import { UsersService } from '../../services/users.service';

@Component({
	selector: 'app-order-invoice',
	templateUrl: '../dispatch-invoice-shared.html',
	styleUrls: ['../dispatch-invoice-shared.scss']
})
export class OrderInvoiceComponent implements OnInit {

	@Input() orderno: any = false;
	@Input() reload: any = false;
	@Input() ctlordirect: any = 'ctl';
	@Output() invoiced = new EventEmitter < any > ();
	@Output() forceReset = new EventEmitter < any > ();
	@Output() reloadCustomer = new EventEmitter < any > ();

	invoice_result: any = false;
	document_text = 'Invoice';
	document_type = '30';
	documentDetails: FormGroup;
	fupdate: any = false;

	order: any = {
		header: [],
		details: [],
		tax_data: {},
	};

	//orderno: any = '';
	debtorno: any = '';
	branchcode: any = '';
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching = false;
	addingpay = false;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total = 0;
	freight_charge = 0.00;
	adjustment: any = 0.00;
	adjustmenttext: any = '';
	payment_total = 0.00
	freight_taxes: any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		adjustment: 0.00,
		discount: 0.00,
		total: 0.00,
	};

	taxdatatotal = 0;

	prefered_vendor: any = [];
	payments: any = [];
	color = 'blue'
	sending = false;
	config: any = false;
	baseUrl = '';
	invoiceLink = '';
	terms: any = false;
	salespeople: any = false;
	shipvias: any = false;
	document_types = [
		{ label: 'Back Order', value: 'backorder' },
		{ label: 'Cancel', value: 'cancel' },
	];

	doc_return_type = 'Item Balance';
	tax_details = [];
	isposuser = false;
	printed: boolean = false;
	constructor(private omsService: OmsService, private usersService: UsersService, private fb: FormBuilder, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {

		this.color = this.globalSearchService.getColor();
		this.baseUrl = this.globalSearchService.config.apiServer.baseUrl;
		this.invoiceLink = this.globalSearchService.config.apiServer.invoiceLink;
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {


		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.shipvia.subscribe((results: any) => {
			this.shipvias = results;
		});

		//this.loadOrder();
		//this.route.params.subscribe(params => {
		//	this.orderno = params.id
		//	this.loadOrder()
		//})
	}

	getTotalDispatching() {
		let value = 0;
		if (this.order.details) {
			value = this.order.details.reduce(function(accumulator, item) {
				const dispatchValue = parseFloat(item.dispatch);
				return dispatchValue > 0 ? accumulator + dispatchValue : accumulator;
			}, 0);
		}

		return value;
	}



	getBalance() {
		const value = this.totals.total + this.adjustment - this.payment_total;
		//if(value < 0) {
		//	value = 0.00
		//}
		return value;
	}

	createInvoice() {
		this.updateTotals();

		if (this.order && this.documentDetails.valid) {
			const data = {
				order: this.order,
				totals: this.totals,
				payments: this.payments_added,
				taxes: this.order.tax_data,
				adjustmenttext: this.adjustmenttext,
				document_form: this.documentDetails.value
			}

			this.sending = true;

			this.orderService.createInvoice(data).subscribe((results: any) => {
				this.sending = false;
				if (results.success) {
					this.invoiced.emit(results);
					this.reloadCustomer.emit(true);
					if (this.ctlordirect == 'direct') {
						this.invoice_result = false;
						this.reloadCustomer.emit(true);
						this.forceReset.emit(true);
						this.clearCart();
						this.usersService.isPosUser().subscribe((isPOS) => {
							if (isPOS) {
								this.router.navigate(['/pos/success/' + results.transno]);
							} else {
								this.router.navigate(['/orders/success/' + results.id]);
							}
						});
					}
				} else {
					alert(results.message);
				}
			});
		} else {
			if (!this.documentDetails.valid) {
				this.globalSearchService.getFormValidationErrors(this.documentDetails);
			}
		}
	}

	clearCart() {
		this.orderService.clearOrder(this.order.header.debtorno, this.order.header.branchcode).subscribe((results: any) => {});
	}

	termRequiresPayment() {
		const term = this.terms.filter(r => {
			return r.termsindicator == this.documentDetails.get('terms').value;
		})[0];

		if (term) {
			return term.collect_payment == '1';
		}

		return false;
		//collect_payment
	}

	updateDispatchQty(event: any, item: any) {

		if (event.target.value != '' && event.target.value != ' ') {
			// Check if the input value is numeric
			if (isNaN(event.target.value)) {

			} else {
				// Compare and process the values
				if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
					item.dispatch = parseFloat(event.target.value);
				} else {
					event.target.value = parseFloat(item.ordered);
					item.dispatch = parseFloat(item.ordered);
				}

				this.updateTotals();
			}
		}


	}

	setQty(event: any, item: any) {
		if (parseFloat(item.ordered) < parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {
			item.dispatch = item.ordered;
		}

		this.updateTotals()
	}

	updatePayments(event: any) {

		if (event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}

		const data = {
			orderno: this.order.header.orderno,
			debtorno: this.order.header.debtorno,
			branchcode: this.order.header.branchcode,
		}

		this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
			this.updateTotals()

			this.payment_total = this.financial(results.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0));

		});

	}

	setFreightCharge(event: any) {

		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			if (!(event.target.value.trim() === '.')) {}
		} else {

			this.order.header.freightcost = parseFloat(event.target.value)
			this.freight_charge = this.order.header.freightcost;
			this.updateTotals();
		}
	}
	setAdjustment(event: any) {
		const value = parseFloat(event);
		if (isNaN(value) || event == '') {
			if (!(event.trim() === '.') && !(event.trim() === '-')) {
				this.adjustment = 0.00;
				this.updateTotals();
			}
		} else {
			this.adjustment = parseFloat(event);
			this.updateTotals();
		}
	}
	setAdjustmentText(event: any) {
		this.adjustmenttext = event.target.value;
	}

	setFreightTax(event: any, tax: any) {
		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			// If it's NaN or less than 0, set it to 0 unless the last character is a period
			if (!(event.target.value.trim() === '.')) {
				event.target.value = 0;
			}
		}

		tax.taxrate = parseFloat(event.target.value);
		this.updateTotals();
	}

	setTax(event: any, item: any, taxindex: any) {
		// Parse event.target.value as a number
		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			// If it's NaN or less than 0, set it to 0 unless the last character is a period
			if (!(event.target.value.trim() === '.')) {
				event.target.value = 0;
			}
		}

		// Update tax rate with the sanitized value
		item.taxes[taxindex].taxrate = parseFloat(event.target.value);

		// Update totals
		this.updateTotals();
	}

	getFreightTax() {
		this.freight_tax_total = 0;
		const freight = this.freight_charge;

		if (this.order && this.order.freight_tax) {
			const taxauths = this.order.freight_tax.map(tax => {
				const taxrate = this.roundToDecimalPlaces(tax.taxrate / 100, 6);
				const taxvalue = this.roundToDecimalPlaces(taxrate * freight, 2);
				return { taxauthid: tax.taxauthid, value: taxvalue };
			});

			this.freight_taxes = taxauths;

			const freighttax = this.order.freight_tax.reduce((accumulator, tax) => {
				return accumulator + (tax.taxrate / 100) * freight;
			}, 0);

			this.freight_tax_total = this.roundToDecimalPlaces(freighttax, 2);
			return freighttax;
		}

		return 0;
	}


	updateTotals() {

		//todo clean this up. requires two containers..
		//parse items subtotals / taxes first.
		this.totals = {
			subtotal: 0.00,
			freight: 0.00,
			tax: 0.00,
			discount: 0.00,
			adjustment: 0.00,
			total: 0.00,
		};
		this.totals.total = 0;
		let totaltax = 0;
		//zero out tax containers
		const parent = this;

		this.order.tax_data.authorities.forEach((item, index) => {
			this.order.tax_data.authorities[index].total = 0;
		});

		const freighttax = this.getFreightTax()
		//add freight tax to tax total container

		this.freight_taxes.forEach((fghtax) => {
			this.order.tax_data.authorities.forEach((tax, index) => {
				if (tax.taxauthid == fghtax.taxauthid) {
					const value = parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
					if (value) {
						this.order.tax_data.authorities[index].total += parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
					}
				}
			})
		})

		if (this.order) {
			if(this.order.details && this.order.details.length) {
				this.order.details.forEach((item, index) => {
					//subtotals
					const discountprice = item.unitprice - parent.financial((item.discountpercent * item.unitprice));

					const subtotal = parent.financial(discountprice * item.dispatch);

					this.order.details[index].linesubtotal = subtotal;

					const parentindex = index;
					//this.order.details[index].taxtotal = 0;
					//item tax breakdown
					if (this.order.tax_data.authorities.length > 0) {
						item.taxes.forEach((tax, subindex) => {

							const thistax = this.order.tax_data.authorities.filter(all => all.taxauthid == tax.taxauthid)[0];
							if (thistax) {
								//!! LET PHP HANDLE THE ROUNDING
								const tindex = this.order.tax_data.authorities.indexOf(thistax);
								const authtax =  parent.financial(((tax.taxrate / 100) * subtotal));

								if (authtax) {
									if (this.order.tax_data.authorities[tindex]) {
										this.order.tax_data.authorities[tindex].total += authtax;
										this.order.details[parentindex].taxes[subindex].total = authtax;
									}
								} else {
									if (this.order.tax_data.authorities[tindex]) {
										this.order.tax_data.authorities[tindex].total += 0.00;
										this.order.details[parentindex].taxes[subindex].total = 0.00;
									}
								}
							}
						});
					}
					//after
					const total_linetax = item.taxes.reduce(function(accumulator, line) {
						return accumulator + parent.financial(((line.taxrate / 100) * subtotal));
					}, 0);

					this.order.details[index].taxtotal = (total_linetax);
					this.order.details[index].linetotal = (total_linetax + subtotal);

					totaltax += total_linetax;
				});
			}

			this.totals.subtotal = this.order.details.reduce(function(accumulator, line) {
				//this financial not avail in reduce
				const discountpriced = parent.financial(line.discountpercent * line.unitprice)
				//incorect
				const discountprice = line.unitprice - discountpriced;
				const subtotal = parent.financial(discountprice * line.dispatch);

				return accumulator + subtotal;
			}, 0);

			this.taxdatatotal = this.order.tax_data.authorities.reduce(function(accumulator, line) {
				return accumulator + (line.total);
			}, 0);

			//total tax includes freight tax
			this.totals.tax = this.financial(totaltax) + this.financial(freighttax);
			this.totals.freight = this.freight_charge;
			this.totals.adjustment = this.financial(this.adjustment);
			this.totals.total = this.totals.subtotal + this.totals.freight + this.totals.tax + this.totals.discount;
			this.checkPreAuth()

		}
	}

	checkPreAuth() {
		const { preauthdetails } = this.order;
		if (!preauthdetails) return;

		const authTotal = Number(preauthdetails.authtotal);
		const total = Number(this.totals.total + this.totals.adjustment);

		if (isNaN(authTotal) || isNaN(total)) {
			console.error("Invalid total values. Cannot perform comparison.");
			return;
		}
		const preAuthMessage = `Pre Authorization for $${authTotal.toFixed(2)} will be completed`;
		const mismatchMessage = `INVOICE AMOUNT DOES NOT MATCH AUTHORIZATION AMOUNT, NEW AUTHORIZATION AMOUNT $${total.toFixed(2)}`;

		if (this.roundToDecimalPlaces(authTotal, 2) !== this.roundToDecimalPlaces(total, 2)) {
			preauthdetails.message = mismatchMessage;
			preauthdetails.new_auth = true;
		} else {
			preauthdetails.message = preAuthMessage;
			preauthdetails.new_auth = false;
		}
	}


	roundToDecimalPlaces(num: number, decimals: number): number {
		const multiplier = Math.pow(10, decimals);
		return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
	}


	financial(num: number): number {
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
		return parseFloat(rounded.toFixed(2));
	}

	santizeNumberValue(input: any) {
		return (Number.isNaN(input) || input == '') ? 0 : this.financial(input);
	}

	back(): void {
		this.location.back()
	}

	loadOrder() {

		this.order = {
			header: [],
			details: [],
			tax_data: {},
		};

		this.orderService.getOrder(this.orderno).subscribe((details) => {

			this.order = details

			if (this.order.header.orderstatusid == '51') {
				this.freight_charge = 0.00
				this.order.header.freightcost = 0.00
			} else {
				this.freight_charge = parseFloat(this.order.header.freightcost)
			}
			this.debtorno = this.order.header.debtorno
			this.branchcode = this.order.header.branchcode
			this.orderno = this.order.header.orderno
			//display on loaded
			this.order.tax_data.authorities.forEach(r => {
				r.taxrate = parseFloat(r.taxrate) * 100
			});

			const data = {
				orderno: this.order.header.orderno,
				debtorno: this.order.header.debtorno,
				branchcode: this.order.header.branchcode,
			}
			if (this.order.header?.additionalcharge && this.order.header.additionalcharge != '') {
				this.setAdjustment(this.order.header.additionalcharge);
				this.adjustmenttext = this.order.header.additionaltext;
				this.updateTotals();
			}
			this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
				this.payments = results;
				this.setForm();
				this.updateTotals();
			});


		})
	}

	setForm() {

		const cancel_or_bo = (this.config && this.config.env.package == 'beauty') ? 'backorder' : 'cancel';

		this.documentDetails = this.fb.group({
			invoiceDate: [new Date().toISOString(), Validators.required],
			packages: [1, Validators.required],
			invoiceText: [''],
			email: [this.order?.invoiceemailaddress || ''],
			customerref: [this.order?.header?.customerref || ''],
			terms: [this.order?.header?.termsindicator || '', Validators.required],
			salesperson: [this.order?.header?.salesmancode || '', Validators.required],
			shipvia: [this.order?.header?.shipvia || '', Validators.required],
			document_type: [cancel_or_bo, Validators.required],
			pickedby: [null],
			packedby: [null],
		});

		if (this.config.env.package === 'beauty' && this.config.env.client !== 'aaprod') {
			['pickedby', 'packedby'].forEach(field => {
				const control = this.documentDetails.get(field);
				control?.setValidators(Validators.required);
				control?.updateValueAndValidity();
			});
		}

		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isposuser = true;
			}
		});

	}



	paymentRequired() {

	}

	ngOnChanges(changes: any) {
		if (changes.orderno) {
			this.invoice_result = false;
			this.loadOrder()
		}
	}

	toggleAddingPay() {
		this.addingpay = (this.addingpay) ? false : true;
	}

	addPoItems(supplier: any) {

		const item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	checkAuthStatus(authData: any): boolean {
		const existingAuthsExist = authData.existing_auths && authData.existing_auths.length > 0;
		const newAuthStatus = authData.new_auth === false;

		return existingAuthsExist && newAuthStatus;
	}

	ngAfterViewInit(): void {

	}

}