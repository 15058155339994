<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{color}}-6">
				<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Valuation </h4>
				<p class="card-category"></p>
			</div>
			<div class="card-body">
				<form [formGroup]="searchForm" *ngIf="searchForm">
					<div class="row">
						<div class="col-md-3">
							<mat-form-field appearance="outline">
								<mat-label>Warehouse</mat-label>
								<mat-select formControlName="loccode" multiple>
									<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
										{{ loc.locationname }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<div class="col-md-3">
							<mat-form-field appearance="outline" class="">
								<mat-label>Categories</mat-label>
								<mat-select formControlName="categories" multiple>
									<mat-option [value]="''">All</mat-option>
									<mat-option *ngFor="let k of categories" [value]="k.categoryid">
										{{ k.categorydescription }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field appearance="outline" class="">
								<mat-label>Brand</mat-label>
								<mat-select formControlName="brand" multiple>
									<mat-option [value]="''">All</mat-option>
									<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">
										{{ k.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-3">
							<mat-form-field appearance="outline" class="">
								<mat-label>Summary/Details</mat-label>
								<mat-select formControlName="type">
									<mat-option *ngFor="let k of types" [value]="k.value">
										{{ k.label }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-6 text-left">
							<ng-container *ngIf="report">
								<button mat-raised-button class="mt-2 mb-2" color="accent" (click)="xls()">Export XLS</button>
								<button mat-raised-button class="ml-2 mt-2 mb-2" color="accent" (click)="pdf()">Export PDF</button>
							</ng-container>
						</div>
						<div class="col-6 text-right">
							<button mat-raised-button class="mt-2 mb-2" color="accent" (click)="loadData()" *ngIf="!running">Run</button>


							<span *ngIf="running || exporting"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg></span>
						</div>
					</div>
				</form>
				<ng-container *ngIf="report.totals && !running">
					<div #print_table class="container-fluid p-3">
						<div id="results" class="w-100" >
							<div class="table">
								<h5>Totals</h5>
								<table calss="table table-sm" >
									<tr>
										<th class="text-center">Total Units</th>
										<th class="text-right">Total Value</th>
									</tr>
									<tr>
										<td class="text-center">{{ report.totals.count }}</td>
										<td class="text-right">{{ report.totals.total |currency }}</td>
									</tr>
								</table>
							</div>
							<div class="table table-striped" *ngIf="this.searchForm.get('type').value=='S'">
								<h5>Inventory Value Summary</h5>
								<table calss="table table-striped w-100" style="width:100% !important;">
									<tr>
										<th>ID#</th>
										<th>Desc</th>
										<th class="text-center">Qty</th>
										<th class="text-right">Total</th>
									</tr>
									<tr *ngFor="let det of report.categories">
										<td>{{ det.categoryid }}</td>
										<td>{{ det.description }}</td>
										<td class="text-center">{{ det.count }}</td>
										<td class="text-right">{{ det.value |currency }}</td>
									</tr>
								</table>
							</div>
							<div class="table table-striped  w-100 " *ngIf="this.searchForm.get('type').value=='D'">
								<h5>Inventory Value Details</h5>
								<table class="table table-hover table-fixed table-striped w-100" style="width:100% !important;">
									<thead>
									<tr>
										<th class="text-left">Item#</th>
										<th class="text-left">Brand</th>
										<th class="text-left">Description</th>
										<th class="text-center">Qty</th>
										<th>Units</th>
										<th class="text-right">Cost</th>
										<th class="text-right">Total</th>
									</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let det of report.details">
											<ng-container *ngIf="det.category_break">
												<tr>
													<th class="text-left">{{ det.categoryid }} {{ det.categorydescription }}</th>
													<th colspan="3" class="text-right">UNITS: {{ getCategoryTotalUnits(det.categoryid) }}</th>
													<th colspan="3" class="text-right">TOTAL: {{ getCategoryTotal(det.categoryid) | currency }}</th>
												</tr>
											</ng-container>
											<tr *ngIf="det.categoryid != 'T - Consignment'">
												<td class="text-left">{{ det.stockid }}</td>
												<td class="text-left">{{ det.brand }}</td>
												<td class="text-left">{{ det.description }}</td>
												<td class="text-center">{{ det.qtyonhand }}</td>
												<td>{{ det.units }}</td>
												<td class="text-right">{{ det.unitcost | currency }}</td>
												<td class="text-right">{{ det.itemtotal | currency}}</td>
											</tr>
											<tr *ngIf="det.categoryid == 'T - Consignment'">
												<td class="text-left">{{ det.stockid }}</td>
												<td class="text-left">{{ det.brand }}</td>
												<td class="text-left"><span *ngIf="det.debtorno">{{ det?.debtorno }}.{{ det?.branchcode }}: {{ det.brname }}</span>&nbsp;{{ det.description }}</td>
												<td class="text-center">{{ det.qtyonhand }}</td>
												<td>{{ det.units }}</td>
												<td class="text-right">{{ det.unitcost | currency }}</td>
												<td class="text-right">{{ det.itemtotal | currency}}</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
