<ng-container *ngIf="paymentForm">
	<form [formGroup]="paymentForm" (ngSubmit)="addPayment()" class="text-dark border-1">
		<div class="row m-0 p-0">
			<div class="col-md-2 text-right text-dark m-0 p-0 d-none">
					<div class="ml-auto" *ngIf="bankaccounts && !pos">
						<mat-form-field appearance="outline">
							<mat-label>Bank Account</mat-label>
							<mat-select formControlName="bankaccount">
								<mat-option *ngFor="let method of bankaccounts" [value]="method.accountcode">
									{{method.bankaccountname }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="ml-auto " >
					</div>
			</div>
			<div class="col-12 col-md-7 m-0 p-0">
				<div class="card">
					<h1 class="card-header text-center border-{{color}}">
						<span *ngIf="getBalance() < 0 && change_due < 0" class="text-success">CHANGE DUE {{ getBalance() | abs | currency }}</span>
						<span *ngIf="getBalance() < 0 && change_due >= 0" class="text-danger">OVER PAYMENT {{ getBalance() | abs | currency }}</span>
						<span *ngIf="getBalance() > 0 " class="text-danger">DUE {{ getBalance() | abs | currency }}</span>
					</h1>
					<div class="card-body">
						<table class="ml-auto mr-0 table table-totals w-100 " *ngIf="totals.length">
							<thead></thead>
							<tbody>
								<tr *ngFor="let t of totals">
									<td class="text-left dotted"><b>{{ t.title }}:</b></td>
									<td class="text-right dotted"> {{ t.text | currency}}</td>
								</tr>
								<tr>
									<th class="border-bottom"><b>PAYMENTS</b></th>
									<th class="border-bottom"></th>
								</tr>

								<ng-container *ngIf="!payments || !payments.length">
									<td class="border-bottom"></td>
									<td class="border-bottom text-right"><b>NO PAYMENTS</b></td>
								</ng-container>

								<tr *ngFor="let pay of payments">
									<td class="text-right dotted">
										<mat-icon-button color="accent" class="mt-4 ml-1" (click)="removePayment(pay.payment_id)">
											<mat-icon color="accent" >cancel</mat-icon>
										</mat-icon-button>
										<b>{{ pay.payment_type }}</b> <span *ngIf="pay.options" class="d-inline">{{lastFour(pay.options.number) }} </span>

									</td>
									<td class="text-right dotted">{{ pay.amount | currency }}</td>
								</tr>
							</tbody>
						</table>
						<div class="item-box">
							<table class="ml-auto mr-0 table table-totals w-100 table-sticky">
								<tbody>
									<tr>
										<th class="normal-semilarge">ITEMS</th>
										<th class="normal-semilarge text-right">QTY@PRICE</th>
										<th class="normal-semilarge text-right">T</th>
									</tr>
									<tr *ngFor="let i of items">
										<td class="text-left dotted normal"><b>{{ i.name }}:</b></td>
										<td class="text-right dotted normal"> {{ i.quantity }}&nbsp;@&nbsp;{{ i.price | currency}}</td>
										<td class="text-right dotted normal">
											<span *ngIf="i.taxtotal != 0"><b>T</b></span>
											<span *ngIf="i.taxtotal == 0"></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-5 ">
				<ng-container *ngIf="paymentForm">
					<div class="row m-0 p-0">
						<div class="col-md-12">
							<h3 class="mt-2 mb-2 text-center"><b>Payment Method</b></h3>
							<h5 class="text-center">
								<mat-slide-toggle
									class="text-center ml-auto mr-auto"
									[formControl]="refund"
									(click)="makeNegativePayment()"
									*ngIf="getBalance() < 0 ">
									REFUND
								</mat-slide-toggle>
							</h5>
							<ng-container *ngIf="paymentmethods && paymentmethods.length">
								<div class="ml-auto mr-auto col-12 g-primary">
									<mat-button-toggle-group #charge="matButtonToggleGroup" aria-label="Charge" (change)="setChargeType($event)" formControlName="type" color="accent">
										<ng-container *ngFor="let method of paymentmethods.slice(0, 5)">
											<mat-button-toggle [value]="method.paymentname" [ngClass]="method.paymentname === charge.value ? 'text-light btn-success btn-strong-text' : 'text-dark btn-light btn-strong-text'" *ngIf="(pos && method.pos_enabled ==='1') || !pos">{{ method.paymentname }}</mat-button-toggle>
										</ng-container>
									</mat-button-toggle-group>
								</div>

								<div class="ml-auto mr-auto col-12 g-primary" *ngIf="paymentmethods.length > 5">
									<mat-button-toggle-group #charge="matButtonToggleGroup" aria-label="Charge" (change)="setChargeType($event)" formControlName="type" color="accent">
										<ng-container *ngFor="let method of paymentmethods.slice(5)">
											<mat-button-toggle [value]="method.paymentname" [ngClass]="method.paymentname === charge.value ? 'text-light btn-success btn-strong-text' : 'text-dark btn-light btn-strong-text'" *ngIf="(pos && method.pos_enabled ==='1') || !pos">{{ method.paymentname }}</mat-button-toggle>
										</ng-container>
									</mat-button-toggle-group>
								</div>
							</ng-container>
							<!-- <mat-form-field appearance="outline" class="pay-panel" *ngIf="pos">
								<mat-label>Payment Type</mat-label>
								<mat-select formControlName="type" (selectionChange)="updatePaymentType($event, false)" panelClass="pay-panel">
									<mat-option [value]="''">SELECT A PAYMENT TYPE</mat-option>
									<ng-container *ngFor="let method of paymentmethods">
										<mat-option *ngIf="( (pos && method.pos_enabled )|| !pos) == 1" [value]="method.paymentname">
											{{method.paymentname }}
										</mat-option>
									</ng-container>
								</mat-select>
							</mat-form-field> -->


							<ng-container *ngIf="preselected_payment && loadingcards">
								<div class="text-center" class="col-md-12">
									<h5 class="text-danger">Pre Selected Payment Loading Profile</h5>
									<span>
										<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</span>
								</div>
							</ng-container>
							<ng-container *ngIf="card_required">
							  <div class="text-center col-md-12 mt-2">
								<span *ngIf="loadingcards" class="spinner-container">
								  <svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								  </svg>
								</span>
								<mat-form-field appearance="outline" *ngIf="profile_cards.length" class="mat-form-field-custom">
								  <mat-label>Charge Credit Card</mat-label>
								  <mat-select formControlName="card_selected" (selectionChange)="changeCard($event.value)">
									<mat-option [value]="false">DO NOT CHARGE</mat-option>
									<mat-option *ngFor="let method of profile_cards" [value]="method" [ngClass]="preselected_payment.card_id == method.card_id ? 'text-danger' : ''">
									  {{ method.number }} {{ method.expiry_month }}/{{ method.expiry_year }}
									  <span *ngIf="preselected_payment.card_id == method.card_id">Requested</span>
									</mat-option>
								  </mat-select>
								</mat-form-field>
							  </div>
							</ng-container>


						</div>

					</div>
					<div class="rainbowboard mt-0 mb-0">
						<mat-form-field appearance="outline" class="amount-text">
							<mat-label>Amount</mat-label>
							<span matPrefix class="mat-icon-large mb-2 mt-0" (click)="paymentForm.get('amount').setValue(0)" *ngIf="getBalance() != 0 "><mat-icon>clear</mat-icon></span>
							<input type="text" #amountId class="text-center mt-0 pt-0 amount-text" placeholder="Amount" aria-label="Amount" formControlName="amount"  matInput  />
						</mat-form-field>
						<div class="col-md-12" *ngIf="addOnRequired()">
							<mat-form-field appearance="fill">
								<mat-label>Reference</mat-label>
								<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" formControlName="reference" matInput appSelectOnFocus />
							</mat-form-field>
						</div>
						<div class="rainbowboard-inner">
							<div class="key">
								<div class="rainbow-content" (click)="runKey(7)">7</div>
							</div>
							<div class="key">
								<div class="rainbow-content" (click)="runKey(8)">8</div>
							</div>
							<div class="key">
								<div class="rainbow-content" (click)="runKey(9)">9</div>
							</div>
						</div>
						<div class="rainbowboard-inner">
							<div class="key">
								<div class="rainbow-content" (click)="runKey(4)">4</div>
							</div>
							<div class="key">
								<div class="rainbow-content" (click)="runKey(5)">5</div>
							</div>
							<div class="key">
								<div class="rainbow-content" (click)="runKey(6)">6</div>
							</div>
						</div>
						<div class="rainbowboard-inner">
							<div class="key">
								<div class="rainbow-content" (click)="runKey(1)">1</div>
							</div>
							<div class="key">
								<div class="rainbow-content" (click)="runKey(2)">2</div>
							</div>
							<div class="key">
								<div class="rainbow-content" (click)="runKey(3)">3</div>
							</div>
						</div>
						<div class="rainbowboard-inner">
							<div class="key" (click)="runKey('0')">
								<div class="rainbow-content">0</div>
							</div>
							<div class="key" (click)="runKey('.')">
								<div class="rainbow-content">.</div>
							</div>
							<div class="key" (click)="runKey('backspace')">
								<div class="rainbow-content">
									<mat-icon>keyboard_backspace</mat-icon>
								</div>
							</div>
							<div class="key" (click)="runKey('minus')" *ngIf="getBalance() < 0 ">
								<div class="rainbow-content minus" >
									<span *ngIf="!isNegative()">-</span>
									<span *ngIf="isNegative()">+</span>
								</div>
							</div>
							<div class="key" (click)="runKey('add')">
								<div class="rainbow-content add">
									ADD
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</form>
</ng-container>
