import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DispatchService } from 'app/services/dispatch.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';

@Component({
	selector: 'app-dispatch-driver-unload',
	templateUrl: './dispatch-driver-unload.component.html',
	styleUrls: ['./dispatch-driver-unload.component.scss']
})
export class DispatchDriverUnloadComponent implements OnInit {
	color: 'blue';
	returnedTrucks: any = [];
	truckForm: any = false;
	showDelivered = false;
	formReady: boolean;
	barcodeForm: any;

	constructor(private fb: FormBuilder, public dispatchService: DispatchService, private searchService: GlobalSearchService) { }

	ngOnInit(): void {
		this.loadReturnedTrucks()
	}

	loadReturnedTrucks() {
		this.dispatchService.getReturnedTrucks().subscribe((res: any)=>{
			this.returnedTrucks = res.map((entry)=>{
				var truck = entry.truck;
				var refused_and_credits = entry.details.filter((i)=>{ return i.status_id == 7 || i.type == 11});
				entry.details['loading'] = false;

				if(refused_and_credits.length > 0){
					return {
						...truck,
						details: refused_and_credits,
					};
				}
			}).filter(out=> out)
		})
		this.setForms()
	}

	setTruckForm() {
		this.truckForm = this.fb.group({
			'truckref': new FormControl('')
		})
	}

	setBarcodeForm(){
		this.barcodeForm = this.fb.group({
			'barcoderef': new FormControl('')
		})
	}

	setForms(){
		this.setTruckForm();
		this.setBarcodeForm();
		this.formReady = true;
	}

	removePick(da: any, event: any) {
		const request = {
				item: {
					stockid: da.stkcode,
					orderlineno: da.orderlineno,
					bin: da.bin
				},
				dispatch: {
					orderno: da.orderno,
					ordertype: da.type
				},
				validateByCode: true,
				barcode:event.target.value
			}

		if (da.loaded > 0) {
			this.dispatchService.unloadItemFromTruck(request).subscribe(results => {
				if (results.success) {
					da.loaded -= 1;
				}else{
					da['error'] = 'Scan did not match expected SKU';
					setTimeout(()=>{
						da.error = false;
					}, 5000);
				}

			})
		}
	}

	unloadCredit(da, event){
		const request = {}
		//is that credit brought into the building @ another point ? oc?
		//how to track loaded amount on truck if loaded amount isn't recorder? creditfreeze equivalent?

	}




}
