import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { OmsService } from '../../services/oms.service';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

declare const google: any;

@Component({
	selector: 'app-open-orders',
	templateUrl: './open-orders.component.html',
	styleUrls: ['./open-orders.component.scss'],
})
export class OpenOrdersComponent implements OnInit {

	CHAT_ROOM = 'OrderBoard';

	@ViewChild('purchaseOrderDetails') purchaseOrderDetailsRef: ElementRef;
	@ViewChild('orderStatusView') orderStatusViewRef: ElementRef;
	@ViewChild('popTemplate') popTemplateRef: ElementRef;


	@ViewChild('mapDiv') mapDivEle: ElementRef;

	@Input() customerdata: any = false;
	@Input() item: any = false;
	@Input() orderstatus: any = false;
	@Output() cart_updated = new EventEmitter < string > ();
	@Output() cancel = new EventEmitter < boolean > ();
	@Output() orderloaded = new EventEmitter < any > ();
	@Output() orderinvoice = new EventEmitter < any > ();
	@Output() reloadCustomer = new EventEmitter < any > ();

	snoozeForm: UntypedFormGroup;

	searchInput = new UntypedFormControl('');
	statusSearch = new UntypedFormControl('');
	order_status_edit = new UntypedFormControl('');
	searchInputItems = new UntypedFormControl('');
	defaultlocation = new UntypedFormControl('');
	shipvia = new UntypedFormControl('');
	podetails: any = false;
	openorders: any = [];
	customertrans: any = [];
	filteredItems: any = [];
	map: any = false;
	updated_map: any = false;
	markers: any = [false];
	pathlines: any = false;
	isMerging: boolean = false;
	map_animation: any = google.maps.Animation.DROP;
	fetch_subscribtion: any = false;
	headercolumns: string[] = [
		'orderno',
		'statusname',
		'pickprinted',
		//'deliverydate',
		'datecreated',
		//'phoneno',
		'deliverto',
		//'deladd1',
		'ponumber',
		'haspo',
		'shipvia',
		'notes',
		'comments',
		//'transno',

		// 'email',
		//'subtotal',


		'takenby',
		'total',
		'void',

	];

	orderupdates: any = [];
	openOrderData: any = [];
	allopenOrderData: any = [];
	config: any = [];
	pickinglink = '';
	invoicelink = '';
	orderdetails: any = [];
	locations: any = [];

	itemsearchresults: any = [];
	itemsearch = false;
	order_statuses: any = false;
	user: any = false;
	ref_location: any = false;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	snoozeorder;
	snoozetime;

	selectedOrderSource: string = '';

	numberofdays = 1;
	issnoozed = false
	initaldate = new Date();
	shipvias: any = [];
	issalesman: any = false;
	isPosUser: any = false;
	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	orderSources = [
		{ value: '1', viewValue: 'Backoffice' },
		{ value: '2', viewValue: 'POS' },
		{ value: '3', viewValue: 'Salesman' },
		{ value: '4', viewValue: 'Online' }
	];

	constructor(private usersService: UsersService, private omsService: OmsService, private formBuilder: UntypedFormBuilder, public purchasingService: PurchasingService, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private location: Location, private globalsService: GlobalsService, private modalService: NgbModal) {

		this.config = this.globalsService.getApiConfig();

		this.pickinglink = this.config.apiServer.baseUrl + this.config.apiServer.pickingLink;
		this.invoicelink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;
		//change the suers location selected

		this.globalSearchService.shipvia.subscribe(r => {
			this.shipvias = r;
		});

		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isPosUser = true;
			}
		});

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.loadData();
					this.ref_location = false;
				}
			}

			this.user = results
			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}


			if (this.user.user.issalesman) {
				this.editsallowed = this.config.salesmanAllowed
				this.issalesman = true;
				this.headercolumns = [
					'orderno',
					'statusname',
					'pickprinted',
					'datecreated',
					'deliverto',
					'ponumber',
					'shipvia',
					'notes',
					'comments',
					'takenby',
					'total',

				];
			}

		});
	}

	ngOnInit(): void {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
			if (results) {

				this.defaultlocation.setValue(this.user.user.defaultlocation.loccode)
				this.ordersService.getOrderStatuses().subscribe(r => {
					this.order_statuses = r;
				})
				if (!this.orderstatus) {
					this.loadData();
				}
			}
		});
		this.snoozeForm = this.formBuilder.group({
			message: ['', Validators.required],
			initials: ['', Validators.required],
			//numberofdays: [1],
			//datepicked: [''],
			// ['', Validators.required],
		});

	}

	filterWarehouse(input: any) {
		this.loadData();
	}

	noNewLines(input: any) {
		if (input) {
			return input.replace(/[^\x20-\x7E]/gmi, ' ').trim().replace(/\\n/g, ' ');
		}

		return input;
	}

	getShipVia(idin: any) {

		const filtered = this.shipvias.filter((r: any) => {
			return r.shipper_id === idin;
		})[0];

		const name = (filtered) ? filtered.shippername : 'N/A';

		return name;
	}

	setSnoozeOrder(orderno) {
		this.snoozeorder = orderno;

		const orderdata = this.allopenOrderData.filter(r => {
			return r.orderno = orderno;
		})[0];

		this.issnoozed = (orderdata.snoozed == '1') ? true : false;
		this.modalService.open(this.popTemplateRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {
			this.snoozeorder = false;
		}, (reason) => {

		});
	}

	setSnooze(event: any) {
		this.numberofdays = parseInt(event.target.value);
		this.dateNumberChanged();
	}

	dateNumberChanged() {

		const subject = new Date();
		subject.setDate(subject.getDate());

		const until = new Date();
		until.setDate(until.getDate() + this.numberofdays);

		this.initaldate = new Date(until);
	}

	dateChanged(event) {
		const now = new Date();
		now.setDate(now.getDate());
		const until = new Date(event.value);
		until.setDate(until.getDate());

		const difference = (until.getTime() - now.getTime())
		const days = difference / (1000 * 60 * 60 * 24);
		this.numberofdays = Math.ceil(days);
		this.initaldate = new Date(event.value)
	}

	removeSnooze(orderno: any) {
		const orderdata = this.allopenOrderData.filter(r => {
			return r.orderno = orderno;
		})[0];

		const data = {
			orderno: orderno,
			days: this.numberofdays,
			inititals: this.snoozeForm.controls['initials'].value,
			reason: this.snoozeForm.controls['message'].value,
			user: this.user.user
		};


		this.omsService.sendSnoozeRemove({ data, roomName: this.CHAT_ROOM }, cb => {});

		this.snoozeForm = this.formBuilder.group({
			message: ['', Validators.required],
			initials: ['', Validators.required],
		});

		setTimeout(() => {
			this.loadData();
		}, 500);

	}

	voidPreAuth(orderno: any) {

		let payload = {
			orderno: orderno
		}

		this.ordersService.voidPreAuth(payload).subscribe(r => {

		})
	}

	snooze(orderno: any) {

		const orderdata = this.allopenOrderData.filter(r => {
			return r.orderno = orderno;
		})[0];

		// if(this.snoozeForm.status == "INVALID" && orderdata.snoozed != '1') {
		// 	return false;
		// }

		const data = {
			orderno: orderno,
			days: this.numberofdays,
			inititals: this.snoozeForm.controls['initials'].value,
			reason: this.snoozeForm.controls['message'].value,
			user: this.user.user
		};


		this.omsService.sendSnoozeUpdate({ data, roomName: this.CHAT_ROOM }, cb => {

		});

		// orderdata.snoozed = '1';
		// orderdata.snoozed_details = {
		// 	initials: data.inititals,
		// 	comments: data.reason,
		// }

		this.snoozeForm = this.formBuilder.group({
			message: ['', Validators.required],
			initials: ['', Validators.required],
			//numberofdays: [1],
			//datepicked: [''],
			// ['', Validators.required],
		});


		setTimeout(() => {
			this.loadData();
		}, 500);
		this.snoozeorder = false;
		this.issnoozed = false;
		this.modalService.dismissAll();
	}

	saveStatusUpdate(tran: any) {

		const data = {
			trans: tran,
			type: this.order_status_edit.value,
			user: this.user,
		}

		this.ordersService.updateOrderStatus(data).subscribe((r: any) => {
			if (r.success) {
				tran.edit = false;
				const status = this.order_statuses.filter(s => {
					return s.order_status_id === data.type;
				})[0];
				tran.statusname = status.name;
				tran.orderstatusid = data.type;
				this.order_status_edit.reset();

				this.omsService.sendOrderCheckUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});

			}
		})
	}

	viewStatus(tran: any) {
		this.ordersService.getOrderStatusView(tran.orderno).subscribe((order: any) => {
			this.orderdetails = order

			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {

			}, (reason) => {

			});

			setTimeout(() => {
				if (this.orderdetails.dispatch_details.nextlast) {
					this.drawMap();
				}
			}, 500);
		});
	}

	openModal(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	}

	viewPurchaseOrder(orderno: any) {
		this.purchasingService.getPurchaseOrder(orderno).subscribe((order: any) => {
			this.podetails = order
			this.modalService.open(this.purchaseOrderDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});
		});
	}

	search() {

		this.itemsearch = false;
		const value = this.searchInput.value;
		if (value && value != '') {
			this.openorders = this.globalSearchService.filterItem(this.openOrderData, value, 'orderno,transno,ponumber,debtorno,deliverto,deliverydate,stockid,description,placedfrom');
			this.openorders = new MatTableDataSource(this.openorders);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;


		} else {
			this.openOrderData = this.allopenOrderData
			this.openorders = new MatTableDataSource(this.allopenOrderData);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		}

		this.globalSearchService.blurInputElement(this.searchInput);

	}

	toggleEdit(tran: any) {
		if (!tran.edit) {
			tran.edit = true;
			this.order_status_edit.setValue(tran.orderstatusid);
		} else {
			tran.edit = false;
		}
	}

	filterShipVia() {
		const value = this.shipvia.value;

		if (value != '' && value != '0') {
			this.itemsearch = true;
			const searchdata = [];
			const searchresults = this.globalSearchService.filterItem(this.allopenOrderData, value, 'shipvia');
			this.openorders = searchresults;
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;

		} else {
			this.openOrderData = this.allopenOrderData
			this.openorders = new MatTableDataSource(this.allopenOrderData);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		}
	}

	filterItems() {

		const value = this.statusSearch.value;

		if (value != '' && value != '0') {
			this.itemsearch = true;
			const searchdata = [];
			// this.openOrderData.forEach((item: any) => {
			// 	searchdata.push(item.details)
			// });

			const searchresults = this.globalSearchService.filterItem(this.allopenOrderData, value, 'orderstatusid');
			this.openorders = searchresults;
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;

		} else {
			this.openOrderData = this.allopenOrderData
			this.openorders = new MatTableDataSource(this.allopenOrderData);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		}

		if (this.selectedOrderSource !== '') {
			this.filterItemSource()
		}

	}

	filterItemSource() {

		const source = this.selectedOrderSource;
		let result = this.openOrderData;

		if (source) {
			result = result.filter(order => order.details.placedfrom === source);
		}


		this.openorders = new MatTableDataSource(result);
		this.openorders.sort = this.sort;
		this.openorders.paginator = this.paginator;
	}


	searchItems() {
		const value = this.searchInputItems.value;

		if (value && value != '') {
			this.itemsearch = true;
			const searchdata = [];
			this.openOrderData.forEach((item: any) => {
				searchdata.push(item.details)
			});

			const searchresults = this.globalSearchService.filterItem(searchdata, value, 'orderno,narrative,stkcode,description,ordervalue');
			const mapped = searchresults.map(i => i.orderno);

			this.itemsearchresults = searchresults;

			this.openorders = this.openOrderData.filter(o => {
				return mapped.includes(o.orderno);
			});

			this.openorders = new MatTableDataSource(this.openorders);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;

		} else {
			this.openOrderData = this.allopenOrderData
			this.openorders = new MatTableDataSource(this.allopenOrderData);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		}

		this.globalSearchService.blurInputElement(this.searchInputItems);
	}

	onInput(event: any) {
		if (this.openOrderData) {

			const result = this.globalSearchService.filterItem(this.openOrderData, event, 'orderno,transno,ponumber,debtorno,deliverto,deliverydate');

			this.openorders = new MatTableDataSource(result);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;

			if (this.user.user.department === '3') {
				if (this.openorders.length === 1) {
					this.invoiceOrder(this.openorders[0].orderno);
				}
			}
		}
	}

	viewCustomer(debtorno: string) {
		if(!this.isPosUser) {
			this.router.navigate(['/customers/view/' + debtorno]);
		}
	}

	viewInvoicedOrder(orderno: any, content: any) {
		this.ordersService.getInvoicedOrder(orderno).subscribe((order: any) => {
			this.orderdetails = order


			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {});

			//wait for element to appear
			if (order && order.dispatch_details.status_id === '29') {
				setTimeout(() => {
					this.drawMap();
				}, 500);
			}

		});
	}

	viewOrder(orderno: any, content: any) {

		this.ordersService.getOrder(orderno).subscribe((order: any) => {
			this.orderdetails = order


			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {});

			//wait for element to appear
			if (order && order.dispatch_details.status_id === '29') {
				setTimeout(() => {
					this.drawMap();
				}, 500);
			}

		});
	}

	invoiceOrder(orderno: any) {
		this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {})
		this.orderinvoice.emit(orderno);
	}

	loadOrder(tran: any) {
		let orderno = tran.orderno;
		switch (tran.orderstatusid) {
			//preorder
			case "3":
				this.ordersService.loadPreOrder(orderno, this.customerdata.customer).subscribe((result: any) => {
					this.orderinvoice.emit(false);
					this.orderloaded.emit(result);
				});
				break;
			default:
				this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {
					this.orderinvoice.emit(false);
					this.orderloaded.emit(result);
				});
				break;
		}

	}

	loadData() {

		let search = '';

		if (this.fetch_subscribtion) {
			this.fetch_subscribtion.unsubscribe();
		}

		if (this.customerdata && search === '') {

			if (this.customerdata.customer) {
				search = this.customerdata.customer.debtorno;
			}

			if (this.customerdata.debtorno) {
				search = this.customerdata.debtorno;
			}
		}


		if (this.item) {
			const search = {
				item: this.item,
				loccode: this.defaultlocation.value
			}

			//todo rework this
			this.fetch_subscribtion = this.ordersService.getItemOrderSearch(search).subscribe(async (results: any) => {
				this.openOrderData = results;
				this.allopenOrderData = results;
				if (results) {
					this.openorders = new MatTableDataSource(results);
					this.openorders.sort = this.sort;
					this.openorders.paginator = this.paginator;
				}
			});

		} else {

			search += '&loccode=' + this.defaultlocation.value;
			this.fetch_subscribtion = this.ordersService.getCustomerOpenOrders(search).subscribe(async (results: any) => {
				this.openOrderData = results;
				this.allopenOrderData = results;
				if (results) {
					this.openorders = new MatTableDataSource(results);
					this.openorders.sort = this.sort;
					this.openorders.paginator = this.paginator;
				}
			});
		}

	}

	ngOnChanges(changes: any) {

		if (changes.orderstatus) {
			if (this.fetch_subscribtion) {
				this.fetch_subscribtion.unsubscribe();
			}

			const search = {
				loccode: this.defaultlocation.value,
				debtorno: false,
				filtered: changes.orderstatus.currentValue,
			}

			this.fetch_subscribtion = this.ordersService.getCustomerOpenOrdersFiltered(search).subscribe((results: any) => {

				this.openorders = new MatTableDataSource(results);
				this.openorders.sort = this.sort;
				this.openorders.paginator = this.paginator;
				this.openOrderData = results;
				this.allopenOrderData = results;
			});
		}
	}

	cancelOrder(orderno: any) {
		this.ordersService.cancelOrder(orderno).subscribe(async (results: any) => {
			this.cancel.emit(true);
			this.reloadCustomer.emit(true);
			this.cart_updated.emit(results);


			const data = {
				cancel: true,
				editing: false,
				neworder: false,
				orderno: orderno,
				user: this.user
			};

			this.omsService.sendOrderCheckUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.omsService.sendOrderPickUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.loadData()
		})
	}

	setDelivered(orderno: any) {
		this.ordersService.setDelivered({ orderno: orderno }).subscribe(async (results: any) => {
			this.loadData()
		})
	}

	updatePick(orderno: any) {
		//needs callback to have customer only
		this.ordersService.updateOrderToPicked({ orderno: orderno }).subscribe(async (results: any) => {

			const data = {
				user: this.user
			};

			this.omsService.sendOrderCheckUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.loadData()
		})
	}

	drawMap() {

		this.globalSearchService.hideSideBar();
		const coordinates = [];
		const columndata = this.orderdetails.dispatch_details;

		const home = { lat: columndata.clat, lng: columndata.clng }
		const truckpos = { lat: columndata.lastlat, lng: columndata.lastlng }
		const counter = 0;
		const trucklat = new google.maps.LatLng(truckpos);
		const lines = [trucklat];
		lines.push(home)

		const regex = /<br\s*[\/]?>/gi;
		//<br />
		const name = columndata.address.replace(regex, '\n');
		const m = new google.maps.Marker({
			position: home,
			title: name,
			optimized: true,
			animation: google.maps.Animation.DROP,
			label: {
				text: name,
				color: '#000',
				fontSize: '14px',
				fontWeight: 'bold'
			}
		}, );

		coordinates.push(m);

		const c = new google.maps.Marker({
			position: trucklat,
			title: columndata.truck_name,
			optimized: true,
			animation: google.maps.Animation.DROP,
			label: {
				text: columndata.truck_name,
				color: '#000',
				fontSize: '14px',
				fontWeight: 'bold'
			}
		});

		coordinates.push(c);

		const mapOptions = {
			zoom: 11,
			center: home,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				'featureType': 'water',
				'stylers': [{
					'saturation': 43
				}, {
					'lightness': -11
				}, {
					'hue': '#0088ff'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'geometry.fill',
				'stylers': [{
					'hue': '#ff0000'
				}, {
					'saturation': -100
				}, {
					'lightness': 99
				}]
			}, {
				'featureType': 'road',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#808080'
				}, {
					'lightness': 54
				}]
			}, {
				'featureType': 'landscape.man_made',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ece2d9'
				}]
			}, {
				'featureType': 'poi.park',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ccdca1'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'labels.text.fill',
				'stylers': [{
					'color': '#767676'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'labels.text.stroke',
				'stylers': [{
					'color': '#ffffff'
				}]
			}, {
				'featureType': 'poi',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'landscape.natural',
				'elementType': 'geometry.fill',
				'stylers': [{
					'visibility': 'on'
				}, {
					'color': '#b8cb93'
				}]
			}, {
				'featureType': 'poi.park',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.sports_complex',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.medical',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.business',
				'stylers': [{
					'visibility': 'simplified'
				}]
			}]

		};

		this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

		coordinates.forEach((item: any) => {
			item.setMap(this.map);
		});

		const lineSymbol = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		const pathTo = new google.maps.Polyline({
			path: lines,
			geodesic: true,
			strokeColor: '#F1575A',
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: [{
				icon: lineSymbol,
				offset: '100%'
			}],
		});

		pathTo.setMap(this.map);
	}

	getTotalQuantity(): number {
		if (!this.podetails || !this.podetails.polines) return 0;
		return this.podetails.polines.reduce((total, item) => parseFloat(total) + parseFloat(item.quantityord), 0);
	}

	getTotalValue(): number {
		if (!this.podetails || !this.podetails.polines) return 0;
		return this.podetails.polines.reduce((total, item) => parseFloat(total) + (parseFloat(item.unitprice) * parseFloat(item.quantityord)), 0);
	}

	async copyToClipboard(text: string): Promise < void > {
		try {
			await navigator.clipboard.writeText(text);
		} catch (err) {}
	}

	back(): void {
		this.location.back()
	}

	announceSortChange(event: any) {
		// alert(JSON.stringify(this.sort));
		// alert(JSON.stringify(event))

	}
	//merge stuff
	mergeList: any[] = []; // List to hold selected orders

	mergeOrders(): void {
		if (this.mergeList.length === 0) {
			return;
		}

		// Group orders by debtorno
		const groupedOrders = this.mergeList.reduce((acc, order) => {
			if (!acc[order.debtorno]) {
				acc[order.debtorno] = [];
			}
			acc[order.debtorno].push(order.orderno);
			return acc;
		}, {} as {
			[key: string]: string[]
		});

		const payload = Object.keys(groupedOrders).map(debtorno => ({
			debtorno,
			orders: groupedOrders[debtorno]
		}));

		this.ordersService.mergeOrders(payload).subscribe((r: any) => {
			if (r.success) {
				this.mergeList = [];
				this.loadData();
			} else {
				this.globalSearchService.showNotification(r.error, 'danger', 'bottom', 'right');
			}
		});
	}

	isSelected(order: any): boolean {
		return this.mergeList.some(o => o.orderno === order.orderno);
	}

	toggleOrderSelection(event: any, order: any): void {
		if (event.checked) {
			if (!this.isSelected(order)) {
				this.mergeList.push(order);
			}
		} else {
			this.mergeList = this.mergeList.filter(o => o.orderno !== order.orderno);
		}
	}

	isAllSelected(): boolean {
		return this.openorders.length && this.mergeList.length === this.openorders.length;
	}

	isSomeSelected(): boolean {
		return this.mergeList.length > 0 && !this.isAllSelected();
	}

	selectAll(event: any): void {
		if (event.checked) {
			this.mergeList = [...this.openorders];
		} else {
			this.mergeList = [];
		}
	}

	trackByOrderNo(index: number, order: any): any {
		return order.orderno;
	}

	toggleMerging(): void {
		this.isMerging = !this.isMerging;
		if (!this.isMerging) {
			this.mergeList = [];
		}
	}
}