import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UsersService } from '../../services/users.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common'
import { ROUTES, AdminLayoutRoutes } from '../../classes/routes'
import * as jq from 'jquery';
import { BehaviorSubject } from 'rxjs';

import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { SocketService } from '../../services/socket.service'
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';


declare let $: any;

export interface Access {
	title: string;
	authorized: boolean;
	color: ThemePalette;
	children ? : Access[];
}

export interface Modify {
	title: string;
	authorized: boolean;
	color ? : ThemePalette;
	children ? : Modify[];
}

@Component({
	selector: 'app-user-groups-edit',
	templateUrl: './user-groups-edit.component.html',
	styleUrls: ['./user-groups-edit.component.scss'],
	animations: [
		trigger('fadeInAnimation', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('500ms', style({ opacity: 1 })),
			]),
		]),
		trigger('listItemAnimation', [
			transition('* => *', [
				query('.mat-list-item', [
					style({ opacity: 0, transform: 'translateY(-20px)' }),
					stagger('100ms', [
						animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
					]),
				]),
			]),
		]),
	],
})

export class UserGroupsEditComponent implements OnInit {

	title = 'User Group';
	group: any = []
	yesno: any = [
		{ value: '0', name: 'No' },
		{ value: '1', name: 'Yes' }
	];

	placedfrom = [{
			name: 'BackOffice',
			value: 1
		},
		{
			name: 'Point Of Sale',
			value: 2
		},
		{
			name: 'Street',
			value: 3
		},
		{
			name: 'Ecom',
			value: 4
		},
		{
			name: 'Retail',
			value: 5
		}
	]


	ROUTES = ROUTES;
	userForm: UntypedFormGroup;
	groupname = new UntypedFormControl('');
	saleschannel = new UntypedFormControl(1);
	group_id: any = '';
	permissions: any = [];
	allAccess = false;
	ipRestricted = false;
	allowedIPs: string[] = [];
	task: any = [];
	access: any = [];
	labelPosition: 'before' | 'after' = 'after';
	access_list: any = [];
	CHAT_ROOM = 'Users';
	color = 'blue';
	group_access: any = [];
	all_reports: any = [];
	group_reports: any = [];
	active_reports: any = []
	base_group_reports: any = [];
	all_reports_unfiltered: any = [];
	allUsers: any = [];
	groupUsers: any = [];
	selecteduser: any = false;
	userDetails: any = false;
	filteredAllUsers: any[] = [];
	filteredGroupUsers: any[] = [];
	searchAllTerm: string = '';
	searchGroupTerm: string = '';

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private userService: UsersService, public router: Router, private location: Location, public globalSearchService: GlobalSearchService, private globalsService: GlobalsService, public socketService: SocketService) {
		const accesss = [];
		this.color = this.globalSearchService.getColor();

		ROUTES.forEach(l => {
			const link = {
				title: l.title,
				authorized: false,
				children: [],
				path: l.path,
				group: l.anchor,
			};

			if (l.children) {
				l.children.forEach(child => {
					const childauth = {
						title: child.title,
						authorized: false,
						path: child.path,
						children: [],
						group: l.anchor,
					};
					link.children.push(childauth);
				});
			}
			this.access_list.push(link);
		})

	}


	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.group_id = params['id'];
			this.loadData();
		});
	}

	// Function to add a new allowed IP
	addAllowedIPs(ipListString: string) {
		const ips = ipListString.split(',').map(ip => ip.trim());
		this.allowedIPs.push(...ips);
	}

	// Function to remove an allowed IP at a given index
	removeAllowedIP(index: number) {
		this.allowedIPs.splice(index, 1);
	}


	loadData() {
		this.userService.getUserGroup(this.group_id).subscribe((group: any) => {
			this.groupname.setValue(group.name);
			this.saleschannel.setValue(group.sales_channel)

			this.ipRestricted = group.ip_restricted

			if (group.allowed_ips && !group.allowed_ips.length && group.allowed_ips != '') {
				this.allowedIPs = []
			} else {
				this.allowedIPs = group.allowed_ips;
			}
			//

			const auth = this.access_list.map(bc => bc.path);

			this.group_access = group.access
			this.access_list.forEach((a, index) => {
				this.access_list[index].authorized = this.allowed(a.path)
				if (a.children) {
					a.children.forEach(child => {
						const allowed = this.allowed(child.path)
						child.authorized = allowed
						if (allowed) {
							//parent access permitted if child is
							this.access_list[index].authorized = true;
						}
					})
				}
			})
			this.group = group;

			this.getUsers();

			this.loadReports();
		});
	}

	getUsers() {
		this.userService.getUsers().subscribe((users: any) => {
			this.groupUsers = users.filter((user: any) => user.user_group === this.group_id);
			this.allUsers = users.filter((user: any) => user.user_group !== this.group_id);
			this.filteredAllUsers = [...this.allUsers];
			this.filteredGroupUsers = [...this.groupUsers];
		});
	}



	filterAllUsers() {

		if (this.searchAllTerm) {
			this.filteredAllUsers = this.allUsers.filter(user =>
				(user?.realname?.toLowerCase() ?? '').includes(this.searchAllTerm.toLowerCase()) ||
				(user?.userid?.toString() ?? '').includes(this.searchAllTerm) ||
				(user?.phone ?? '').includes(this.searchAllTerm) ||
				(user?.email?.toLowerCase() ?? '').includes(this.searchAllTerm.toLowerCase()) ||
				(user?.defaultlocation?.toLowerCase() ?? '').includes(this.searchAllTerm.toLowerCase())
			);

		} else {
			this.filteredAllUsers = [...this.allUsers];
		}
	}

	filterGroupUsers() {
		if (this.searchGroupTerm) {
			this.filteredGroupUsers = this.groupUsers.filter(user =>
				(user?.realname?.toLowerCase() ?? '').includes(this.searchGroupTerm.toLowerCase()) ||
				(user?.userid?.toString() ?? '').includes(this.searchGroupTerm) ||
				(user?.phone ?? '').includes(this.searchGroupTerm) ||
				(user?.email?.toLowerCase() ?? '').includes(this.searchGroupTerm.toLowerCase()) ||
				(user?.defaultlocation?.toLowerCase() ?? '').includes(this.searchGroupTerm.toLowerCase())
			);
		} else {
			this.filteredGroupUsers = [...this.groupUsers];
		}
	}

	addUserToGroup(user: any) {
		let data = {
			userid: user.userid,
			group_id: this.group_id
		}

		this.userService.addUserToGroup(data).subscribe((result: any) => {

			if (result.success) {
				this.getUsers();
			}
		});
	}

	removeUserFromGroup(user: any) {
		// Implement logic to remove user from group
		let data = {
			userid: user.userid,
			group_id: this.group_id
		}
		this.userService.removeUserFromGroup(data).subscribe((result: any) => {
			if (result.success) {
				this.getUsers();
			}
		});
	}



	// Function to toggle the addition/removal of a report from the group
	toggleReport(report: any): void {
		if (this.hasLocation(report.title)) {
			this.removeUserReport(report);
		} else {
			this.addUserReport(report);
		}
	}


	loadReports() {
		this.userService.getAllReports(this.group_id).subscribe((all_reports: any) => {
			this.all_reports = this.sortAndGroupReports(all_reports);
			this.all_reports_unfiltered = all_reports;
			this.userService.getGroupReports(this.group_id).subscribe((group_reports: any) => {
				this.group_reports = this.sortAndGroupReports(group_reports);
				this.base_group_reports = group_reports;
				this.active_reports = group_reports;
			});
		})
	}
	addUserReport(report: any) {
		const payload = {
			groupid: this.group_id,
			report: report.title,
		}
		this.userService.addReport(payload).subscribe((all_reports: any) => {
			this.loadReports();
		});
	}

	filterReports(searchTerm: string) {
		const results = this.all_reports_unfiltered.filter((report: any) => {
			return report.title.toLowerCase().includes(searchTerm.toLowerCase());
		});

		if (results && results.length) {
			this.all_reports = this.sortAndGroupReports(results);
		} else {
			this.all_reports = this.sortAndGroupReports(this.all_reports_unfiltered);
		}
	}

	removeUserReport(report: any) {
		const payload = {
			groupid: this.group_id,
			report: report.title,
		}
		this.userService.removeReport(payload).subscribe((all_reports: any) => {
			this.loadReports();
		});
	}

	hasLocation(title: any) {
		//this.user becomes false when modalService closes
		let access = []

		if (this.all_reports) {
			access = this.active_reports.filter(loc => {
				return loc.title == title;
			})
		};

		return (access.length > 0) ? true : false;
	}

	allowed(path: any): boolean {
		const a = this.group_access.indexOf(path);
		return (a < 0) ? false : true;
	}

	back(): void {
		this.location.back()
	}

	updateAccessComplete(parent: any) {
		const parent_index = this.access_list.indexOf(parent);

		if (!this.access_list[parent_index].children.length) {
			return;
		}

		this.access_list[parent_index].authorized = this.access_list[parent_index].children.length && this.access_list[parent_index].children.every(t => t.authorized);

		//this.allAccessComplete[parent_index] = this.access_list[parent_index].children != null && this.access_list[parent_index].children.every(t => t.authorized);
	}

	updateAccess(completed: boolean, parent: any, child: any) {
		const parent_index = this.access_list.indexOf(parent);
		const child_index = this.access_list[parent_index].children.indexOf(child)

		if (!completed) {
			this.allAccess = false;
		}
		if (this.access_list[parent_index].children[child_index] == null) {
			return false;
		}

		this.access_list[parent_index].children[child_index].authorized = completed
		return this.access_list[parent_index].children[child_index].authorized

	}

	setChildAccessAuthorized(completed: boolean, parent: any) {

		const index = this.access_list.indexOf(parent);
		if (!completed) {
			this.allAccess = false;
		}

		if (this.access_list[index].children == null) {
			return;
		}

		this.access_list[index].authorized = (this.access_list[index].authorized) ? false : true;
		this.access_list[index].children.forEach(t => (t.authorized = completed));
	}

	setAllAccess(completed: boolean) {
		this.allAccess = completed

		this.access_list.forEach(t => {
			t.authorized = completed;
			t.children.forEach((child: any) => (child.authorized = completed))
		});
	}

	saveAccess() {
		let data = { access: this.access_list, group: this.group, name: this.groupname.value, groupid: this.group_id, ip_restrict: this.ipRestricted, allowedips: this.allowedIPs, sales_channel: this.saleschannel.value }
		this.userService.updateGroup(data).subscribe((result: any) => {
			data = result;
			//this.socketService.sendUserGroupUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.loadData();
			this.globalSearchService.showNotification('Modifed', 'success', 'bottom', 'left')
		})
	}


	sortAndGroupReports(reports: any[]): any[] {
		// Sort the reports alphabetically by title
		reports.sort((a, b) => a.title.localeCompare(b.title));

		// Group the reports by type
		const groupedReports = {};
		reports.forEach(report => {
			if (!groupedReports[report.type]) {
				groupedReports[report.type] = [];
			}
			groupedReports[report.type].push(report);
		});

		// Convert the groupedReports object into an array of objects
		const result = [];
		for (const type in groupedReports) {
			if (groupedReports.hasOwnProperty(type)) {
				result.push({ type, reports: groupedReports[type] });
			}
		}

		return result;
	}

	trackByFn(index: number, item: any): number {
		return item.title;
	}

	trackByFnTwo(index: number, item: any): number {
		return item.type;
	}
}