import { Component, OnInit } from '@angular/core';
import { RouteInfo, ROUTES } from '../../classes/routes';
import { UsersService } from '../../services/users.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { ReportsService } from '../../services/reports.service';
import { RuntimeConfigLoaderModule } from 'runtime-config-loader';
import { Router } from '@angular/router';
//import * as $ from "jquery";
declare const $: any;

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
	menuItems: any[];
	searchTerm: any;
	userData: any;
	searchResults: any;
	user: any;
	messagecount: any;
	navstate: boolean;
	reports: any = [];
	anchors: any = [];
	access: any = ['/customers'];
	always_allowed = ['/logout', '/auth/permission'];
	config: any = false;
	datasets: any = false;
	memoizedPathAllowed: any = [];
	constructor(public reportsService: ReportsService, private userService: UsersService, private globalSearchService: GlobalSearchService, public router: Router) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}

	ngOnInit() {
		this.memoizedPathAllowed = this.memoize(this.pathAllowed.bind(this));
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;

			this.reportsService.getReports().subscribe(reports => {
				this.reports = reports
			})

			if (this.user.user.department == '1') {
				this.globalSearchService.datasets.subscribe(r => {
					this.datasets = r;
				});
			}

			this.menuItems = ROUTES.filter(menuItem => {
				//PARENTS ONLY CHILDREN SEPERATE
				if (this.always_allowed.includes(menuItem.path)) {
					return true;
				}

				if (this.user.user.fullaccess < 8 && this.user.user.access_permissions) {
					return this.user.user.access_permissions.includes(menuItem.path);
				} else {
					return true;
				}
			});
		})

		this.globalSearchService.messages.subscribe((results: any) => {
			this.messagecount = results;
		});

		this.globalSearchService.searchTerm.subscribe((newValue: string) => {
			this.searchTerm = newValue;
		});

		this.globalSearchService.userData.subscribe((newValue: string) => {
			this.userData = newValue;
		});

		this.globalSearchService.searchResults.subscribe((newValue: string) => {
			this.searchResults = newValue;
		});
	}

	isTitleInReports(title: string): boolean {
		return this.reports.some(report => report.title === title);
	}


	setDataSet(event: any) {
		const newDataSet = event;


		localStorage.removeItem('app');
		this.globalSearchService.setPackage('app', event.config.env.package)

		const newpackaege = this.globalSearchService.getSessionItem('app');
		this.globalSearchService.configsubscription.next(event.config);
		this.router.navigate(['/']);
		this.config = event.config;
		//window.location.reload();

		RuntimeConfigLoaderModule.forRoot({ configUrl: './assets/config/config.' + event.config + '.json' })
		//this.globalSearchService.reload()

	}

	anchorReports(anchor: any) {
		if (!this.reports) {
			return [];
		}
		
		return this.reports.filter((list: any) => list.group === anchor);
	}

	pathAllowed(path: string): boolean {
		const { fullaccess, access_permissions } = this.user.user;

		if (fullaccess < 8) {
			return access_permissions ? access_permissions.includes(path) : false;
		}

		return true;
	}

	memoize(fn: Function) {
		const cache = new Map();
		return function(...args: any[]) {
			const key = JSON.stringify(args);
			if (cache.has(key)) {
				return cache.get(key);
			}
			const result = fn.apply(this, args);
			cache.set(key, result);
			return result;
		};
	}


	navTo(link) {
		if (link != '') {
			this.router.navigate([link, { skipLocationChange: true }]);
		}
	}
	getNavState() {
		return this.userService.getNavState();
	}

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false;
		}
		return true;
	};

	collapse(event: any) {
		const children = document.querySelector('.children');
		children.classList.remove('show');
	}

	resetCount() {
		this.messagecount = 0;
	}

	onInput(event: any) {
		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
	}

	selectParentMarker(element: string, secondref: string) {
		const el = $(element);

		$('.children').removeClass('show');
		$('.nav .nav-item').removeClass('active');

		if (el) {
			const sidebar = $('.sidebar-wrapper');
			if (el.offset()) {
				let count = el.offset().top - sidebar.offset().top - sidebar.scrollTop();
				if (count < 0) {
					count = el.offset().top - sidebar.offset().top + sidebar.scrollTop();
				}

				$(secondref + ' .children').addClass('show');
				$(element).addClass('active');
				sidebar.animate({
					scrollTop: count
				}, 100);
			} else {
				$(secondref + ' .children').addClass('show');
			}
		}

	}

	selectMarker(element: string, secondref: string) {
		const el = $(element);
		if (el) {
			const sidebar = $('.sidebar-wrapper');
			if (el.offset()) {
				let count = el.offset().top - sidebar.offset().top - sidebar.scrollTop();
				if (count < 0) {
					count = el.offset().top - sidebar.offset().top + sidebar.scrollTop();
				}
				$('.children').removeClass('show');
				$(secondref).addClass('show');
				sidebar.animate({
					scrollTop: count
				}, 100);
			} else {
				$('.children').removeClass('show');
				$(secondref).addClass('show');

			}
		}

	}

}
