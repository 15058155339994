import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { CartProduct, Product } from '../classes/orders';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class OrdersService {
	key = 'UET6DFMPAXW7BKCB';
	items: Product[] = [];
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {

		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe((result: any) => {
			this.config = result;
		});
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getCheckedOrders(data: any) {
		const method = 'oms/picking/checked';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public completeOrderCheck(data: any) {
		const method = 'oms/picking/checkedcomplete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public voidPreAuth(data: any) {
		const method = 'orders/voidtrans';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public updateOrderStatus(data: any) {
		const method = 'orders/updatestatus';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getOrderStatuses() {
		const method = 'orders/getstatuses';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public refreshCart(custno: any, branchcode: any){
		const method = 'orders/refreshcartitems&customer=' + custno + '&branch=' + branchcode;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getRefused() {
		const method = 'oms/dashboard/refused';
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public repriceCart(data: any) {
		const method = 'cart/reprice';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public unflagCart(debtorno: any, branchcode: any) {
		const method = 'cart/unflagcredit&debtorno=' + debtorno + '&branchcode=' + branchcode;
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public flagCart(debtorno: any, branchcode: any) {
		const method = 'cart/flagcredit&debtorno=' + debtorno + '&branchcode=' + branchcode;
		return this.http.post(this.setEndPoint(method), method, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public changeOrderCustomer(customer: any) {
		const method = 'cart/changecustomer';
		return this.http.post(this.setEndPoint(method), customer, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public setSalesOrderPoOptions(data: any) {
		const method = 'cart/updatesalespoitem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public setDelivered(data: any) {
		const method = 'oms/picking/setdelivered';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public updateOrderToPicked(data: any) {
		const method = 'oms/picking/updatepicked';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public setPoOptions(data: any) {
		const method = 'cart/updatepoitem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getPurchDataFromLine(data: any) {
		const method = 'cart/poitem';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getPoRequired(data: any) {
		const method = 'cart/poitems';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getDealCheck(data:any){
		const method = 'cart/dealcheck';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}
	public applyDeal(data:any){
		const method = 'cart/applydeal';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getItemSearchCount(data: any) {
		const method = 'orders/items/getsearchcount';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public swapItem(data: any) {
		const method = 'orders/items/swap';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getItemSearch(data: any) {
		const method = 'orders/items/search';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getSingleItemSearch(data: any) {
		const method = 'orders/items/searchsingle';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getSimpleSingleItemSearch(data: any) {
		const method = 'orders/items/searchsinglesimple';
		return this.http.post(this.setEndPoint(method), data , this.httpOptions).pipe(
			 catchError(this.handleError(method))
		 );
	}

	public getItemFilter(data: any) {
		const method = 'orders/items/search&filter=true';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}


	public getReceipt(transno: any) {
		const method = 'documents/receipt/posreceipt&id=' + transno;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrderReceipt(transno: any) {
		const method = 'documents/receipt/posreceiptorder&id=' + transno;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}


	public printOrderReciept(data:any) {
		const method = 'documents/receipt/posreceipt&display=print';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public emailOrderReciept(data:any) {
		const method = 'documents/receipt/posreceipt&display=email';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getEmployee(data: any) {
		const method = 'users/getuserbyemp';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCustomer(customerid: any, branchcode: any) {
		const method = 'customers/orderentry&debtorno=' + customerid + '&branchcode=' + branchcode;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrderHistory(orderno: any) {
		const method = 'orders/history&oid=' + orderno;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public loadSalesOrder(orderno: any, reverse:any=false) {
		const method = 'orders/loadorder&oid=' + orderno + '&reverse='+reverse;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public loadPreOrder(orderno: any, customer: any, reverse:any=false) {
		let data = {
			oid: orderno,
			reverse: reverse,
			customer: customer
		};
		var method = 'orders/loadpreorder';
		const headers = this.setHeaders();
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public reloadItemsWIthForcedTax(data:any) {
		const method = 'cart/forcetax';
		const headers = this.setHeaders();
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public reloadItemsWIthForcedTaxZero(data:any) {
		const method = 'cart/forcetaxzero';
		const headers = this.setHeaders();
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public loadSalesOrderPos(orderno: any, reverse:any=false) {
		const method = 'orders/loadorder&oid=' + orderno + '&reverse='+reverse;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public cancelQuote(orderno: any) {
		const method = 'orders/quotes/cancel';
		return this.http.post(this.setEndPoint(method), { orderno: orderno }, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public loadQuote(orderno: any) {
		const method = 'orders/quotes/load';
		return this.http.post(this.setEndPoint(method), { orderno: orderno }, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public cancelOrder(orderno: any, ) {
		const method = 'orders/cancel';
		return this.http.post(this.setEndPoint(method), { orderno: orderno }, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getTransactionById(id: any) {
		const method = 'orders/gettransaction&id=' + id;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });

	}

	public getOpenQuoteList(data: any) {
		const method = 'orders/getquotes';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getOpenQuotes() {
		const method = 'orders/getquotes';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getQuote(orderno: any) {
		const method = 'orders/getquote';
		return this.http.post(this.setEndPoint(method), { orderno: orderno }, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getQuotes(debtorno: any, branchcode: any) {
		const method = 'orders/getquotes&debtorno=' + debtorno + '&branchcode=' + branchcode;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}


	public saveQuote(customer: any, items: any, totals: any, user: any, order: any) {
		const method = 'orders/savequote';
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user, order: order }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public getPick(orderno: any) {
		const url = 'https://cleve.qbcsys.com/PDFPickingListSinglePortrait.php?TransNo=2135133&pre=1&se=1';
	}

	public downloadReport(): Observable < any > {
		// Create url
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			}),
		}

		const url = 'https://cleve.qbcsys.com/?route=salesman/documents/picking&key=9NPZ5AJ4FNNT9C7Y&id=2135137';
		const body = { orderno: 2135133 };

		return this.http.post(url, body, {
			responseType: 'blob',
			headers: new HttpHeaders().append('Content-Type', 'application/json')
		});
	}

	public getOrders() {
		const method = 'orders';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getLocationOrders(loccode: any) {
		const method = 'orders&loccode=' + loccode;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrderSearch(keywords: any) {
		const method = 'orders/search&keywords=' + keywords;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}


	public getOrderInqSearch(data: any) {
		const method = 'orders/inqsearch';
		const headers = this.setHeaders();
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getCustomerOpenOrders(debtorno: any) {
		const method = 'orders&debtorno=' + debtorno;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getCustomerOpenOrdersFiltered(data: any) {
		const method = 'orders';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}


	public getItemOrderSearch(data: any) {
		const method = 'orders';

		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}



	public getTotals(customer: any, branchcode: any, freight: any = 0.00) {
		const method = 'cart/gettotals';
		return this.http.post(this.setEndPoint(method), { debtorno: customer, branchcode: branchcode, freight: freight }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public getPosTotals(customer: any, branchcode: any, freight: any = 0.00, location: any = '', forcetax: any = false) {
		const method = 'cart/gettotals&pos=true';
		return this.http.post(this.setEndPoint(method), { debtorno: customer, branchcode: branchcode, freight: freight, location: location, forcetax:forcetax }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public getDispatches() {
		const method = 'orders/dispatches';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrderInvoice(oid) {
		const method = 'orders/open/invoice&oid=' + oid;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrder(oid) {
		const method = 'orders/open&oid=' + oid;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getInvoicedOrder(oid) {
		const method = 'orders/open&invoiced=true&oid=' + oid;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public mergeOrders( data : any ) {
		const method = 'orders/merge';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getLocs() {
		const method = 'orders/getlocs';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public productlinesByMfg() {
		const method = 'orders/productlinesbymfg';
		return this.http.post(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public productlineReport(data) {
		const method = 'orders/productlinereport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getOrderReservedItems(oid, stockid) {
		const method = 'orders/reservedorderitems&oid=' + oid + '&stockid=' + stockid;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrderStatusView(oid) {
		const method = 'orders/viewopen&oid=' + oid;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public setOrderItems(items) {
		this.items = items;
	}

	public addPromo(promo: any, customer: string, branchcode: string) {
		const method = 'cart/addmm';
		return this.http.post(this.setEndPoint(method), { promo: promo, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, promo))
		);
	}
	public addEdu(edu: any, customer: string, branchcode: string) {
		const method = 'cart/addedu';
		return this.http.post(this.setEndPoint(method), { edu: edu, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, edu))
		);
	}

	public addPromoPos(promo: any, customer: string, branchcode: string) {
		const method = 'cart/addmm&pos=true';
		return this.http.post(this.setEndPoint(method), { promo: promo, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, promo))
		);
	}

	public addMultipleToOrder(products: any, customer: string, branchcode: string) {
		const method = 'cart/addmultiple';
		return this.http.post(this.setEndPoint(method), { items: products, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, products))
		);
	}

	public addMultipleToOrderPos(products: any, customer: string, branchcode: string, location: any ='', forcetax: any = false) {
		const method = 'cart/addmultiple&pos=true';
		return this.http.post(this.setEndPoint(method), { items: products, customer: customer, branchcode: branchcode , location : location, forcetax: forcetax }, this.httpOptions).pipe(
			catchError(this.handleError(method, products))
		);
	}


	public addNonStock(data: any) {
		const method = 'cart/addnonstock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public addNonStockPos(data: any) {
		const method = 'cart/addnonstock';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public addToOrder(product: any, customer: string, branchcode: string) {
		this.items.push(product);
		const method = 'cart/add';
		return this.http.post(this.setEndPoint(method), { item: product, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}



	public updateOrder(product) {
		const method = 'cart/update';
		return this.http.post(this.setEndPoint(method), product, this.httpOptions).pipe(
			catchError(this.handleError(method, product))
		);
	}

	public updateCartItemsDiscount(items: any) {

		const method = 'cart/updateitemsdiscounts';
		return this.http.post(this.setEndPoint(method), items, this.httpOptions).pipe(
			catchError(this.handleError(method, items))
		);
	}

	public updateCartItemsDiscountPos(items: any) {

		const method = 'cart/updateitemsdiscounts&pos=true';
		return this.http.post(this.setEndPoint(method), items, this.httpOptions).pipe(
			catchError(this.handleError(method, items))
		);
	}


	public getMstOnOpenSalesOrders() {
		const method = 'orders/openmstsalesorders';
		return this.http.post(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getMstOnOpenPurchOrders(data: any) {
		const method = 'orders/openmstpurchorders';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public clearOrder(customerno: any, branchcode: any) {
		this.items = [];
		const method = 'cart/clear';
		return this.http.post(this.setEndPoint(method), { customerno: customerno, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, customerno))
		);
	}

	public createInvoice(order: any) {
		const method = 'orders/invoice';
		return this.http.post(this.setEndPoint(method), order, this.httpOptions).pipe(
			catchError(this.handleError(method, order))
		);
	}

	public updateSalesOrder(customer: any, items: any, totals: any, order: any, user: any, adjustment: any = null) {
		const method = 'cart/updateorder';
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, order: order, user: user, adjustment: adjustment }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public createOrderAndInvoice(customer: any, items: any, totals: any, user: any) {
		const method = 'cart/complete&invoice=true';

		const data = { customer: customer, items: items, totals: totals, user: user };
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public createOrderAndInvoicePos(customer: any, items: any, totals: any, user: any, employee: any) {
		const method = 'cart/complete&invoice=true&pos=true';

		const data = { customer: customer, items: items, totals: totals, user: user , employee: employee };
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public createPosInvoice(customer: any, items: any, totals: any, user: any, employee: any) {
		const method = 'cart/complete&pos=true&invoice=false';

		const data = { customer: customer, items: items, totals: totals, user: user };
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user , employee: employee }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}
	public createOrder(customer: any, items: any, totals: any, user: any, adjustment: any = null) {
		const method = 'cart/complete';

		const data = { customer: customer, items: items, totals: totals, user: user, adjustment: adjustment };
		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user, adjustment: adjustment }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public createPreOrder(customer: any, items: any, totals: any, user: any, preorder:any, adjustment: any = null) {
		var method = 'cart/complete';

		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user, preorder: preorder, adjustment: adjustment }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public createPreOrderPos(customer: any, items: any, totals: any, user: any, preorder:any, adjustment: any = null) {
		var method = 'cart/complete&pos=true';

		return this.http.post(this.setEndPoint(method), { customer: customer, items: items, totals: totals, user: user, preorder: preorder, adjustment: adjustment }, this.httpOptions).pipe(
			catchError(this.handleError(method, customer))
		);
	}

	public removeFromOrder(cart_id, customer, branchcode: any) {
		const method = 'cart/remove';
		return this.http.post(this.setEndPoint(method), { cart_id: cart_id, customer: customer, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, cart_id))
		);
	}

	public removeFromOrderPos(cart_id, customer, branchcode: any, location: any = '', forcetax: any = false) {
		const method = 'cart/remove';
		return this.http.post(this.setEndPoint(method), { cart_id: cart_id, customer: customer, branchcode: branchcode, location: location, forcetax: forcetax }, this.httpOptions).pipe(
			catchError(this.handleError(method, cart_id))
		);
	}

	public getRunPick(column: any) {
		const method = 'oms/runpick&column=' + column;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getCards() {
		const method = 'oms/cards';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getFilteredPicks(type: any) {
		const method = 'oms/picking&type=' + type;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getNeedPicked(loccode: any = '', orderno:any = '') {
		let method = 'oms/picking/orders';
		if(loccode != '') {
			method += '&loccode='+loccode;
		}

		if(orderno != '') {
			method += '&orderno='+orderno;
		}

		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getPicked(loccode: any = '', orderno:any = '') {
		let method = 'oms/picking/picked';
		if(loccode != '') {
			method += '&loccode='+loccode;
		}

		if(orderno != '') {
			method += '&orderno='+orderno;
		}

		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public completeOrderPick(data: any) {
		const method = 'oms/picking/pickcomplete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method))
		);
	}

	public getLocationPicks(loccode: any) {
		const method = 'oms/picking&loccode='+loccode;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getPicks() {
		const method = 'oms/picking';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getOrderItems(search, branchcode: any) {
		const method = 'cart';
		this.getCart(search, branchcode).subscribe((results: Product[]) => {
			this.items = results;
			return results
		});;

		return this.items;
	}

	public getCart(search: any, branchcode: any) {
		const method = 'cart';
		return this.http.post(this.setEndPoint(method), { customerno: search, branchcode: branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public getPosCart(search: any, branchcode: any, location: any = '', forcetax: any = false) {
		const method = 'cart&pos=true';
		return this.http.post(this.setEndPoint(method), { customerno: search, branchcode: branchcode, location: location, forcetax: forcetax }, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public updateCartShipto(search: any, original_branchcode: any, new_branchcode: any) {
		const method = 'cart/updatecartshipto';
		return this.http.post(this.setEndPoint(method), { customerno: search, original_branchcode: original_branchcode, new_branchcode: new_branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public updateCreditCartShipto(search: any, original_branchcode: any, new_branchcode: any) {
		const method = 'cart/updatecreditcartshipto';
		return this.http.post(this.setEndPoint(method), { customerno: search, original_branchcode: original_branchcode, new_branchcode: new_branchcode }, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}
	public addCartPayment(data: any) {
		const method = 'cart/addpayment';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getCartPayments(cart: any) {
		const method = 'cart/getpayments';
		return this.http.post(this.setEndPoint(method), cart, this.httpOptions).pipe(
			catchError(this.handleError(method, cart))
		);
	}
	public removeCartPayment(id: any) {
		const method = 'cart/removepayment';
		return this.http.post(this.setEndPoint(method), { id: id }, this.httpOptions).pipe(
			catchError(this.handleError(method, id))
		);
	}

	public getStockProducts(search) {
		const method = 'inventory&stock=true';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public getProducts(search) {
		const method = 'inventory';
		return this.http.post(this.setEndPoint(method), search, this.httpOptions).pipe(
			catchError(this.handleError(method, search))
		);
	}

	public getOrderList(oid) {
		const method = 'orders/getdetails&oid=' + oid;
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public getPicking() {
		const method = 'oms/fillable';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}

	public setPicking(data) {
		const method = 'orders/pick';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public uploadPo(data){
		const method = 'purchasing/order/upload';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}

	public getADRS() {
		const method = 'orders/adrs/pending';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}
	public getADRSReject() {
		const method = 'orders/adrs/rejected';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}
	public getADRSSent() {
		const method = 'orders/adrs/sent';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}
	public getADRSCount() {
		const method = 'orders/adrs/adrscount';
		const headers = this.setHeaders();
		return this.http.get(this.setEndPoint(method) ,{ headers:headers });
	}
	public sendADRS(data) {
		const method = 'orders/adrs/send';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public removeADRS(data) {
		const method = 'orders/adrs/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public resendADRS(data) {
		const method = 'orders/adrs/resend';
		return this.http.post(this.setEndPoint(method), { orderno: data }, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public clearADRS(data) {
		const method = 'orders/adrs/resetadrs';
		return this.http.post(this.setEndPoint(method), { orderno: data }, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	public getOII(data) {
		const method = 'orders/items/oii';
		return this.http.post(this.setEndPoint(method),data, this.httpOptions).pipe(
			catchError(this.handleError(method, data))
		);
	}
	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {

		if (this.config.apiCredentials.clientId) {

			const apiKeyUserId = this.config.apiCredentials.clientId + '_' + this.user.user.userid;

			//this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + apiKeyUserId);
		}

		// if (this.config.userId) {
		// 	this.httpOptions.headers = this.httpOptions.headers.set('UserId',  this.user.user.userid);
		// }

		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();

	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private setHeaders(): HttpHeaders {
		// Create HttpHeaders object
		let headers = new HttpHeaders();

		// Check if clientId exists in config
		if (this.config.apiCredentials.clientId) {
			// Set Authorization header
			headers = headers.set('Authorization', 'Bearer ' + this.config.apiCredentials.clientId+'_'+this.user.user.userid);
		}

		// Check if thi exists in config
		// if (this.user && this.user.user) {
		// 	// Set UserId header
		// 	headers = headers.set('UserId', this.user.user.userid);
		// }

		return headers;
	  }

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/login';
			}

			return error;
	  };
	}


}
