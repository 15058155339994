<ng-template #stockAdjust let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Stock Adjustment For {{ itemdetails.item.stockid }} - {{ itemdetails.item.description }} </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<mat-tab-group>
			<mat-tab label="Inventory Adjustment">
				<app-inventory-adjustment [stockid]="itemdetails.item.stockid" [cost]="itemdetails.item.actualcost" (closeadj)="closeAdj()" (newitemdata)="updateItem($event)"></app-inventory-adjustment>
			</mat-tab>
			<mat-tab label="Min/Max">
				<app-edit-min-max [item]="itemdetails.item" (updated)="closeAdj()"></app-edit-min-max>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #productDescription let-modal>
	<div class="modal-header">
		<ng-container *ngIf="descriptionHtmlContent.type == 'description'">
			<h4 class="modal-title">Item Description Editor</h4>
		</ng-container>
		<ng-container *ngIf="descriptionHtmlContent.type == 'features'">
			<h4 class="modal-title">Item Features Editor</h4>
		</ng-container>
		<ng-container *ngIf="descriptionHtmlContent.type == 'benefits'">
			<h4 class="modal-title">Item Benefits Editor</h4>
		</ng-container>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<editor [init]="editorConfig" [(ngModel)]="descriptionHtmlContent.html"></editor>
	</div>
	<div class="modal-footer">
		<ng-container *ngIf="descriptionHtmlContent.type == 'description'">
			<button type="button" class="btn btn-outline-dark" onclick="this.blur()" (click)="modal.close('save description')">Save</button>
		</ng-container>
		<ng-container *ngIf="descriptionHtmlContent.type == 'features'">
			<button type="button" class="btn btn-outline-dark" onclick="this.blur()" (click)="modal.close('save features')">Save</button>
		</ng-container>
		<ng-container *ngIf="descriptionHtmlContent.type == 'benefits'">
			<button type="button" class="btn btn-outline-dark" onclick="this.blur()" (click)="modal.close('save benefits')">Save</button>
		</ng-container>
	</div>
</ng-template>


<div class="main-content">
	<div class="container-fluid" *ngIf="itemdetails">
		<div class="row mr-2 ml-2 ">
			<div class="col-6 text-left">
				<a (click)="stockid = itemdetails.prev;loadItem()">Previous Item</a>
			</div>
			<div class="col-6 text-right">
				<a (click)="stockid = itemdetails.next;loadItem()">Next Item</a>
			</div>
		</div>
		<div class="col-md-12 ">
			<div class="card" *ngIf="itemdetails.item">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title pointer"><i class="material-icons" (click)="back()">arrow_left</i>
						<span class="bold">
							<button mat-icon-button [cdkCopyToClipboard]="itemdetails.item.stockid">
								<small>
									<mat-icon>content_copy</mat-icon>
								</small>
							</button>

								<span>Inventory: {{ itemdetails.item.stockid }} - </span>
								<span [innerHTML]="itemdetails.item.description"></span>
						</span>

					</h4>
				</div>
				<div class="card-body  no-overflow">

					<app-inventory-search [stockid]="itemdetails.item.stockid"></app-inventory-search>
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" [selectedIndex]="selectedindex" (selectedIndexChange)="updateActiveIndex($event)">


						<mat-tab label="Information">
							<div class="row mt-2 p-0 m-0">
								<div class="card col-md-4 card-editor">
									<div class="card-header card-header-icon pointer card-header-rose">
										<div class="card-icon" (click)="toggleEditSettings()">
											<i class="material-icons">settings</i>
										</div>
										<h4 class="card-title  ">Setup:</h4>
									</div>
									<div class="card-body">
										<app-inventory-edit-setup *ngIf="editing.settings" [item]="itemdetails.item" (newitemdata)="updateItem($event)">
										</app-inventory-edit-setup>
										<ul class="entry-list p-0 m-0" *ngIf="!editing.settings">
											<li>
												<div class="spec-label bg-white">
													Description:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.description }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Brand
												</div>
												<div class="spec-value">
													{{ itemdetails.brand }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Category:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.categorydescription }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Product Line:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.lineid }} {{ itemdetails.item.linedesc }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Product Type:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.mbflag_dsp }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													GL Class:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.glclass }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Tax Category:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.taxcategory }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													BOM:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.hasbom }}
												</div>
											</li>
										</ul>

									</div>
								</div>

								<div class="card col-md-4 card-editor" [ngClass]="itemdetails.item.discontinued  == '1' ? 'border-danger border' : ''">
									<div class="card-header card-header-icon pointer card-header-success">
										<div class="card-icon" (click)="toggleEditExtra()">
											<i class="material-icons">line_weight</i>
										</div>
										<h4 class="card-title  ">Extra:</h4>
									</div>
									<div class="card-body ">
										<app-inventory-edit-extra [item]="itemdetails.item" [qoh]="itemdetails.total_qoh" (newitemdata)="updateItem($event)" *ngIf="editing.extra"></app-inventory-edit-extra>
										<ul class="entry-list p-0 m-0" *ngIf="!editing.extra">
											<li>
												<div class="spec-label bg-white">
													Created:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.created }}
												</div>
											</li>
											<li *ngIf="config.env.package == 'tires'">
												<div class="spec-label bg-white">
													Fee: (FET)
												</div>
												<div class="spec-value">
													<ng-container *ngIf="itemdetails.item.additionalfee">
														{{ itemdetails.item.additionalfee | currency }}
													</ng-container>
													<ng-container *ngIf="!itemdetails.item.additionalfee">
														$0.00
													</ng-container>
												</div>
											</li>

											<li>
												<div class="spec-label bg-white">
													*Barcode:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.barcode }}
												</div>
											</li>
											<li *ngIf="config.env.package != 'tires'">
												<div class="spec-label bg-white">
													PanSize:
												</div>
												<div class="spec-value">
													<ng-container *ngIf="itemdetails.pansize">
														{{ itemdetails.pansize}}
													</ng-container>
													<ng-container *ngIf="!itemdetails.pansize">
														N/A
													</ng-container>
												</div>
											</li>
											<!--<li>
												<div class="spec-label bg-white">
													EOQ:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.eoq }}
												</div>
											</li>-->
											<li>
												<div class="spec-label bg-white">
													Controlled:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.controlled_dsp }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Units:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.units }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													DWO:
												</div>
												<div class="spec-value" [ngClass]="itemdetails.item.dwo ? 'bg-warning' : ''">
													{{ itemdetails.item.dwo_dsp }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Discontinued:
												</div>
												<div class="spec-value" [ngClass]="itemdetails.item.discontinued ? 'bg-danger text-white' : ''">
													{{ itemdetails.item.discontinued_dsp }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Web Enabled:
												</div>
												<div class="spec-value" [ngClass]="!itemdetails.item.enabled ? 'bg-danger text-white' : ''">
													{{ itemdetails.item.enabled_dsp }}
												</div>
											</li>
											<li>
												<div class="spec-label bg-white">
													Commissionable:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.commissionable_dsp }}
												</div>
											</li>
											<li *ngIf="itemdetails.item.commissionable">
												<div class="spec-label bg-white">
													Commission Rate:
												</div>
												<div class="spec-value">
													{{ itemdetails.item.commpct }}%
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div class="card col-md-4 card-editor">
									<div class="card-header card-header-icon pointer card-header-danger">
										<div class="card-icon" (click)="toggleEditImages()">
											<i class="material-icons">image</i>
										</div>
										<h4 class="card-title  ">Images:</h4>
									</div>
									<div class="card-body text-center ">
										<app-inventory-edit-images [item]="itemdetails.item" (newitemdata)="updateItem($event)" *ngIf="editing.images"></app-inventory-edit-images>
										<div class="product-gallery" *ngIf="!editing.images">
											<div class="main-image-container">
												<button class="remove-button" (click)="removeMainImage()" *ngIf="itemdetails.item.image != '' && itemdetails.item.image != 'no_image.png'"></button>
												<div class="image-wrapper">
													<img [src]="itemdetails.item.full_size_image" class="img img-fluid img-responsive thumbnail item-image ml-auto mr-auto">
												</div>
											</div>
											<div class="thumbnail-container" *ngIf="itemdetails.item.images && itemdetails.item.images.length > 1">
												<ng-container *ngFor="let thu of itemdetails.item.images">
													<div class="thumbnail" (click)="changeMainImage(thu)">
														<div class="main-image-container">
															<div class="image-wrapper">
																<img [src]="thu.thumb" alt="{{thu.alt}}">
															</div>
														</div>
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
								<div class="card col-md-4 card-editor" [ngClass]="editing.prices ? 'col-md-4' : 'col-md-4'">
									<div class="card-header card-header-icon pointer card-header-warning">
										<div class="card-icon" (click)="toggleEditPrices()">
											<i class="material-icons">payment</i>
										</div>
										<h4 class="card-title  ">Prices:</h4>
									</div>
									<div class="card-body m-0 p-0 mt-3 ">
										<app-inventory-edit-prices [item]="itemdetails" (newitemdata)="updateItem($event)" *ngIf="editing.prices"></app-inventory-edit-prices>
										<ul class="entry-list" *ngIf="!editing.prices">
											<li *ngIf="config.plm">
												<b>MARKUP COST:</b> {{ itemdetails.item.actualcost | currency }}
											</li>
											<li *ngIf="itemdetails.item.special && itemdetails.item.special.prices" class="pink-color">
												<!-- todo not do this -->
												<b>ON SPECIAL</b>
												<!-- &nbsp;&nbsp;{{ getFirstPriceValue(itemdetails.item.special.prices) | currency  }} -->
											</li>
										</ul>
										<mat-divider></mat-divider>
										<table class="table table-fluid w-100 smaller-table">
											<thead>
												<tr>
													<th>Type</th>
													<th>Price</th>
													<th *ngIf="config.plm">PLM</th>
													<ng-container *ngIf="config.env.package !== 'tires'">
														<th>Start Date</th>
														<th>End Date</th>
														<th>Currency</th>
													</ng-container>
													<th>GP</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let p of itemdetails.allpricing" [ngClass]="getPriceForKey(p.typeabbrev) ? 'pink-border': ''">
													<td class="spec-label bg-white">{{ p.typeabbrev }} {{ p.sales_type }}</td>
													<td>
														<ng-container *ngIf="getPriceForKey(p.typeabbrev) as specialPrice; else originalPrice">
															<span class="original-price" title="Original Price">{{ p.price | currency }}</span>
															<span class="special-price">{{ specialPrice | currency }}</span>
														</ng-container>
														<ng-template #originalPrice>
															<span class="price">{{ p.price | currency }}</span>
														</ng-template>
													</td>
													<td *ngIf="config.plm">
														{{ getPlmMarkup(p.typeabbrev) }}
													</td>
													<ng-container *ngIf="config.env.package != 'tires'">
														<td>{{ formatDate(p.startdate) }}</td>
														<td>{{ formatDate(p.enddate) }}</td>
														<td>{{ p.currabrev }}</td>
													</ng-container>
													<td>{{ getGp(p.price, itemdetails.item.actualcost) }}%</td>
												</tr>
											</tbody>
										</table>
										<table class="table table-fluid w-100 smaller-table" *ngIf="itemdetails.price_break">
										<thead>
										<tr><th colspan="5" style="text-center">Qty Breaks</th></tr>
										<tr><th>Price Lv</th><th>Qty</th><th>Price</th><th>Start</th><th>End</th></tr>
										</thead>
										<tbody>
										<tr *ngFor="let pb of itemdetails.price_break">
										<td class="spec-label bg-white">{{pb.salestype}}</td><td>{{pb.quantitybreak}}</td><td>{{pb.price | currency }}</td>
										<td class="spec-label bg-white">{{ formatDate(pb.startdate) }}</td>
														<td>{{ formatDate(pb.enddate) }}</td>
										</tr>
										</tbody>
										</table>


										<!-- <ul class="entry-list" *ngIf="!editing.prices">
											<li *ngFor="let p of itemdetails.allpricing">
												<div class="spec-label bg-white">
													{{ p.typeabbrev }} {{ p.sales_type }}
												</div>
												<div class="spec-value">
													<span *ngIf="getSpecial(p.typeabbrev)" class="text-danger">
														{{ getSpecial(p.typeabbrev) | currency}}
													</span>
													<span *ngIf="!getSpecial(p.typeabbrev)">
														{{ p.price | currency}}
													</span>

													<ng-container *ngIf="!hasspecial">
													 <span class="pull-right" [ngClass]="{'text-danger': getGp(p.price, itemdetails.item.cost) < 0 }">
														 {{ getPlmMarkup(p.typeabbrev) }}
														 <i>(GP:{{ getGp(p.price, itemdetails.item.cost)}} %)</i></span>
													</ng-container>
												</div>
											</li>
										</ul> -->
									</div>
								</div>
								<div class="card col-md-4 card-editor">
									<div class="card-header card-header-icon pointer card-header-info">
										<div class="card-icon" (click)="toggleEditStock()">
											<i class="material-icons">category</i>
										</div>
										<h4 class="card-title  ">Inventory:</h4>
									</div>
									<div class="card-body ml-0 mr-0 pl-0 pr-0">


										<ul class="inventory-list p-0 m-0 item-bins" *ngIf="!editing.stock">
											<ng-container *ngFor="let item of getFilteredItemStock()">
												<li>
													<div class="inventory-spec-label dashed"><b>{{ item.locationname }}:</b></div>
													<div class="inventory-spec-value dashed">
														<b>{{ item.quantity }}</b>
													</div>
												</li>
												<ng-container *ngIf="itemdetails.bins">
													<ng-container *ngFor="let loc of itemdetails.bins[item.loccode]">
														<ng-container *ngIf="loc">
															<li *ngIf="loc.quantity != '0' || loc.defaultbin === '1'">
																<div class="inventory-spec-label bg-white">
																	<span *ngIf="loc.bin == ''"><i>N/A</i></span>
																	<span *ngIf="loc.bin != ''"><i>{{ loc.bin }}</i></span>
																</div>
																<div class="inventory-spec-value">
																	&nbsp;({{ loc.quantity }})
																</div>
															</li>
														</ng-container>
													</ng-container>
												</ng-container>
											</ng-container>

											<ng-container *ngIf="itemdetails.remote_inventory && itemdetails.remote_inventory.length">
												<mat-divider></mat-divider>
												<li *ngFor="let remote of itemdetails.remote_inventory">
													<div class="inventory-spec-label bg-white">{{ remote.feedlabel }}:</div>
													<div class="inventory-spec-value">{{ remote.qty }}</div>
												</li>
											</ng-container>
										</ul>



										<div class="table">
											<table class="table table-sm">
												<thead>
													<tr>
														<th class="text-center">MIN/MAX:</th>
														<th class="text-center">COMMITTED</th>
														<th class="text-center">ON ORDER</th>
													</tr>
												</thead>
												<tbody>
													<ng-container *ngIf="itemdetails.item.minmax">
														<tr>
															<td class="text-center">
																<span *ngIf="itemdetails.item.minmax.minimum">
																	{{ itemdetails.item.minmax.minimum }}
																</span>
																<span *ngIf="!itemdetails.item.minmax.minimum">
																	N/A
																</span>
																/
																<span *ngIf="itemdetails.item.minmax.maximum">
																	{{ itemdetails.item.minmax.maximum }}
																</span>
																<span *ngIf="!itemdetails.item.minmax.maximum">
																	N/A
																</span>
															</td>
															<td class="text-center">{{ itemdetails.itemdemand }}</td>
															<td class="text-center">{{ itemdetails.openpos }}</td>
														</tr>
													</ng-container>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div class="card col-md-4 card-editor" [ngClass]="editing.purchasing ? 'col-md-12' : 'col-md-4'">
									<div class="card-header card-header-icon pointer card-header-secondary">
										<div class="card-icon" (click)="toggleEditPurchasing()">
											<i class="material-icons">storefront</i>
										</div>
										<h4 class="card-title  ">Purchasing:</h4>
									</div>
									<div class="card-body ml-0 mr-0 pl-0 pr-0">
										<app-inventory-purchase-data *ngIf="editing.purchasing" [item]="itemdetails" (newitemdata)="updateItem($event)">
										</app-inventory-purchase-data>
										<div class="row" *ngIf="config.plm && user.user.viewcost">
											<ng-container *ngIf="itemdetails.total_qoh > 0">
												<mat-radio-group [formControl]="costingbase" aria-label="Costing Value" (change)="setActualCost($event.value)" class="mt-0 ml-auto mr-auto">
													<mat-radio-button inputId="icost" value="{{ itemdetails.item.cost }}">AVG COST &nbsp;</mat-radio-button>
													<mat-radio-button inputId="ilast" value="{{ itemdetails.item.lastpoprice }}">LAST COST&nbsp;</mat-radio-button>
													<mat-radio-button inputId="pprice" value="{{ itemdetails.primarypurchase.price }}">PURCHASE PRICE&nbsp;</mat-radio-button>
												</mat-radio-group>
											</ng-container>
										</div>
										<mat-divider></mat-divider>
										<ul class="purchasing-entry-list p-0 m-0 mt-2" *ngIf="!editing.purchasing">
											<ng-container *ngIf="user.user.viewcost">
												<li *ngIf="config.plm">
													<div class="purchasing-spec-label dark">
														<span [ngClass]="itemdetails.item.hasoveridemark ? 'badge badge-warning' : ''"><b>MARKUP COST:</b></span>
													</div>
													<div class="purchasing-spec-value dark">
														{{ itemdetails.item.actualcost | currency }}
													</div>
												</li>
												<li>
													<div class="purchasing-spec-label">
														AVG COST:
													</div>
													<div class="purchasing-spec-value" *ngIf="!isEditingAvgCost">
														{{ itemdetails.item.cost | currency }}
														<button mat-icon-button matTooltip="Edit Avg Cost" (click)="editAvgCost()" class="smaller pink-icon p-0 mt-0 mb-0">
															<mat-icon>edit</mat-icon>
														</button>
													</div>
													<div class="purchasing-spec-value editing-section" *ngIf="isEditingAvgCost">
														<input [(ngModel)]="editingAvgCostValue" class="form-control d-inline-block text-right editing-input" />
														<div class="editing-button-group">
															<button mat-icon-button matTooltip="Save" (click)="saveAvgCost()">
																<mat-icon>save</mat-icon>
															</button>
															<button mat-icon-button matTooltip="Cancel" (click)="cancelEditAvgCost()">
																<mat-icon>cancel</mat-icon>
															</button>
														</div>
													</div>

												</li>
												<li>
													<div class="purchasing-spec-label bg-white">
														LAST COST
													</div>
													<div class="purchasing-spec-value">
														<span *ngIf="itemdetails.lastpoprice">{{ itemdetails.lastpoprice | currency }}</span>
														<span *ngIf="!itemdetails.lastpoprice">N/A</span>
														<span *ngIf="itemdetails.lastpurch != 'N/A'"> ({{ itemdetails.lastpurchqty }} @ {{ itemdetails.lastpurch }} )</span>
													</div>
												</li>
												<li>
													<div class="purchasing-spec-label">
														PURCHASE PRICE:
													</div>
													<div class="purchasing-spec-value">
														{{ itemdetails.primarypurchase.price | currency }} {{ itemdetails.primarypurchase.suppliersuom }}
													</div>
												</li>
												<li>
													<div class="purchasing-spec-label bg-white">
														PRIMARY SUPPLIER:
													</div>
													<div class="purchasing-spec-value">
														{{ itemdetails.primarypurchase.supplierno }} : {{ itemdetails.primarypurchase.suppname }}
													</div>
												</li>
												<li>
													<div class="purchasing-spec-label">
														PREV AVG COST:
													</div>
													<div class="purchasing-spec-value">
														{{ itemdetails.item.lastcost | currency }}
													</div>
												</li>
											</ng-container>
											<li>
												<div class="purchasing-spec-label bg-white">
													LAST SALE
												</div>
												<div class="purchasing-spec-value">
													{{ itemdetails.lastordered }}
												</div>
											</li>
										</ul>


									</div>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row p-0 m-0">
								<div class="col-fmd-4">
									<button class="mt-3" mat-raised-button (click)="toggleAddingAttribute()" *ngIf="!user.user.issalesman">
										<span *ngIf="!addingattribute">Add Attribute</span>
										<span *ngIf="addingattribute">Cancel</span>
									</button>
								</div>
								<div class="ml-auto col-md-8 text-right">
									<mat-form-field appearance="outline" *ngIf="!addingattribute" class="text-right">
										<mat-label>Search Attributes</mat-label>
										<input type="text" class="text-right m-0 p-0" [formControl]="filterAttributes" matInput (input)="searchAttributes($event.target.value)" />
									</mat-form-field>
									<ng-container *ngIf="addingattribute">
										<form [formGroup]="attributeForm" *ngIf="attributeForm" (ngSubmit)="addAttribute()">
											<div class="card">
												<div class="card-body">
													<div class="row">
														<div class="col-md-5">
															<mat-form-field appearance="outline">
																<mat-label>Label</mat-label>
																<input type="text" formControlName="key" matInput placeholder="Attribute Label" />
															</mat-form-field>
														</div>
														<div class="col-md-5">
															<mat-form-field appearance="outline">
																<mat-label>Value</mat-label>
																<input type="text" formControlName="value" matInput placeholder="Attribute Value" />
															</mat-form-field>
														</div>
														<div class="col-md-2 text-left">
															<button mat-stroked-button class="mt-3">
																<mat-icon>save</mat-icon>
															</button>
														</div>
													</div>
												</div>
											</div>
										</form>
									</ng-container>

								</div>
								<div class="col-md-6">
									<ul class="entry-list" *ngFor="let a of filteredattributes; let i = index">

										<li *ngIf="!(i % 2)">


											<div class="spec-label bold"> {{ a.label }}</div>
											<div class="spec-value">
												<ng-container [ngSwitch]="a.type">
													<!-- select dropdown -->
													<div class="input-group frow" *ngSwitchCase="'select'">
														<mat-form-field appearance="outline" class="switch-select">
															<mat-label>{{ a.label }}</mat-label>
															<mat-select (selectionChange)="savevalue = $event.value; savekey = a.key" [value]="a.value">
																<mat-option *ngFor="let k of a.options" [value]="k.value">
																	{{ k.label }}
																</mat-option>
															</mat-select>
														</mat-form-field>

														<button mat-button class="btn btn-primary btn-sm save-button" [ngClass]="(savekey == a.key) ? 'green-btn': ''" (click)="updateAttribute(itemdetails.item.stockid, a.key, savevalue, a.oe, savekey, a.type) ? savekey = '' : savekey = savekey "><i class="fa fa-save"></i></button>

													</div>
													<!--default text -->
													<div class="input-group" *ngSwitchDefault>

														<input class="form-control" value="{{ a.value }}" (input)="savevalue=$event.target.value; savekey = a.key" [readonly]="a.key == 'sub'">
														<div class="input-group-append">
															<button mat-button class="btn btn-primary btn-sm" [ngClass]="(savekey == a.key) ? 'green-btn': ''" (click)="updateAttribute(itemdetails.item.stockid, a.key, savevalue, a.oe, savekey, a.type) ? savekey = '' : savekey = savekey ;" [disabled]="a.key=='sub'"><i class="fa fa-save "></i></button>
														</div>
													</div>
												</ng-container>


											</div>
										</li>
									</ul>
								</div>
								<div class="col-md-6">
									<ul class="entry-list" *ngFor="let a of filteredattributes; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1 bold"> {{ a.label }}</div>
											<div class="spec-value">
												<ng-container [ngSwitch]="a.type">
													<!-- select dropdown -->
													<div class="input-group frow" *ngSwitchCase="'select'">
														<mat-form-field appearance="outline" class="switch-select">
															<mat-label>{{ a.label }}</mat-label>
															<mat-select (selectionChange)="savevalue = $event.value; savekey=a.key;" [value]="a.value">
																<mat-option *ngFor="let k of a.options" [value]="k.value">
																	{{ k.label }}
																</mat-option>
															</mat-select>
														</mat-form-field>

														<button mat-button class="btn btn-primary btn-sm save-button" [ngClass]="(savekey == a.key) ? 'green-btn': ''" (click)="updateAttribute(itemdetails.item.stockid, a.key, savevalue, a.oe,savekey, a.type ) ? savekey = '' : savekey = savekey;"><i class="fa fa-save"></i></button>

													</div>
													<!--default text -->
													<div class="input-group" *ngSwitchDefault>
														<input class="form-control" value="{{ a.value }}" (input)="savevalue=$event.target.value; savekey = a.key" [readonly]="a.key == 'alt'">
														<div class="input-group-append">
															<button mat-button class="btn btn-primary btn-sm" [ngClass]="(savekey == a.key) ? 'green-btn': ''" (click)="updateAttribute(itemdetails.item.stockid, a.key, savevalue, a.oe, savekey, a.type )? savekey = '' : savekey = savekey;" [disabled]="a.key=='alt'"><i class="fa fa-save"></i></button>
														</div>
													</div>
												</ng-container>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Barcodes" *ngIf="!user.user.issalesman">
							<ng-template matTabContent>
								<app-inventory-barcodes class="w-100" [item]="itemdetails.item" (newitemdata)="updateItem($event)">
								</app-inventory-barcodes>
							</ng-template>
						</mat-tab>
						<mat-tab label="E-com Descriptions">
							<div class="d-flex justify-content-between align-items-center mb-2">
								<h5 [innerHTML]="itemdetails.descriptions.name" class="mb-0"></h5>
								<button class="btn btn-primary btn-sm" (click)="saveDescriptions();">
									<i class="fa fa-save mr-1"></i> Save
								</button>
							</div>
							<div class="row p-0 m-0">
								<div class="col-md-12 mb-2">
									<mat-form-field appearance="outline">
										<mat-label><b>E-COM NAME</b></mat-label>
										<input matInput
										required
										[formControl]="overridename"
										>
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-2">
									<label for="description-editor" class="form-label small">Description</label>
									<ckeditor [formControl]="descControl" [editor]="Editor" [config]="editorConfig"></ckeditor>
								</div>
								<div class="col-md-6 mb-2">
									<label for="features-editor" class="form-label small">Features</label>
									<ckeditor [formControl]="featControl" [editor]="Editor" [config]="editorConfig"></ckeditor>
								</div>
								<div class="col-md-6 mb-2">
									<label for="benefits-editor" class="form-label small">Benefits</label>
									<ckeditor [formControl]="beneControl" [editor]="Editor" [config]="editorConfig"></ckeditor>
								</div>
								<div class="col-md-6 mb-2" *ngIf="config.env.package == 'beauty'">
									<label for="ingredients-editor" class="form-label small">Ingredients</label>
									<ckeditor [formControl]="ingrControl" [editor]="Editor" [config]="editorConfig"></ckeditor>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Purchase Data" *ngIf="!user.user.issalesman && user.user.viewcost">
							<app-purchase-data [item]="itemdetails" (newitemdata)="updateItem($event)"></app-purchase-data>
						</mat-tab>

						<mat-tab label="Open Orders ({{ itemdetails.opensalesorders }})">
							<ng-template matTabContent>
								<app-open-orders [item]="itemdetails.item.stockid" (orderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)"></app-open-orders>
							</ng-template>
						</mat-tab>
						<mat-tab label="Open Purchase Orders ({{itemdetails.podata.length}})" *ngIf="!user.user.issalesman && user.user.viewcost">
							<ng-template matTabContent>
								<app-open-purchase-orders [item]="itemdetails.item.stockid" (orderloaded)="moveToPo($event)"></app-open-purchase-orders>
							</ng-template>
						</mat-tab>
						<mat-tab label="Open RMA's ({{itemdetails.opencredits.length}})">
							<ng-template matTabContent>
								<app-open-credits [item]="itemdetails.item.stockid"></app-open-credits>
							</ng-template>
						</mat-tab>
						<mat-tab label="Item Movements">
							<ng-template matTabContent>
								<app-inventory-movements [item]="itemdetails"></app-inventory-movements>
							</ng-template>
						</mat-tab>
						<mat-tab label="Item History">
							<ng-template matTabContent>
								<div class="row m-0 p-0">
									<div class="col-md-auto" *ngIf="locations.length > 1 && consolidated.value == false">
										<mat-form-field appearance="outline">
											<mat-label>Warehouse</mat-label>
											<mat-select [formControl]="defaultlocation" multiple>
												<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
													{{ loc.locationname }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-auto text-right mt-2">
										<mat-slide-toggle class="nav-link" [formControl]="consolidated" *ngIf="locations.length > 1">
											<mat-label>Consolidated</mat-label>
										</mat-slide-toggle>
										<mat-checkbox [formControl]="hideshowunits">Units&nbsp;</mat-checkbox>
										<mat-checkbox [formControl]="hideshowsales">Sales&nbsp;</mat-checkbox>
										<mat-checkbox [formControl]="hideshowcost" *ngIf="user.user.viewcost">Cost&nbsp;</mat-checkbox>
									</div>
									<div class="col-md-auto ml-auto mr-0">
										<ng-container *ngIf="!sending">
											<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
											&nbsp;&nbsp;
											<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
										</ng-container>

										<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="loadData()" *ngIf="!sending">
											<mat-icon>refresh</mat-icon>
										</button>
									</div>
								</div>
								<div class="table mt-3" #print_history>
									<ng-container *ngIf="itemhistory.totals.length == 0 && itemhistory.years.length == 0; else history">
										<div class="frow justify-content-center mt-20">
											<h5><b>No History Found</b></h5>
										</div>
									</ng-container>
									<ng-template #history>
										<h4 class="mb-3">
											<b>{{ itemdetails.item.description }}</b> | <small>{{ itemdetails.item.stockid }}</small> Y/Y
										</h4>
										<div class="card shadow-sm">
											<ng-container *ngFor="let year of itemhistory.years; let i = index;">
												<div class="card-header" *ngIf="year.title && locations.length > 1">
													<h5 class="card-title mb-0">{{ year.title }}</h5>
												</div>
												<div class="card-body p-0">
													<table class="table table-sm mt-0 mb-0">
														<thead class="thead-light">
															<tr>
																<th class="text-left"></th>
																<th *ngFor="let month of year.sales" class="text-center">
																	{{ month.label }} {{ month.year }}
																</th>
																<th class="text-center">Total</th>
															</tr>
														</thead>
														<tbody>
															<tr *ngIf="hideshowunits.value">
																<td class="text-left font-weight-bold">Qty</td>
																<td class="text-center" *ngFor="let month of year.qty">
																	{{ month.value | number:'1.0-0' }}
																</td>
																<td class="text-center font-weight-bold">{{ getQtyTotalYear(year) }}</td>
															</tr>
															<tr *ngIf="hideshowsales.value">
																<td class="text-left font-weight-bold">Sales</td>
																<td class="text-center" *ngFor="let month of year.sales">
																	{{ month.value | currency }}
																</td>
																<td class="text-center font-weight-bold">{{ getSaleTotalYear(year) | currency }}</td>
															</tr>
															<tr *ngIf="hideshowcost.value">
																<td class="text-left font-weight-bold">Cost</td>
																<td class="text-center" *ngFor="let month of year.cost">
																	{{ month.value | currency }}
																</td>
																<td class="text-center font-weight-bold">{{ getCostTotalYear(year) | currency }}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</ng-container>
										</div>
									</ng-template>
								</div>




							</ng-template>
						</mat-tab>
						<mat-tab label="Picking History" *ngIf="!user.user.issalesman">
							<ng-template matTabContent>
								<app-item-pick-history [item]="itemdetails.item"></app-item-pick-history>
							</ng-template>
						</mat-tab>
						<mat-tab *ngIf="itemdetails.item.mbflag == 'C'" (selectedTabChange)="openLookup()">
							<ng-template mat-tab-label>
								<span (click)="openLookup()">Bom</span>
							</ng-template>
							<ng-template matTabContent>
								<app-bommodal [openclosed]="stockid"></app-bommodal>
							</ng-template>
						</mat-tab>
						<mat-tab *ngIf="itemdetails.item.groups.length" label="Product Groups">
							<ng-template matTabContent>
								<app-inventory-productgroups [details]="itemdetails.item"></app-inventory-productgroups>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
</div>